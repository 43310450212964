.dropdown {
  display: inline-block;
  position: relative;
  $base-width: 200px;
  $base-height: 300px;
  .dropdown-content {
    z-index: 3;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 0px 15px 2px rgba(15, 15, 15, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    margin-top: 20px;
    position: absolute;
    &.right {
      left: 0;
    }
    &.left {
      right: 0;
    }
    &.middle {
      left: -100px;
    }
    max-height: $base-height;
    width: $base-width;
    overflow-y: auto;
  }
}

.dropdown-content {
  .dropdown-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .dropdown-command {
      cursor: pointer;
      display: flex;
      padding: 10px;
      justify-content: center;
      box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
      text-transform: lowercase;
      font-feature-settings: "c2sc";
      font-variant: small-caps;
      &:hover {
        background: #eee;
      }
    }
    .dropdown-item {
      user-select: none;
      cursor: pointer;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
      align-items: center;
      padding: 10px;
      line-height: 1.5;
      font-weight: 400;
      &:hover {
        background: #eee;
      }
      input[type="checkbox"] {
        margin: 0;
      }
    }
    .dropdown-item.disabled {
      pointer-events: none;
      cursor: default;
      color: #ccc;
    }
  }
}