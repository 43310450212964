.disabled-button{
    cursor: not-allowed;
    background-color: rgb(229, 229, 229) !important;
    pointer-events:none;
}

.unconfirmed-appointment-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.appointment-action-button {
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 0px 6px 6px;
    border-radius: 4px;

    &.reschedule-button {
        color: #2196f3;
        &:hover {
            background-color: unset;
            color: #2196f3;
        }
        &:focus {
            background-color: unset;
            color: #2196f3;
        }
        &:disabled {
            background-color: unset;
            color: gray;
        }
    }

    &.join-button {
        color: #2196f3;
        &:hover {
            background-color: unset;
            color: #2196f3;
        }
        &:focus {
            background-color: unset;
            color: #2196f3;
        }
    }

    &.cancel-appointment-button {
        color: #ed6c02;
        min-width: 170px;
        &:hover {
            background-color: unset;
            color: #ed6c02;
        }
        &:focus {
            background-color: unset;
            color: #ed6c02;
        }
    }

    &.cancel-action-button {
        color: #ed6c02;
        &:hover {
            background-color: unset;
            color: #ed6c02;
        }
        &:focus {
            background-color: unset;
            color: #ed6c02;
        }
    }
}