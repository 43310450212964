header.page-header {
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  .page-header-title {
    padding: 0;
    flex: 1;
    h1 {
      font-size: 2.3em;
      font-weight: 300;
      color: #666;
      .page-title-link {
        color: #4281a6;
        cursor: pointer;
      }
    }
    .page-count-value {
      color: #b3b4ba;
    }
  }
  .header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .link-button {
      text-align: right;
    }
    > * {
      margin: 0px 20px;
    }
  }
}
