.button {
  letter-spacing: 1px;
  &.action {
    @include button($bg: darken($link-green, 20%));
    &.small {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
  &.download_link {
    padding: 5px 10px;
    font-size: 30px;
  }
}