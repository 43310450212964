table{
  .button {
    margin-bottom: 0px;
    padding: 5px 10px;
    font-size: 0.9em;
  }
  input[type=checkbox]{
    margin: 0;
  }
  &.compact td, &.compact tr{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr.table-header td {
    background-color: $link-green;
    color: #FFF;
    font-size: 0.95em;
    padding: 11px 10px;
    font-weight: 200;
    letter-spacing: 0.05em;
  }
  span.edit-in-place {
    width: 150px;
    overflow: scroll;
    display: block;
    white-space: nowrap;
  }
  tr {
    &.compliance-false td {
      color: red;
    }
    &.archived td {
      color: lighten($body-font-color, 65%) !important;
      a {
        color: lighten($primary-color, 25%);
      }
    }
  }
  th {
    white-space: nowrap;
  }
}

.table-topper .columns {
  background: $link-green;
  padding: 10px !important;
  font-size: 0.8em;
  height: 45px;
  select {
    margin: 0;
    background: rgba(255,255,255,0.5);
    border: 1px solid rgba(255,255,255,0.6);
    height: 25px;
    padding: 2px 10px !important;
    &:focus{
      box-shadow: 0 0 0px $base-green;
    }
  }
}

.row.table-footer.collapse {
  background: lighten($link-green, 20%);
  position: relative;
  top: -19px;
  .columns{
    padding: 10px;
    input, select {
      margin: 0;
    }
  }
}