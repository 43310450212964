$autodialer-color: #ff69b4;
$followup-color: #9370db;

.system-tray {
  position: fixed;
  bottom: 0;
  right: 0;
  &.chat-active {
    right: calc(#{$twilio-chat-width} + 20px);
  }
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  z-index: 10;
  .system-tab {
    color: #777;
    user-select: none;
    border: 1px solid #ccc;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: linear-gradient(#dcdcdc, #ececec);
    box-shadow: 0px 0px 1px #333;
    font-weight: bold;
    padding: 0.35rem 0.5rem;
    min-width: 17rem;
    font-size: 1rem;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr auto;
    .tab-label {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 18rem;
      padding: 0px 10px;
    }
    .close-icon {
      color: #333;
      &:hover {
        transform: scale(1.15);
      }
    }
  }
  .system-tab + .system-tab {
    margin-left: 1rem;
  }
  .system-tab.is-open {
    color: #0d0d0d;
    border: 1px solid #aaa;
    box-shadow: 0 3px 0 #fff, 0 0 3px #888;
    .close-icon {
      pointer-events: none;
    }
  }
  .auto-dialer-tab.is-active {
    background: linear-gradient(#ccc, $autodialer-color);
    color: #555;
  }
  .auto-dialer-tab.is-active.is-open {
    background: linear-gradient($autodialer-color, #aaa);
    color: #333;
  }

  .auto-dialer-tab.is-active.followUpOrders {
    background: linear-gradient(#ccc, $followup-color);
    color: #555;
  }

  .auto-dialer-tab.is-active.is-open.followUpOrders {
    background: linear-gradient($followup-color, #aaa);
    color: #333;
  }

  .side-btn.followUpOrders {
    .tab-side-btn {
      background: linear-gradient(#ccc, $followup-color);
      color: #333;
      .icon-button {
        background: linear-gradient(#ccc, $followup-color);
      }
    }
  }
  .tab-side-btn {
    background: linear-gradient(#ccc, $autodialer-color);
    margin-left: 1rem;
    padding: 3px;
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #555;
    &:hover {
      color: #333;
      svg {
        transform: scale(1.05);
      }
    }
  }
}
