.compliance-dashboard-tabs {
  font-family: "Raleway", Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  li {
    display: inline-block;
    list-style: outside none none;
    margin: 0.25em 1em;
    padding: 0.4em 0;
    color: rgb(0, 144, 188);
    position: relative;
    text-decoration: none;
    display: inline-block;
  }

  li:before {
    position: absolute;
    content: "";
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    opacity: 0;
    top: 25%;
    bottom: 25%;
    left: 0;
    right: 0;
    border-top: 2px solid rgb(37, 168, 224);
    border-bottom: 2px solid rgb(37, 168, 224);
  }

  li:hover {
    color: rgb(0, 144, 188);
  }

  li:hover:before {
    opacity: 1;
    top: 0;
    bottom: 0;
  }

  .is-active {
    &:before {
      opacity: 1;
      top: 0;
      bottom: 0;
    }

    color: rgb(37, 168, 224);
  }
}

.active{
  background-color: lightgray;
}

.dropdown-command-disabled {
  cursor:pointer;
  display: flex;
  padding: 10px;
  justify-content: center;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
  text-transform: lowercase;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  opacity: 0.5;
}

.patient-list-export {
    color: green;
    font-size: 22px;
    cursor: pointer;
}