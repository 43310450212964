.patient-physician {
  margin: 10px;
  .physician-search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .clear-form {
    margin: 5px 5px;
    color: #4281a6;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  .patient-physician-form {
    .name-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0px 20px;
    }
    .address-fields {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0px 20px;
      :first-child {
        grid-column: 1/-1;
      }
    }
    .form-actions {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      margin-bottom: 5px;
      button {
        margin: 0;
      }
    }
  }

  .physician-info {
    .physician-address {
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      grid-gap: 20px;
      margin-left: 50px;
      cursor: pointer;
      color: #4281a6;
    }
  }

  .search-results {
    max-height: 325px;
    overflow: auto;
  }

  .search-results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-values {
    color: #085b55;
    text-align: right;
    font-size: 0.8rem;
    span:not(:empty) ~ span:not(:empty):before {
      content: ", ";
    }
  }
}
