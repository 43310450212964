.error-boundary {
  z-index: 2;
  position: fixed;
  left: calc(50% + 220px);
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 720px;
  width: 100%;
  padding-left: 160px !important;
  line-height: 1.1;

  .error-boundary-error {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 140px;
    height: 140px;
    background-image: url(./assets/images/emoji.png);
    background-size: cover;
  }

  .error-boundary-error:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2.4);
    -ms-transform: scale(2.4);
    transform: scale(2.4);
    border-radius: 50%;
    background-color: #f2f5f8;
    z-index: -1;
  }

  h1 {
    font-family: "Nunito", sans-serif;
    font-size: 65px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #151723;
    text-transform: uppercase;
  }

  h2 {
    font-family: "Nunito", sans-serif;
    font-size: 21px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    color: #151723;
  }

  .error-actions {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    div {
      margin: 15px 0px;
    }
    details {
      position: fixed;
      font-family: "Nunito", sans-serif;
      color: #999fa5;
      font-weight: 400;
      outline: none;
    }
  }
  .feedback-link {
    margin: 30px 0px;
  }
}
.error-boundary-underlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.75;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .error-boundary .error-boundary-error {
    width: 110px;
    height: 110px;
  }
  .error-boundary {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}
