.multi-page-form {

  position: relative;

  .multi-page-form-breadcrumbs {
    display: flex;
    justify-content: space-evenly;
    padding: 15px
  }

  .multi-page-form-breadcrumb {
    opacity: 0.6;
    &.active {
      opacity: 1;
    }
    &.traversable {
      cursor: pointer;
    }
  }

  .multi-page-form-breadcrumb + .multi-page-form-breadcrumb {
    padding-left: 10px;
  }

  .multi-page-form-breadcrumb-title {
    color: #4281a6;
    font-weight: 600;
  }

  .multi-page-form-breadcrumb-subtitle {
    color: #333;
    font-size: 0.87em;
  }

  .multi-page-form-page {
    border: 1px solid #ddd;
    padding: 1rem 20px;
  }

  .multi-page-form-prompt {
    font-size: 1.6875rem;
    color: #085b55;
    line-height: 1.4;
  }

  .multi-page-form-nav {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.845rem;

    button {
      margin: 0;
    }

    * + button {
      margin-left: 15px;
    }
  }

  .multi-page-form-submit-overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }
}