.team-dashboard-page {
  display: flex;
  flex-direction: column;
  .team-dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid rgb(221, 221, 221);
    .team-dashboard-header-title {
      display: flex;
      align-items: center;
      span {
        font-size: 2em;
        color: rgb(102, 102, 102);
      }
      > span {
        padding-right: 10px;
      }
    }
    .date-range-container {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
    }
  }
  .team-dashboard-container {
    display: flex;
    flex: 1 1 auto;
    padding-bottom: 10px;
    .block {
      padding-bottom: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }
    .block-content {
      display: flex;
      flex: 1 1 auto;
      border-radius: 0 0 5px 5px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.22);
    }
    .block-header {
      display: grid;
      grid-template-columns: 1fr auto;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.22);
      align-items: center;
      padding: 5px;
      color: #085b55;
      background: #e8e8e8;
      border-radius: 5px 5px 0 0;
      font-weight: 700;
      font-size: 1.5em;
      padding-left: 10px;
      > span {
        cursor: pointer;
      }
    }
    table {
      width: 100%;
      table-layout: fixed;
    }
    .coach-orders {
      .coach-orders-mode-select {
        cursor: pointer;
        font-size: 1em;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        align-items: flex-start;
        user-select: none;
        padding: 0 5px;
        i {
          font-size: 0.75em;
          color: #4281a6;
          font-weight: normal;
          cursor: pointer;
          padding: 1px;
        }
        i.active {
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      table {
        overflow-x: auto;
      }
    }
  }
}
.team-dashboard-coaches-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  grid-gap: 20px;
}

.team-dashboard-companies-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.team-dashboard-allocation-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.deallocate-button {
  margin: 0;
  padding: 0.25rem 1rem 0.3rem 1rem;
}

.company-allocation-slider-container {
  .company-allocation-controls {
    display: flex;
  }
  .company-allocation-controls:nth-child(even) {
    background-color: #ededed;
  }
  .company-allocation-slider {
    background-color: transparent;
  }

  .company-allocation-slider::-webkit-slider-runnable-track {
    background-color: transparent;
    background: #3071a9;
  }

  .allocation-company-title {
    font-weight: bold;
    align-self: center;
    margin-right: 10px;
    font-size: 1.2em;
  }
  .allocation-percent-container {
    display: flex;
    font-weight: bold;
    border: solid 1px #949494;
    justify-content: center;
    padding: 0px 5px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: inset 0px 4px 6px -3px #949494;
    width: 4em;
  }
  .allocation-percent-input {
    border-width: 0px;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    color: #3071a9;
    font-size: 1.5em;
    text-align: end;
    width: 1.5em;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    // opacity: 1;
  }
}
.allocation-control-buttons {
  margin: 5px auto;
  padding: 1em;
  text-align: center;

  .allocation-submit-button {
    width: 400px;
  }
}
