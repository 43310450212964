.phone {
  background: linear-gradient(#eee, #dfdfdf);
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1001;

  .main {
    display: flex;

    > * {
      display: flex;
      height: 33px;
    }
  }

  input,
  button,
  select {
    width: unset;
    margin: unset;
    padding: 0 2rem;
    min-height: 100%;
  }

  input {
    width: 220px;
  }
  .callButton {
    background-color: green;
    width: 50px;
    padding: 0px;
    cursor: pointer;

    &:disabled {
      background-color: #47a147;
    }
    &:hover {
      background-color: #005400;
      &:disabled {
        background-color: #47a147;
      }
    }
  }

  .muteButton {
    width: 50px;
    padding: 0px;
    cursor: pointer;
  }

  .muteButton.active {
    cursor: pointer;
    background-color: hsl(0, 0, 40%);
    width: 50px;
    padding: 0px;
  }

  .queue-controls {
    padding-left: 20px;
    .queue-select {
      padding: 0 1rem;
      width: 120px;
    }
    .answerQueueButton {
      width: 50px;
      padding: 0px;
      font-size: 0.9em;
      cursor: pointer;
    }

    .queueStats {
      padding: 0 1rem;
      background: #ddd;
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 0px 3px 3px 0px;
      > * {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .acceptIncomingButton {
    font-size: 0.9em;
    width: 50px;
    padding: 0;
    text-align: center;
    background-color: #ff6500;
    animation-name: acceptButtonAnim;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    -webkit-animation-name: acceptButtonAnim;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 0.7s;
  }
  @keyframes acceptButtonAnim {
    0% {
      background-color: red;
    }
    50% {
      background-color: black;
    }
    100% {
      background-color: red;
    }
  }
  @-webkit-keyframes acceptButtonAnim {
    0% {
      background-color: red;
    }
    50% {
      background-color: black;
    }
    100% {
      background-color: red;
    }
  }
  .rejectIncomingButton {
    font-size: 0.9em;
    width: 50px;
    padding: 0;
    text-align: center;
    cursor: pointer !important;
  }

  .callerIDIncoming {
    font-size: 1.3em;
    margin-left: 10px;
    color: red;
    font-weight: bold;
  }

  .closeButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > i {
      font-size: 1.2rem;
      padding: 0.4rem;
      cursor: pointer !important;
    }
  }

  .callDuration {
    min-width: 85px;
    background: #ddd;
    padding: 0 1rem;
    font-size: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &.noCall {
      align-items: center;
      color: #bbb;
    }
  }
  .call-quality-warning {
    color: rgb(232, 145, 12);
    background: #eee;
    position: fixed;
    top: 33px;
    font-size: 1.15em;
    display: flex;
    align-items: center;
    right: 0;
    padding: 0 10px;
    .call-quality-text {
      padding: 0px 10px;
    }
  }
  .dialer-number-input {
    display: flex;
    position: relative;

    .outboundCallInput {
      padding: 0px;
      text-align: center;
    }

    .outboundCallInputDisabled {
      padding: 0px;
      text-align: center;
      background-color: red !important;
      border-color: red !important;
    }

    .number-input-clear-icon {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      z-index: 1;
      top: 0;
      right: 60px;
      padding: 0 5px;
      font-size: 1.15em;
      cursor: pointer;
      color: gray;
      &:hover {
        color: black;
      }
    }
    .hangupButton {
      background-color: red;
      width: 50px;
      padding: 0px;
      cursor: pointer;
    }
    .hangupButton:hover {
      background-color: darkred;
    }

    .hangupIcon {
      transform: rotate(135deg);
      width: 50px;
      padding: 0px;
    }
  }
}

.recording-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .resume-recording-btn {
    color: green;
  }
  button {
    color: #222;
    outline: none;
    border: none;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;

    span {
      display: block;
      transition: transform 0.1s ease-in-out;
    }
    &:hover {
      span {
        transform: scale(1.02);
      }
    }
  }
  button:hover {
    background: #eee;
  }
}
