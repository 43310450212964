.companies-table {
  max-height: 70vh;
}
.companies-table-option-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  .column-option-info {
    padding-left: 10px;
  }
}
.companies-table-date-range-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 50%;

  input {
    margin: 0;
    width: 150px;
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid hsla(0, 0%, 100%, 0.6);
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.75);
      border: 1px solid hsla(0, 0%, 100%, 0.6);
    }
  }
  .DayPickerInput {
    color: black;
  }
  button {
    margin: 0;
    width: 150px;
    padding: 0.15rem 0.35rem;
    height: unset;
    color: #249c91;
    background: rgba(255, 255, 255, 0.75);
    border: 1px solid hsla(0, 0%, 100%, 0.6);
    &:focus,
    &:active {
      outline: none !important;
    }
    &:disabled {
      background: rgba(255, 255, 255, 0.5);
      color: #249c91;
      border: none;
    }
  }
}
.companies-table-action-buttons {
  padding: 5px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.company-search-field {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  .search-icon {
    font-size: 20px;
    color: hsla(0, 0%, 100%, 0.6);
    padding: 0 5px;
  }
  input {
    margin: 0;
    width: 150px;
    padding: 0.25rem 0.5rem;
    height: unset;
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid hsla(0, 0%, 100%, 0.6);
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.75);
      border: 1px solid hsla(0, 0%, 100%, 0.6);
    }
  }
  .clear-icon {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1;
    padding-right: 5px;
    top: 0;
    right: 0;
    font-size: 1.15em;
    cursor: pointer;
    color: gray;
    &:hover {
      color: black;
    }
  }
}
