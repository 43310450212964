.chat-tabs ul {
  margin-bottom: 0;
}

.scheduler {
  $cell-height: 15px;
  $cell-width: 180px;
  $confirmed-color: rgb(19, 180, 255);
  $confirmation-pending-color: rgb(255, 211, 0);
  $product-demo-color: rgb(174, 22, 62);
  $free-color: #006633;
  $completed-color: #666666;
  $time-off-color: #e2e1e1;
  $expired-color: #8e8e8e;
  $non-working-hours: #e2e1e1;

  user-select: none;
  flex: 1 1 auto;
  display: grid;
  padding: 5px;
  overflow: hidden;
  justify-content: center;

  .calendar-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    overflow-x: auto;

    .week-select {
      display: grid;
      grid-area: 2;
      grid-template-columns: repeat(3, auto);
      align-items: center;
      justify-content: end;
      flex: 1;
      grid-gap: 20px;
      font-family: "Poppins-Regular", "PT Sans", sans-serif;
    }

    .calendar-legend {
      display: grid;
      grid-area: 2;
      grid-template-columns: repeat(10, auto);
      align-items: center;
      justify-content: center;
      grid-gap: 5px;
      font-family: "Poppins-Medium", "PT Sans", sans-serif;

      .color-block {
        float: left;
        width: 20px;
        height: 20px;
        margin: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }

      .color-block.completed {
        background: $completed-color;
      }
      .color-block.non-working-hours {
        background: $non-working-hours;
      }

      .color-block.expired {
        background-size: auto auto;
        background-color: rgba(102, 102, 102, 0.5);
        background-image: repeating-linear-gradient(
          135deg,
          transparent,
          transparent 2px,
          rgba(240, 240, 240, 1) 2px,
          rgba(240, 240, 240, 1) 7px
        );
      }

      .color-block.un-confirmed {
        background: $confirmation-pending-color;
      }

      .color-block.confirmed {
        background: $confirmed-color;
      }

      .color-block.free {
        background: $free-color;
      }
      .color-block.timeoff {
        background: $time-off-color;
      }

      .legend-text {
        padding-right: 25px;
      }
    }
  }

  .calendar-container {
    max-width: fit-content;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 10px;
    overflow: hidden;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .calendar-wrapper {
    padding: 0 5px 0 5px;
    overflow: hidden;
    display: grid;
    grid-template-rows: min-content minmax(auto, max-content);
  }

  .calendar-wrapper-coach {
    padding: 0 5px 0 5px;
    overflow: hidden;
    display: grid;
    grid-template-rows: min-content min-content minmax(auto, max-content);
  }

  .calendar {
    display: grid;
    grid-template-areas:
      "empty  days-of-week"
      "time cells";
    grid-template-columns: 120px;
    grid-template-rows: 60px;
    overflow: auto;
  }

  .empty-corner {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 4;
    background-color: #f7f7f7;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }

  .calendar-daysofweek-header {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
    grid-area: days-of-week;
    display: grid;
    // grid-template-columns: repeat(7, $cell-width);
    grid-template-columns: repeat(auto-fill, $cell-width);
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    font-family: "Poppins-Regular", "PT Sans", sans-serif;

    .calendar-day-of-week {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .calendar-container-by-day {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 10px;
    overflow: hidden;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .calendar-by-day {
    display: grid;
    height: 100%;
    grid-template-areas: "time cells";
    grid-template-columns: 120px;
    overflow: auto;
  }

  .calendar-day-header {
    grid-area: days-of-week;
    display: grid;
    grid-template-columns: repeat(1, $cell-width);
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    font-family: "Poppins-Regular", "PT Sans", sans-serif;

    .calendar-day-of-week {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .calendar-spinner-container {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar-daysofweek-header > div {
    display: grid;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }

  .times {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    grid-area: time;
    display: grid;
    grid-template-rows: repeat(15, 60px);
    font-size: 14px;
    background-color: #f7f7f7;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    font-family: "Poppins-Regular", "PT Sans", sans-serif;
  }

  .times > div {
    display: grid;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .time-slots-container {
    position: relative;
    grid-area: cells;
    display: grid;
    user-select: none;
    grid-template-rows: repeat(60, $cell-height);
    //grid-template-columns: repeat(7, $cell-width);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    grid-auto-flow: column;
    grid-auto-columns: $cell-width;
  }

  .time-slots-day-container {
    position: relative;
    grid-area: cells;
    display: grid;
    user-select: none;
    grid-template-rows: repeat(60, $cell-height);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    grid-auto-flow: column;
  }

  .time-slot {
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: $non-working-hours;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.cant-use {
      cursor: default;
    }

    &.disabled {
      background-color: $expired-color !important;
    }
    &.work-time:not(.disabled) {
      background: white;
    }
    &.lunch-time {
      background: $non-working-hours;
      cursor: default;
      color: black;
    }

    &.initial-row {
      background: #26bbad;
    }

    &.selected:hover {
      border: 1px solid rgba(0, 0, 0, 0.5);
      cursor: s-resize;
    }

    &:nth-child(4n) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .scheduled-slot {
    font-size: 1rem;
    position: absolute;
    background-color: #f0f0f0;
    cursor: default;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    z-index: 2;
    font-family: "Poppins-Regular", "PT Sans", sans-serif;
    border-radius: 6px;

    &.temp {
      border: 1px solid rgba(0, 0, 0, 0.45);
    }

    &.active {
      box-shadow: 0 0 3px rgba(33, 33, 33, 0.25);
    }

    &.active.completed {
      background: $expired-color;
      color: black;
    }

    &.active.not-completed {
      color: #000000;
      background-size: auto auto;
      background-color: rgba(102, 102, 102, 0.5);
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 2px,
        rgba(240, 240, 240, 1) 2px,
        rgba(240, 240, 240, 1) 12px
      );
      font-weight: 600;
    }

    &.active.expired {
      color: #000000;
      background-size: auto auto;
      background-color: rgba(102, 102, 102, 0.5);
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 2px,
        rgba(240, 240, 240, 1) 2px,
        rgba(240, 240, 240, 1) 12px
      );
      font-weight: 600;
    }

    &.active.un-confirmed {
      background: $confirmation-pending-color;
      color: black;
    }

    &.active.confirmed {
      background: $confirmed-color;
      color: white;
    }

    &.active.free {
      background: $free-color;
      color: white;
    }

    &.selected {
      box-shadow: 0 0 5px rgba(33, 33, 33, 0.85);
    }

    &.temp {
      align-items: center;
      justify-content: center;
    }

    &.timeoff {
      background-color: $time-off-color;
      color: black;
      z-index: 1; // to avoid covering the tooltip (for appointment_patients)
    }

    .scheduled-slot-info {
      font-family: "Poppins-Regular", "PT Sans", sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 8%;
      flex: 1;
      width: 100%;
      font-size: 90%;

      .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .slot-type {
      display: flex;
      width: 30px;
      align-items: center;
      justify-content: center;
    }

    .temp-scheduled-slot-hour-range-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tooltip-assgined-patients {
    color: lightgray !important;
    background: rgb(59, 57, 59) !important;
    max-width: 210px;
  }
  .tooltip-assgined-patients .show {
    background-color: #3b393b;
  }

  .scheduler-controls {
    background: #f3f3f3;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-family: "Poppins-Regular", "PT Sans", sans-serif;
    width: 300px;
    justify-self: end;

    .scheduler-new-slot-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(6, auto);
      grid-gap: 15px;
      align-items: center;
      padding: 10px;

      label {
        font-size: 1.15em;

        > span {
          padding-right: 20px;
        }
      }

      input {
        margin: 0px;
      }
    }
  }

  .edit-timeslot-time-selector-container {
    display: flex;
  }

  .edit-timeslot-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    padding: 5px;

    .edit-timeslot-button {
      font-family: inherit;
      border-radius: 15px;
    }
  }

  .month-view {
    .appointment.completed {
      background: $expired-color;
    }
    .appointment.non-working-hours {
      background: $non-working-hours;
    }

    .appointment.expired {
      background-size: auto auto;
      background-color: rgba(102, 102, 102, 0.5);
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 1px,
        rgba(240, 240, 240, 1) 1px,
        rgba(240, 240, 240, 1) 15px
      );
    }

    .appointment.un-confirmed {
      background: $confirmation-pending-color;
    }

    .appointment.confirmed {
      background: $confirmed-color;
    }

    .appointment.timeoff,
    .appointment.lunchtime {
      background: $non-working-hours;
      color: black;
    }
  }
}

.create-timeslot-button {
  font-family: "Poppins-Regular", "PT Sans", sans-serif;
  border-radius: 15px;
  margin: 0px 0px 0px 10px;
}
.move-timeslot-button {
  font-family: "Poppins-Regular", "PT Sans", sans-serif;
  border-radius: 15px;
  margin: 0;
  background-color: #cbcaca;
  color: #309bd9;
  font-weight: 600;
  &:hover {
    color: #309bd9;
    background-color: #b2b2b2;
  }
}

.remove-timeslot-button {
  font-family: "Poppins-Regular", "PT Sans", sans-serif;
  border-radius: 15px;
  margin: 0px 0px 0px 10px;
  &:hover {
    background-color: unset;
  }
  &:focus {
    background-color: unset;
  }
}

.select-coach-for-appointment-button {
  font-family: "Poppins-Regular", "PT Sans", sans-serif;
  border-radius: 15px;
}

.calendar-help-menu {
  padding: 20px;
  font-family: "Poppins-Regular", "PT Sans", sans-serif;

  .help-menu-title {
    font-family: inherit;
  }
}

.edit-timeslot-modal-title {
  font-family: "Poppins-Regular", "PT Sans", sans-serif;
}

.chat-type-legend {
  grid-area: 1 / 2;
  display: flex;
  justify-content: space-evenly;
  font-family: "Poppins-Regular", "PT Sans", sans-serif;

  .chat-type-container {
    display: flex;
    align-items: center;

    .chat-type-text {
      margin-bottom: 0px;
      margin-left: 10px;
    }
  }
}

.chat-type-slot-icon {
  justify-self: center;
}

.circled-character {
  display: inline-block;
  background-color: steelblue;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 1rem;
  height: 1rem;
  width: 1rem;
  line-height: 1rem;
  justify-content: center;
  align-items: center;
}

.create-time-slot-modal-container {
  font-family: "Poppins-Regular", "PT Sans", sans-serif;
  padding: 20px 50px 20px 20px;

  .modal-title {
    padding-left: 35px;
    font-family: "Poppins-Regular", "PT Sans", sans-serif;
    color: #4281a6;
  }

  .title-container {
    padding-left: 35px;
  }

  .input-label-container {
    display: flex;
    justify-content: space-between;
  }

  .input-label {
    color: #4281a6;
    margin: 0px;
    font-size: 0.875rem;
  }

  .recurrent-options-container {
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    align-items: baseline;
  }

  .recurrent-switch-container {
    display: flex;
    padding-top: 0.5rem;
    height: fit-content;
  }

  .recurrent-options-text {
    margin: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .recurrent-days-container {
    display: flex;
    flex: 1;
    justify-content: space-around;
  }

  .second-icon-container {
    padding: 5px;
    display: flex;
    align-items: center;
  }

  .chat-type-and-invites-container {
    width: 100%;
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
  }

    .text-input-styles {
        border-left: none;
        border-right: none;
        border-top: none;
        background-color: #ffffff !important;
        box-shadow: none;
    }

    .text-input-styles::placeholder {
      color: #666;
      opacity: 1;  
    }

  .warning-text {
    text-align: end;
    color: #ff0000;
  }

  .warning-button {
    background-color: #ff0000;
  }

  .modal-spinner-container {
    height: inherit;
    width: inherit;
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .invite-patients-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invite-patients-button {
    color: #356785;
    background-color: #ffffff;
    margin-top: 1.25rem;
    padding: 0px;
    width: 130px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Poppins-Regular", "PT Sans", sans-serif;

    &:hover {
      color: #356785;
      background-color: #ffffff;
    }
  }

  .invited-patients {
    background-color: #f5f5f5;
    height: 25px;
    margin-left: 10px;
    padding: 7px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .already-assigned-patients {
    background-color: #f5f5f5;
    height: 25px;
    margin-left: 10px;
    padding: 7px;
    border-radius: 15px;
    display: flex;
    align-items: center;
  }

  .patient-search-inputs-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .appointment-topic-container {
    display: flex;
    margin: 1em 0;
  }

  .patient-search-button {
    margin: 0px;
    padding: 0px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .third-icon-container {
    padding: 5px;
    padding-top: 15px;
  }

  .action-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .remove-margin-bottom {
    margin-bottom: 0px;
  }

  .selected-patients-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.date-input-styles {
  border-radius: 15px;
}

.week-day-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;

  &.on {
    background-color: steelblue;
    color: #fff;
  }

  &.off {
    background-color: grey;
    color: black;
  }
}
@media (min-width: 450px) {
  &.by-day {
    width: 100%;
  }
}
