.add-equipment-form {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;

  .compatible-equip-checkbox {
    display: flex;
    align-items: center;
    input[type="radio"],
    input[type="checkbox"] {
      margin: 0 10px;
    }
  }

  .filter-selections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 0px 30px;
    padding: 5px 0px;
  }
  .product-select-row {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    span {
      padding-right: 10px;
    }
  }
  .submit-button {
    margin: 0;
    padding: 0.5rem 1.75rem;
  }
}

.add-equipmentById-form {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  .form-field {
    padding-right: 15px;
  }
  .form-field:first-child {
    flex: 1 1 auto;
  }
  .submit-button {
    margin: 0;
    padding: 0.5rem 1.75rem;
  }
}
