.choose-order-supplies {
  li + li {
    margin-top: 10px;
  }

  label {
    display: flex;
    align-items: center;
  }

  .supply-checkbox-container {
    padding-right: 15px;
  }

  input {
    margin: 0;
  }

  .description {
    color: #999;
  }

  .choose-supplies-button-group {
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
    // background: #eee;
    margin: 1rem -20px -1rem -20px;
    margin-top: 1rem;

    button {
      width: 25%;
    }
  }
}

