.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-container {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
}