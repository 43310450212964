.order-profile-container {
  padding: 0px !important;
  overflow: auto;
  display: grid;
  grid-template-rows: auto 1fr;
}
.order-profile {
  input,
  button,
  select {
    margin-bottom: 0;
  }
  padding-top: 20px;
  overflow: auto;

  button {
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: bold;
  }

  .order-profile-page,
  .order-profile-print-page {
    padding: 2rem 4rem;
    font-size: 0.9rem;
    background: #fff;

    > * + * {
      margin-top: 2rem;
    }
  }

  .order-profile-page {
    width: 75rem;
    border: 1px solid #ddd;
    margin: 0 auto;
  }

  .order-profile-page + .order-profile-page {
    margin-top: 2rem;
  }

  .order-profile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    > :first-child {
      flex: 1;
    }
  }

  .order-profile-subheader {
    margin-top: 2rem;
  }

  .profile-header {
    .thirty-day {
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      color: #f33;
      border: 1px solid #f33;
      background: #fcc;
      font-size: 1.1rem;
      font-weight: bold;
    }

    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .order-action-buttons {
      display: grid;
      grid-template-columns: repeat(5, minmax(60px, auto));
      justify-items: center;
      grid-gap: 15px;
      align-items: center;
    }
    .order-notes-button,
    .order-refresh-button,
    .print-order-button {
      font-size: 2.75rem;
      cursor: pointer;
      color: grey;
      &.has-notes {
        color: salmon;
      }
    }
    .print-order-button {
      .print-menu {
        font-size: 0.85rem;
        .print-option {
          padding: 5px 0px;
          &:hover {
            color: black;
          }
        }
      }
    }

    .order-status {
      font-weight: bold;
      font-size: 1rem;
    }

    .modal-link {
      color: #4281a6;
      cursor: pointer;
    }

    .pending-info-container {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      padding-top: 5px;
      input,
      select {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 1px solid black;
        font-size: 1rem;
        width: 250px;
      }
    }

    .pending-delayed-container {
      margin-top: 10px;
      padding-top: 5px;
      display: inline-flex;
      .pending-delayed-field {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 1px solid black;
        font-size: 1rem;
        width: 250px;
      }
    }

    .subheader-text {
      padding: 0px 10px;
      font-weight: bold;
      font-size: 1rem;
      color: #4281a6;
    }
  }

  .packing-slip-header {
    display: flex;
    justify-content: space-between;
  }

  .package-shipping {
    display: flex;
    justify-content: space-between;
  }

  .package-shipping-card {
    display: flex;
    border: 1px solid #eee;
    flex: 1;

    > * {
      padding: 1rem;
    }

    > :first-child {
      background: #eee;
    }
  }

  .package-shipping-card + .package-shipping-card {
    margin-left: 2rem;
  }

  .order-profile-replacement-order {
    label {
      display: flex;

      > * + * {
        margin-left: 0.5rem;
      }
    }
  }

  .inline-header {
    font-weight: bold;
    &::after {
      content: ": ";
    }
  }

  .remove-equipment-x {
    color: salmon;
    cursor: pointer;
  }

  .add-order-equipment-container {
    border: 1px solid #eee;
    background: #f9f9f9;
    padding: 5px 10px;

    .submit-button {
      display: flex;
      align-self: flex-end;
    }
  }

  .order-status-container {
    display: flex;
    align-items: baseline;
  }

  .accept-decline-container {
    font-size: 2rem;
    margin-left: 2rem;
    > span {
      margin: 0px 30px;
      svg {
        transition: all 0.2s ease-in-out;
        margin: 5px;
        &:hover {
          filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
          transform: scale(1.02);
        }
      }
    }

    > * + * {
      margin-left: 0.5rem;
    }
  }

  .accept-order {
    cursor: pointer;
    color: green;
  }

  .send-pending {
    cursor: pointer;
    color: green;
  }

  .send-follow-up {
    cursor: pointer;
    color: #0090bc;
  }

  .decline-order {
    cursor: pointer;
    color: salmon;
  }

  .quantity-input {
    width: 4rem;
  }

  .send-to-fulfillment button + button {
    margin-left: 1rem;
  }
  .generic-item-flag {
    text-decoration: underline;
    font-weight: bold;
    color: #ff7377;
  }

  &:not(.print) {
    .equipment-label {
      margin-left: 20px;
      vertical-align: middle;
      padding: 0.25rem 0.5rem 0.25rem;
      font-size: 0.75rem;
      background-color: #4281a6;
      color: #ffffff;
      text-transform: uppercase;
      &.hasLineItem {
        text-decoration: underline;
      }
    }
  }

  &.print {
    /* TODO deprecated along with component: Nov 4 19*/
    // we don't want this in the page itself either, which is why it's
    // out of `@media print`
    .copy-to-clipboard-button,
    .print-order-button,
    .order-notes-button {
      display: none;
    }

    .equipment-label {
      margin-left: 0px;
      vertical-align: middle;
      padding: 0px;
      font-size: 0.75rem;
      text-transform: lowercase;
    }
    .equipment-label:not(:first-child):before {
      content: ", ";
    }
  }

  .edit-sales-order-number,
  .admin-claim {
    color: $primary-color;
    cursor: pointer;
  }

  .sales-order-form form {
    * + * {
      margin-top: 1rem;
    }
    .submit-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .bulk-update-tracking-button-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .bulk-tracking-update {
    .submit-container {
      margin-top: 2rem;
      button {
        text-transform: uppercase;
        font-weight: bold;
        width: 100%;
      }
    }
    .bulk-error {
      font-weight: bold;
      color: red;
    }
  }
  footer {
    display: none;
  }
}
.carrierGuess {
  font-weight: 700;
  color: #00aa9e;
  position: relative;
  top: -66px;
  left: -46px;
  font-size: 1em;
}

.copy-to-clipboard-button {
  cursor: pointer;
  pre {
    color: black;
    font-size: 10px;
    line-height: 1;
  }
}

.copied-animation {
  animation: bounce 1s linear;
}

.copied-animation:before {
  content: "Copied";
  position: absolute;
  top: -20px;
  left: -3px;
  opacity: 0.8;
  animation: fade 2s ease-in-out;
}

.information-tooltip {
  margin-left: 5px;
  * > {
    content: normal;
  }
}

.order-profile-notes {
  padding: 1.8rem;

  textarea,
  button {
    margin-bottom: 0;
  }
  button {
    padding: 8px;
    height: auto;
    align-self: center;
    justify-self: center;
  }
}

.order-profile-notes-body {
  color: darkslategrey;

  > * + * {
    margin-top: 2rem;
  }
}

.order-profile-add-note {
  display: flex;
  > * {
    height: 5rem;
  }
  > * + * {
    margin-left: 2rem;
  }
}

.order-profile-note-list {
  max-height: 50vh;
  overflow-y: auto;
}

.order-profile-note {
  padding: 1rem;
  display: flex;

  > :first-child {
    flex: 1;
  }

  > :nth-child(2) {
    flex: 3;
  }

  > * + * {
    margin-left: 2rem;
  }

  .creator {
    font-weight: bold;
  }
}

@media print {
  .order-profile {
    margin: 0 !important;
    padding: 0 !important;
    overflow: unset;
    background: #fff;
  }
  .order-profile-container {
    margin: 0 !important;
    padding: 0 !important;
    overflow: unset;
  }
  .order-notes-button,
  .accept-decline-container,
  .work-list-container,
  .copy-to-clipboard-button,
  .print-order-button,
  .order-notes-button {
    display: none !important;
  }

  .order-profile .order-profile-page {
    width: 100%;
    border: none;
  }

  footer {
    display: block !important;
  }

  .equipment-label {
    margin-left: 0px;
    vertical-align: middle;
    padding: 0px;
    font-size: 0.75rem;
    text-transform: lowercase;
    background-color: unset !important;
    text-decoration: none;
  }
  .equipment-label:not(:first-child):before {
    content: "" !important;
  }
}
