#sidebar {
  width: 220px;
  background-color: #202020;
  .search-bar-container {
    display: grid;
    grid-template-rows: auto auto 1fr 30px;
    height: 100%;
  }

  .closed-press-area,
  main-overlay {
    display: none;
    background: none;
  }

  @media only screen and (max-width: 680px) {
    transition: all 0.3s linear;
    .closed-press-area,
    main-overlay {
      display: block;
    }
    &:not(.open) {
      width: 2px;
      box-shadow: -5px 0px 10px 2px rgba(255, 255, 255, 0.25) inset;
      .search-bar-container {
        opacity: 0;
        transition: opacity 0.3s linear;
      }
      .closed-press-area {
        position: fixed;
        top: 0;
        min-height: 100%;
        width: 25px;
        z-index: 2;
        opacity: 1;
      }
    }
    .sidebar-open-overlay {
      position: absolute;
      left: 220px;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  .search {
    padding: 15px 10px;
    label {
      color: $link-green;
    }
    .search-filter-field {
      padding: 0;
    }
    .search-input-field {
      padding: 0;
      font-size: 10px;
      margin-bottom: 10px;
    }
    select {
      margin: 0px;
    }
    input {
      margin-bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.2);

      &:focus {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
      }

      &:hover {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
      }
    }
  }
  .user-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-height: 40px;
    font-size: 1.36em;
    width: 100%;
    svg {
      transition: all 0.1s ease-in;
      margin: 0 15px 17px 0px;
    }
    .user-action {
      width: 15%;
      margin: 0 14px 0 14px;
      text-align: center;
      display: inline-block;
      color: $link-green;
      cursor: pointer;
      &:hover {
        svg {
          transform: scale(1.2);
          filter: brightness(120%);
        }
      }
    }
  }
}

ul.side-nav {
  width: 100%;
  padding: 0.5rem 0px;
  svg {
    color: $side-nav-link-color;
    font-size: 1.5em;
    margin-right: 5px;
  }
  li {
    color: $side-nav-font-color;
    margin: 8px 0px;
    padding-left: 20px;
  }
  a,
  .menu-link {
    color: $side-nav-link-color;
    cursor: pointer;
    user-select: none;
    i {
      color: $side-nav-link-color;
    }
    &:hover {
      color: $side-nav-font-color;
      font-weight: 500;
      filter: brightness(120%);
    }
  }
}

ul.navUl.subnav {
  margin-left: 0.7rem;
  margin-bottom: 0;
  li {
    margin: -5px 0px;
  }
}
.icon-container a,
.menu-link {
  padding: 0.2375rem 0.875rem;
}

.vmNumberIcon {
  padding: 5px;
  font-size: 0.75em;
  position: relative;
  left: 5px;
  top: 9px;
  color: #ff2525;
  border-radius: 50%;
  font-weight: 700;
}

.sidebar-menu {
  grid-row: 3;
  width: 100%;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  overflow-y: auto;
  padding-bottom: 5px;
  background: rgba(255, 255, 255, 0.01);
  direction: rtl;
  > * {
    direction: ltr;
  }
}

.update-btn {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333;
  margin: 0px;
  padding: 3px;
  cursor: pointer;
  font-weight: 500;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
}
.app-update-prompt {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
