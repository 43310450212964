.ui.container {
  margin-top: 20px;

  section {
    background: #FFF;
    box-shadow: 0 3px 3px #DDD;
    border: 1px solid #DDD;
    padding: 15px;

    header {
      margin-bottom: 15px;
      h2 {
        font-size: 1.6em;
      }
    }
  }
}

ul {
  list-style: none;
  margin-left: 0px;
}
