.s3-ui-field-table {

  border: 1px solid #eee;
  width: 100%;

  tbody {

    tr {

      &:nth-child(odd) {
        background: #fff;
      }

      &:nth-child(even) {
        background: #f9f9f9;
      }

      td {

        padding: 0.6rem;

        &.has-field {
          font-weight: bold;

          &::after {
            content: ":";
          }
        }
      }
    }
  }
}
