.pages {
  height: 100%;
  position: relative;

  .page-container {
    height: calc(100% - 60px);
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .page-select {
    background: #f3f3f3;
    height: 60px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    align-items: center;

    * + * {
      margin-left: 10px;
    }

    * {
      margin-bottom: 0;
      line-height: 1;
      // height: 40px;
      width: auto;
      display: flex;
      align-items: center;
    }

    select {
      min-width: 15rem;
    }

    i {
      font-size: 2rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
