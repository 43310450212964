.AppContainer {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 550px;
  width: 100%;
  margin: .5em;
  font-size: 13px;
  display: flex;
  flex-direction: column;
}

.AppContainer .MessageList {
  box-sizing: border-box;
  flex: 1;
}

.AppContainer .MessageForm {
  background: #eee;
  border-top: 1px solid #ccc;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  flex: 0 0 30px;
}
