



.timeline-container {
  $cell-height: 120px;
  $cell-width: 50px;
  $confirmed-color: rgb(19, 180, 255);
  $confirmation-pending-color: rgb(255, 211, 0);
  $product-demo-color: rgb(174, 22, 62);
  $time-off-color: #e2e1e1;
  $free-color: #006633;
  $expired-color: #8e8e8e;
  $non-working-hours: #e2e1e1;

  display: grid;
  grid-template-columns: 1fr;
  margin-top: 10px;
  overflow-x: scroll;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: relative;

  .timeline {
    display: grid;
    height: 100%;
    flex: 1 1 auto;
    grid-template-areas:
      "empty  time"
      "coaches cells";
    grid-template-columns: 170px;
    grid-template-rows: 60px;
    margin: 0 auto;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  }

  .timeline-coaches-header {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: white;
    grid-area: coaches;
    display: grid;
    // grid-template-columns: repeat(7, $cell-width);
    grid-template-rows: repeat(auto-fill, $cell-height);
    text-transform: uppercase;
    font-size: 12px;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    font-family: 'Poppins-Regular', "PT Sans", sans-serif;

    .timeline-coach {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    }
  }



  .times {
    grid-area: time;
    display: grid;
    grid-template-columns: repeat(15, 200px);
    font-size: 14px;
    background-color: #f7f7f7;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-family: 'Poppins-Regular', "PT Sans", sans-serif;
  }

  .times>div {
    display: grid;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  .time-slots-container-timeline {
    position: relative;
    grid-area: cells;
    display: grid;
    user-select: none;
    grid-template-columns: repeat(60, $cell-width);
    //grid-template-columns: repeat(7, $cell-width);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    grid-auto-flow: row;
    grid-auto-rows: $cell-height;
  }

  .time-slot {
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: $non-working-hours;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.cant-use {
      cursor: default;
    }

    &.disabled {
      background: $expired-color;
    }
    &.work-time {
      background: white;
    }
    &.lunch-time {
      background: $non-working-hours;
      cursor: default;
    }

    &.initial-row {
      background: #26bbad;
    }

    &.selected:hover {
      border: 1px solid rgba(0, 0, 0, 0.5);
      cursor: s-resize;
    }

    &:nth-child(4n) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.10) !important;
    }
  }
}