.bonafide-insurance-verification {
  $border: 1px solid #ddd;
  display: grid;
  grid-template-rows: 55px 1fr auto;
  height: 100%;
  // overflow-y: scroll;
  &.hidden {
    display: none;
    overflow: hidden;
    max-height: 0;
  }
  .bonafide-result-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    overflow-y: scroll;
    .bonafide-status-result-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 5px 10px;
      .bonafide-overview {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .status-label {
        font-size: 1.25rem;
        font-weight: 500;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        flex: 1;
      }
      .status-text {
        margin: 0px;
        font-size: 1.25rem;
        padding-left: 10px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      }
    }
    .bonafide-issue {
      margin-left: 1.3em;
      font-size: 1.15rem;
    }
    .reason-status-text {
      color: #333;
      padding: 5px;
      font-size: 1.15rem;
    }
    .bonafide-issue::before {
      content: "\2022";
      color: red;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
    .bonafide-errors-container {
      grid-column: 1;
      grid-row: 2;
      padding: 5px;
    }

    .bonafide-eq-info {
      padding: 3px;
      display: flex;
      flex-direction: column;
      grid-column: 2;
      grid-row: span 2;
      border-left: $border;
    }
  }
  .bonafide-footer {
    border-top: 1px solid #ddd;
    padding: 0px 5px;
    display: flex;
    flex: 1;
    width: 100%;
    background: #f2f2f2;
    align-items: center;
    position: sticky;
    bottom: 0;
    .bonafide-request-order-info {
      margin: 0px;
      margin-left: 20px;
      font-size: 1.15rem;
      float: left;
      font-size: 1rem;
      margin: 0 10px;
      padding: 4px 5px;
      height: 2.125rem;
      border-color: #b9b9b9;
      border-radius: 5px;
      transition: background-color 0.3s ease-out;
      user-select: none;
      border-color: #b9b9b9;
      &.current {
        cursor: pointer;
        color: #4281a6;
        &:hover {
          .step-label {
            text-decoration: underline;
          }
        }
        .step-number {
          border-color: #4281a6;
        }
      }
      &.unavailable {
        border-color: #b9b9b9;
        color: #999;
        pointer-events: none;
      }

      .step-number {
        border: 1px solid #aaa;
        height: 20px;
        width: 20px;
        padding: 2px;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color 500ms;
        margin-right: 10px;
        text-decoration: default !important;
      }
    }

    .cmn-warning {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 5px;
      align-items: center;
      justify-content: center;
      color: #e8910c;
      font-size: 14px;
    }

    .refresh-button {
      font-size: 1.15rem;
      cursor: pointer;
      grid-column: 1;
    }
  }
}
