.fill-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-message-container {
  .error-message {
    -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    padding: 2rem;
    color: #ff3333;
    background: #ddd;
    font-weight: 600;
    width: 40rem;
  }
  .back-btn {
    margin: 50px;
  }
}
