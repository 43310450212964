.Message {
  line-height: 1.5em;
}

.Message.log {
  color: #bbb;
}

.Message .author {
  margin: 0 5px 0 0;
}

.Message.me .author {
  color: #b6191e;
}

.host-message-body {
    display: inline-block;
    padding: 5px;
    background-color: rgba(37, 150, 216, 0.1);
    margin-top: 0px;
    margin-bottom: 8px;
    margin-right: 10px;
    margin-left: 18px;
    flex: 1;
    align-self: flex-end;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow-wrap: anywhere;
    color: #141c36;
}

.patient-message-body {
    display: inline-block;
    padding: 5px;
    background-color: whitesmoke;
    margin-top: 0px;
    margin-bottom: 8px;
    margin-right: 18px;
    margin-left: 10px;
    flex: 1;
    align-self: flex-start;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow-wrap: anywhere;
    color: #141c36;
}

.author-message-info-container {
    display: flex;
    margin-right: 5px;
    color: #0085d1;
}

.patient-message-info-container {
    display: flex;
    margin-right: 5px;
}

.timestamp {
    margin-left: 5px;
}