.pagination-controls {
  .pagination-controls-container {
    display: grid;
    width: auto;
    grid-template-columns: [previousPage] 20px [currentPage] auto [nextPage] 20px;
    user-select: none;
    align-items: center;
    margin: 0 5px;

    .arrow-container {
      cursor: pointer;
      svg {
        border: 1px solid #ddd;
        &:hover {
          background-color: #dfdfdf;
        }
      }
    }
    .previous-page {
      grid-area: previousPage;
    }
    .current-page {
      grid-area: currentPage;
      padding: 0 10px;
    }
    .next-page {
      grid-area: nextPage;
    }
  }
}
.sort-controls {
  display: inline-flex;
  user-select: none;
  align-items: center;
  margin: 0 5px;
  font-size: 1em;

  .sort-label-text {
    font-weight: bold;
    padding-right: 10px;
  }
  .sort-options {
    display: inline-flex;
    align-items: center;
    .sort-option {
      cursor: pointer;
      padding: 0 10px;
      display: grid;
      width: auto;
      grid-template-columns: [text] auto [icon] 20px;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }
      svg {
        grid-area: icon;
      }
      span {
        grid-area: text;
        padding: 0 5px;
      }
    }
    .sort-is-active {
      color: blue;
    }
  }
}
