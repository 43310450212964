$blue: #4A90E2;
$red: #D0021B;
$grey: #DCE0E0;
$dark-grey: #565A5C;
$white: #FFF;

$font: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
$font-head: "Avenir Next", Avenir, "Helvetica Neue", Helvetica, Arial, sans-serif;
$monospace: Inconsolata, Menlo, Consolas, monospace !important;

.DayPicker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 1rem 0;
  user-select: none;
  background: inherit;
}

.DayPicker-Month {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  user-select: none;
  margin: 0 1rem;
}

  .DayPicker-NavBar {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 .5rem;
  }

  .DayPicker-NavButton {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }

    .DayPicker-NavButton--prev {
      left: 1rem;
    }

    .DayPicker-NavButton--next {
      right: 1rem;
    }


  .DayPicker-Caption {
    display: table-caption;
    height: 1.5rem;
    text-align: center;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    > div {
      display: table-row;
    }
  }

    .DayPicker-Weekday {
      display: table-cell;
      padding: .5rem;
      font-size: .875em;
      text-align: center;
    }

  .DayPicker-Body {
    display: table-row-group;
  }

    .DayPicker-Week {
      display: table-row;
    }

      .DayPicker-Day {
        display: table-cell;
        padding: .5rem;
        border: 1px solid lighten($grey, 5);
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
      }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }


// Modifiers

.DayPicker-Day--today {
  color: $red;
  font-weight: 500;
}

.DayPicker-Day--disabled {
  color: $grey;
  cursor: default;
  background-color: lighten($grey, 7);
}

.DayPicker-Day--sunday {
  color: $grey;
  background-color: lighten($grey, 10);
}

.DayPicker-Day--outside {
  cursor: default;
  color: $grey;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: $white;
  background-color: $blue;
}
