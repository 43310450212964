.notifications-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 500;

  .box {
    animation: fade 0.1s linear;
    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    bottom: 10px;
    font-size: 1.15em;
    font-weight: 500;
    margin: 5px 10px;
    max-height: 70vh;
    overflow-wrap: break-word;
    overflow: hidden;
    right: 10px;
    transform: translate3d(0px, 0px, 0px);
    transition: opacity 0.1s ease-out;
    transition: transform 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    width: 350px;
    max-width: 500px;
    z-index: 100;
    .content {
      -webkit-box-flex: 1;
      flex-grow: 1;
      font-size: 16px;
      line-height: 1.4;
      min-height: 40px;
      padding: 1rem;
    }
    .dismiss {
      position: absolute;
      margin: 3px;
      top: 0px;
      right: 0px;
      cursor: pointer;
      font-size: 1.25em;
      &:hover {
        filter: brightness(75%);
        transform: scale(1.1);
      }
    }
  }

  .box.success {
    background-color: rgb(227, 252, 239);
    box-shadow: rgba(0, 0, 0, 0.275) 0px 3px 8px;
    color: rgb(0, 102, 68);
  }

  .box.warning {
    background-color: rgb(255, 235, 230);
    box-shadow: rgba(0, 0, 0, 0.275) 0px 3px 8px;
    color: rgb(191, 38, 0);
  }
}
