.sleepCoachHeatMapTitle {
  text-align: center;
  box-shadow: 0px 1px 9px 0px rgba(136, 136, 136, 0.7294117647058823);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.07058823529411765);
    color: white;
  }
}

.sleep-coach-score-card {
  .coach-score-card-container {
    display: grid;
    grid-template-columns: 33% 66%;
    grid-gap: 5px;
    margin-bottom: 20px;
    .leftSection {
      padding: 10px 30px 0 30px;
    }
    .rightSection {
      .score-card-header {
        display: flex;
        flex: 1;
        padding-bottom: 5px;
        h2 {
          flex: 1;
        }
        .date-controls-container {
          display: grid;
          grid-template-columns: auto auto auto;
          align-items: center;
          grid-gap: 20px;
          margin-bottom: 5px;
          button {
            margin: 0;
          }
        }
      }
    }
  }
  .sum-block-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .equipment-cards-container {
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
  }
  .order-cards-container {
    padding: 0 0 10px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
  .insight-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}
