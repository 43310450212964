.items-sold-container {
  border: 1px solid #ddd;
  padding: 16px 20px 20px 20px;
  margin-bottom: 20px;
  width: 100%;
  .bar-graph-header {
    font-size: 1.4em;
    text-transform: uppercase;
  }
  .graph-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.graph-card {
  border: 1px solid #ddd;
  display: flex;
  flex: 1;
  flex-direction: column;
  .card-top {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #fff;
    position: relative;
    padding: 5px 20px;
    // height: 385px;
  }
  .card-bottom {
    background-color: #2b4059;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  .card-header {
    font-size: 1.4em;
    text-transform: uppercase;
  }
}

.stat-export-icon {
  color: green;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
}

.item-types-requested-container {
  border: 1px solid #ddd;
  padding: 16px 20px 20px 20px;
  max-height: 535px;
  margin-top: 20px;
  h2 {
    font-size: 1.4em;
  }
  // @media print {
  //   .recharts-wrapper,
  //   .recharts-surface {
  //     min-height: 100%;
  //     max-width: 100%;
  //     max-height: 100%;
  //     height: auto !important;
  //     width: auto !important;
  //   }
  // }
}
.orders-completed {
  border: 1px solid #ddd;
  padding: 16px 20px 20px 20px;
  max-height: 535px;
  margin-top: 20px;
  h2 {
    font-size: 1.4em;
  }
  .orders-completed-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10;
  }
  // @media print {
  //   .recharts-wrapper,
  //   .recharts-surface {
  //     min-height: 100%;
  //     max-width: 100%;
  //     max-height: 100%;
  //     height: auto !important;
  //     width: auto !important;
  //   }
  // }
}
