.patient-lookup-container {
  background: #f2f2f2;
  border-bottom: 1px solid #ccc;
  padding: 30px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  flex: 1 1 auto;
  .patient-lookup-header {
    font-size: 2.3em;
    font-weight: 300;
    color: rgb(102, 102, 102);
    margin: 0px 5px 10px 5px;
    border-bottom: 1px solid rgb(221, 221, 221);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.clickable-link {
  color: #4281a6;
  cursor: pointer;
  font-style: normal;
}
.patient-lookup-form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  padding: 10px;
  > * {
    margin: 0;
    height: 25px;
    padding: 0 5px;
  }
}
