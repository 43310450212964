.bulk-order-update {
  margin: 10px;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 1rem;
  }

  .bulk-order-submit {
    text-transform: uppercase;
  }
}
