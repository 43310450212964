$ui-button-color: #2185d0 !default;
$ui-button-font-size: 110% !default;

.ui {
  a.button {
    background-color: $ui-button-color;
    border-radius: 4px;
    box-shadow: 0 3px 3px #ddd;
    font-size: $ui-button-font-size;
    margin-bottom: 0;
    margin-right: 10px;
    padding: 10px 15px;

    &:hover {
      background-color: darken($ui-button-color, 10%);
      box-shadow: 0 1px 3px #ddd;
    }
  }
}

.flat-icon-button {
  cursor: pointer;
  color: rgb(66, 129, 166);
  font-size: inherit;
  padding: 0 7px;
  margin: 0 5px;
  user-select: none;
  &:disabled,
  &.disabled {
    color: #aaa !important;
    pointer-events: none;
  }
  &:hover,
  &:focus {
    transform: scale(1.025);
  }
  &:focus {
    outline: none;
    transform: scale(1.025);
  }
}
.link {
  color: #4281a6;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #396f8f;
    outline: 0;
  }
}
