.s3-ui-variable-form-field {
  select, input, button {
    margin: unset;
  }

  .variable-field {
    display: flex;
    align-items: flex-start;

    > *:first-child {
      flex: 1;
      margin-right: 2rem;

      display: flex;
      flex-direction: column;
    }

    .remove-field {
      font-size: 2rem;
      cursor: pointer;
    }
  }

  .variable-field-label-text {
    font-weight: bold;
  }  

  .add-field-container {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    *:first-child {
      flex: 1;
      margin-right: 0;
    }

    .choose-field {
      font-weight: bold;
    }
  }
}
