.twilio-call-profile {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px 30px;
  .page-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(221, 221, 221);
    align-items: center;

    h2 {
      font-size: 2.3em;
      font-weight: 300;
      color: rgb(102, 102, 102);
      > span {
        font-size: 1.25rem;
        padding-left: 15px;
      }
    }
  }
  .fetch-error {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    color: red;
  }
  .twilio-call-profile-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 50px;
    &.is-fetching {
      opacity: 0.5;
    }
    .call-summary-container {
      padding: 25px;
      .call-direction-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 1.2rem;
        grid-gap: 30px 15%;
      }
      .call-direction-header {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #dfdfdf;
        justify-content: space-between;
        .direction-label {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          color: #085b55;
          font-size: 1.5rem;
          padding-right: 10px;
        }
        .direction-info {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          padding: 0 5px;
        }
      }
      .summary-label {
        padding-right: 20px;
        font-weight: bold;
      }
      .call-type-container {
        display: flex;
        flex-direction: column;
      }
      .call-direction-details {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .call-type-container,
      .call-direction-details {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
      }
    }
    .call-duration-container,
    .recording-container,
    .call-time-container {
      padding: 20px;
    }
  }
}
