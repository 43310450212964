.google-button {
  margin: 0px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
    background: white;
    color: #737373;
  }

  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }
}

.google-button-icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button-icon--plus {
  width: 27px;
}

.google-button-text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

.file-preview-container {
  overflow: hidden;
}

.file-preview-frame {
  position: absolute;
  // top: 22px;
  // left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.new-file-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.company-info-sheets {
  .info-sheets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    .drive-action {
      color: #4281a6;
      font-size: 18px;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }
    }
  }
}

.user-files-page-header {
  margin: 20px 15px;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folder-files-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  padding-bottom: 20px;
  h4 {
    text-align: center;
    svg {
      margin: 0 10px;
    }
  }
}

.user-files-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding: 1rem;
  .file-card {
    background: #fdfdfd;
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    border-radius: 5px;
    height: 100px;
    box-shadow: 0 0 5px rgba(33, 33, 33, 0.3);

    .card-wrapper {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      transition: all 0.1s ease-in;
      overflow: hidden;
      .file-name {
        color: #085b55;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
        justify-self: stretch;
        padding: 5px 10px;
      }
      &.active {
        animation-duration: 0.5s;
        animation-name: fadeIn;
        animation-timing-function: cubic-bezier(0.71, 0.55, 0.62, 1.57);
        box-shadow: 0 0 10px rgba(33, 33, 33, 0.5);
        display: grid;
        grid-template-rows: auto 1fr 1fr;
        background: rgba(33, 33, 33, 0.02);
        .file-name {
          cursor: auto;
          background: white;
          font-size: 1em;
        }
        .menu-option {
          padding: 9px 0px;
          text-align: center;
          border-bottom: 1px solid rgba(33, 33, 33, 0.06);
          height: 100%;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
    &:hover {
      box-shadow: 0 0 8px rgba(33, 33, 33, 0.5);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
