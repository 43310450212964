.coach-status {
  table {
    border: none;
    border-collapse: collapse;
  }
  table tbody td:nth-child(5) {
    border-right: 1px solid #ccc;
  }
  .coach-status-table {
    height: auto;
  }
}
.coach-status-table {
  .coach-controls-dropdown {
    display: flex;
    align-items: center;
    .coach-controls-menu {
      cursor: pointer;
      font-size: 1.35em;
      &:hover {
        color: #222;
        svg {
          transform: scale(1.25);
        }
      }
    }
    .dropdown-list {
      margin: 0;
      .dropdown-item {
        border-bottom: 1px solid #ccc;
        .toggle-switch {
          pointer-events: none;
        }
      }
    }
  }
  .coach-name-column {
    .coach-name {
      padding-right: 10px;
    }
    .coach-status-icon {
      padding-left: 5px;
      color: #333;
      font-size: 1.15em;
    }
  }
}
