
.thanks-container{
  background: #ffffff;
  width: 100%;
  height: 100%;
  height: 100vh;
  padding: 100px 0;
  text-align: center;
  font-size: 2.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
