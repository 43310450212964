.draggable-tag {
  display: inline-flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  margin: 8px;
  background-color: #74a0ba;
  border-left: 3px solid #007cc2;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    #bbdcef 15.98%,
    #007cc2 94.12%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: grab;

  .draggable-tag-handle {
    display: inline-block;
    margin-left: 4px;
    font-size: 1.5em;
    color: #007cc2;
    border-radius: 5px 0 0 5px;
  }

  .draggable-tag-content {
    padding: 10px;
    font-size: 1.3em;
    font-style: normal;
    font-weight: bold;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.45);
    margin-left: 10px;
  }
}

.draggable-bucket-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .draggable-bucket-title {
    font-size: 1.2em;
    font-weight: bold;

    .draggable-bucket-icon {
      float: right;
    }
  }

  .draggable-bucket {
    width: 100%;
    min-height: 80px;
    flex-grow: 1;
    border: 1px solid #aaa;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
  }
}

.draggable-tag:active {
  cursor: grabbing;
}

.draggable-tag-disabled {
  @extend .draggable-tag;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    #dddddd 15.98%,
    #aaaaaa 94.12%
  );
  border-left: 3px solid #aaaaaa;

  .draggable-tag-handle {
    color: #aaaaaa;
  }
}