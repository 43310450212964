.patient-insurance-section {
  .patient-insurance-edit {
    .searchable-dropdown {
      input,
      select {
        width: 100%;
        height: 1rem;
        padding: 1px;
        padding-left: 3px;
        font-size: 14px;
        margin: 0;
      }
    }
  }

  .insurance-section-header {
    font-size: 1.2rem;
    border-bottom: 1px solid #eee;
    margin-top: 10px;
  }
}
