.compliance-action-view-card {
  display: grid;
  grid-template-rows: 40px 1fr;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  background-color: #f5f5f5;
  max-height: 150px;
  border-radius: 4px;
  border-top: 3px;
  border-bottom: 8px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;

  .compliance-action-view-card-title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .compliance-action-view-card-value {
    font-size: 2.5em;
    text-align: center;
  }

  .tile-title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  .infoCircle {
    cursor: pointer;
    position: relative;
    left: 0.25rem;
    bottom: 0.5rem;
  }
}