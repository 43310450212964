.admin-dashboard-container {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(3, 8rem) repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "active-pts active-pts"
    "ready-outreach-pts ready-outreach-pts"
    "ins-elig-pts ins-elig-pts"
    "orders-table orders-table"
    "orders-claimed orders-claimed"
    "helpful-links helpful-links"
    "announcements announcements";
}

@media only screen and (min-width: 900px) {
  /* For small desktop or tablet: */
  .admin-dashboard-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 8rem 50% 1fr;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "active-pts active-pts ins-elig-pts ins-elig-pts ready-outreach-pts ready-outreach-pts"
      "orders-table orders-table orders-table orders-table helpful-links helpful-links"
      "announcements announcements announcements announcements announcements announcements";
    /* grid-template-areas:
       "active-pts active-pts ins-elig-pts ins-elig-pts ready-outreach-pts ready-outreach-pts"
       "orders-table orders-table orders-claimed orders-claimed orders-claimed orders-claimed"
       "announcements announcements announcements announcements helpful-links helpful-links";
    */
  }
}

@media only screen and (min-width: 1200px) {
  /* For large desktop: */
  .admin-dashboard-container {
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 8rem 50% 1fr;
    grid-template-areas:
      "active-pts active-pts ins-elig-pts ins-elig-pts ready-outreach-pts ready-outreach-pts"
      "orders-table orders-table orders-table orders-table helpful-links helpful-links"
      "announcements announcements announcements announcements announcements announcements";
  }
}
