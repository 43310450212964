.company-demo-logs-container {
  height: 100%;
  width: 100%;
  flex-direction:column;
}

.company-content {
  height: 100%;
}

.demo-logs-table {
  border-radius: 10px;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  text-align: center;

  .demo-logs-header-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .demo-logs-header {
      text-align: center;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      font-size: 1.4em;
      padding: 5px;
      border-bottom: 1px solid #888;
    }
  }

  .demo-logs-content-container {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .spinner-container {
      position: absolute;
      left: 48%;
      top: 40%;
    }

    .demo-logs-content {
      padding: 8px;
      font-size: 1.2em;
      background-color: rgba(225, 225, 225, 0.5);
    }

    .demo-logs-content:nth-child(12n + 1),
    .demo-logs-content:nth-child(12n + 2),
    .demo-logs-content:nth-child(12n + 3),
    .demo-logs-content:nth-child(12n + 4),
    .demo-logs-content:nth-child(12n + 5),
    .demo-logs-content:nth-child(12n + 6) {
      background-color: rgba(255, 255, 255, 0.7);
    }

    .demo-logs-not-found {
      grid-column: 1/7;
      text-align: center;
      color: salmon;
      font-size: 2em;
      padding: 20px;
    }
  }

  .pagination-control-container {
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 0 0 10px 10px;
    background: linear-gradient(
      258deg,
      rgb(45, 198, 184) 0%,
      rgb(36, 156, 145) 100%
    );

    .page-select {
      text-align: center;
      width: 60px;
      margin: 10px;
    }

    .per-page-select {
      position: absolute;
      left: 10px;
      text-align: center;
      width: 60px;
      margin: 10px;
    }

    .pagination-control {
      display: inline-block;
      margin: 5px;
    }
  
    .clickable {
      cursor: pointer;
    }
  }
}
