.searchable-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: default;

  .searchable-dropdown-content {
    background-color: #f6f6f6;
    overflow: auto;
    border: 1px solid #999;
    z-index: 1;

    input {
      border: none;
      margin-bottom: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
      background-position: right;
      background-repeat: no-repeat;
      padding-right: 15px;
    }

    input:focus {
      outline: 3px solid #ddd;
    }

    .searchable-dropdown-items-container {
      position: absolute;
      min-width: 100%;
      width: inherit;
      max-height: 60vh;
      overflow: auto;
      z-index: 500;
      background-color: #fafafa;
      border: 1px solid #ddd;

      .searchable-dropdown-item {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

      .searchable-dropdown-item-focused {
        background-color: #ddd;
      }
    }
  }
}

.modal-window-content {
  .searchable-dropdown {
    .searchable-dropdown-items-container {
      // position: fixed !important;
      max-height: 300px !important;
    }
  }
}
