.query-table-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  .query-header-controls {
    grid-row: 1;
  }
  .query-table-header {
    position: sticky;
    z-index: 2;
    border: 1px solid #eee;
  }
  .query-table {
    grid-row: 2;
    height: calc(100vh - 200px);
    overflow: auto;
    width: 100%;
    margin-bottom: 0;
    background: #f5f5f5;
    border: 1px solid #eee;
    position: relative;
    .table-loading-spinner {
      height: 0px;
      position: sticky;
      top: 50%;
    }
    table {
      table-layout: fixed;
      width: 100%;
      background: white;
      margin-bottom: 0;
      &.loading {
        opacity: 0.5;
      }
    }
    tr.even {
      background: #fff;
    }
    tr.odd {
      background: #f9f9f9;
    }

    .query-table-header-cell-container,
    .batch-header-cell {
      position: sticky;
      background: #f5f5f5;
      top: -1px;
      z-index: 1;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
      width: 120px;
      min-width: 120px;

      .query-table-header-cell {
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 110px;
        .header-text {
          min-width: 85px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
          cursor: pointer;
          user-select: none;
          text-decoration: underline;
          font-size: 1rem;
          display: flex;
          flex: 1 1 auto;
          &.active {
            color: #af0200;
          }
        }
        .small-header-text {
          font-size: 0.9rem;
        }
        .column-filter-trigger {
          box-shadow: none;
          font-size: 1rem;
          width: 20px;
        }
        .dropdown {
          display: flex;
          flex: 1 1 auto;
          justify-content: flex-end;
        }
        .filter-downselect-input {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 10px;
          padding: 5px;
          margin: 5px;
          input {
            margin: 0;
            width: 100%;
            color: #333;
          }
        }
      }
    }
    .batch-header-cell {
      width: 40px;
    }
  }
  .query-table.dynamic-columns {
    table {
      table-layout: auto;
    }
    .custom-blue-header { 
      background-color: #4684aa !important;
      color: white;
    }
  }

  // TODO: refactor style sheet assing properties to dynamic columns class so important declaration is not needed
  .query-table.static-columns {
    overflow: unset !important;
    .query-table-container {
      overflow: unset !important;
    }
    table {
      table-layout: auto !important;
    }
    .query-table-header-cell {
      width: unset;
    }
    .query-table-header-cell-container {
      min-width: unset;
      position: unset !important;
    }
    .header-text {
      font-size: 0.9em !important;
      min-width: 35px !important;
    }
  }

  .query-footer {
    grid-row: 3;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    background: #eee;
    border: 1px solid #eee;
    padding: 0.5rem 10px;
    .page-select {
      display: grid;
      grid-template-columns: auto auto auto auto;
      flex: 1 1 auto;
      grid-gap: 10px;
      align-items: center;
      justify-content: center;
      justify-self: center;
      justify-items: center;
      select {
        width: 45px;
        margin: 0;
      }
      .next-page,
      .previous-page {
        color: #4281a6;
        cursor: pointer;
        min-width: 40px;
      }
    }
  }

  .per-page-options-container {
    display: flex;
    align-items: center;
    label {
      color: #4281a6;
    }
    input,
    select {
      margin: 0px 10px;
      width: 60px;
    }
    .per-page-options-mode {
      cursor: pointer;
      font-size: 1.5em;
      &:hover {
        filter: brightness(180%);
        transform: scale(1.1);
      }
    }
  }
  .total-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    color: #4281a6;
    .count-label {
      padding-right: 0.5rem;
    }
    .count-value {
      font-weight: 600;
    }
  }

  .query-table-controls {
    display: flex;
    background: linear-gradient(#2dc6b8, #249c91);
    border-radius: 4px 4px 0px 0px;
    padding: 5px;
    color: hsla(0, 0%, 100%, 0.6);
    align-items: center;
    box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.4);
    .action-buttons {
      display: flex;
      align-items: center;
      padding-right: 20px;
      height: 100%;
      .icon-button {
        align-self: flex-end;
        font-size: 20px;
        padding: 0 5px;
        font-size: 20px;
        color: hsla(0, 0%, 100%, 0.6);
        cursor: pointer;
      }
    }
    select {
      height: 25px;
      width: 250px;
      margin: 0;
      background-color: hsla(0, 0%, 100%, 0.5);
      border: 1px solid hsla(0, 0%, 100%, 0.6);
    }
    .selected-rows-text {
      margin-left: 10px;
      color: #fdfdfd;
      font-size: 1em;
    }
  }
}

@media only screen and (max-width: 680px) {
  .query-table-container,
  .query-table {
    height: 100% !important;
    overflow: unset !important;
  }
}
