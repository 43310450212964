.ui-data-column-filter-table {

  > .filter-container {

    display: flex;
    width: 60%;

    .filters {

      $spacing: 15px;
      margin-right: $spacing;
      flex: 1;

      .filter {

        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: $spacing;

        .select-container {
          flex: 1;
          display: flex;
          margin-right: $spacing;

          select {
            margin-bottom: 0;
          }
        }

        input {
          flex: 2;
          margin-right: $spacing;
          margin-bottom: 0;
        }

        .mod-filter {
          font-size: 20px;
          cursor: pointer;
        }

      }

    }

  }

}
