.print-company-monthly-report {
  &_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
  }
  &_toggle-print-view {
    cursor: pointer;
    color: #fff;
    background: #4281a5;
    width: 12%;
    float: left;
    text-align: center;
    border-radius: 2.5px;
    font-size: 1rem;
    padding: 0.5rem;
    margin-left: 0.5rem;
  }
  &_print-button {
    cursor: pointer;
    font-size: 1.5rem;
  }
  &_sum-block {
    margin: 0.5rem 0;
    display: flex;
    width: 30%;
    flex-direction: column;
    border: 1px solid darkgrey;
    font-size: 15px;
  }
  &_sum-block-header {
    margin: 0.25rem;
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    background-color: #f0efef;
  }
  &_sum-block-row {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
  }
  &_container {
    padding: 1.5rem 4.0rem;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: white;
  }
  &_completed-orders-chart-container {
    border: 1px solid #DDD;
    padding: 16px 20px 20px 20px;
    max-height: 535;
    margin-top: 20;
  }
  &_hide-root {
    display: none;
  }
  &_table-container {
    padding: 0.25rem 0;
  }
  &_table-row-row:nth-of-type(even) {
    background-color: grey;
  }
  &_table-row td{
    height: 0.75rem;
    font-size: 0.775rem;
    padding: 0.4rem 0.6rem;
  }
  &_pt-table {
    &--left-column {
      float: left;
      width: 95%;
    }
    &--right-column {
      float: right;
      width: 95%;
    }
  }
  &_pt-table-title-container {
    margin-left: 1rem;
  }
  &_orders-completed-table {
    width: 100%;
  }
  &_body {
    padding: 0.5rem;
  }
  &_footer {
    padding: 10px 0;
  }
  &_section-title {
    margin-left: 0.5rem;
    font-weight: bold;
  }
  &_section-title-container {
    padding: 0;
  }
  &_header {
    border-bottom: 1px solid #DDD;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }
  &_title {
    padding: 2rem 0.8rem;
  }
  &_date-container {
    padding: 0 0 0.5rem 1rem;
    font-size: 1.25rem;
    font-style: italic;
  }
  &_page-1-container {
    padding-left: 0.5rem;
  }
}

.activation-report {
  &_pie-chart-container {
   display: flex;
   flex-direction: row;
  }
  &_pie-chart {
    margin: 0 auto;
    width: 50%;
  }
  &_pie-chart-header {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  &_graph_legend_container {
    height: 10rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem 0;
    margin: 0 auto;
  }
}
.activation-report_pie-chart .recharts-wrapper {
  margin: 0 auto;
}
.company-monthly-report_container .recharts-wrapper {
  margin: 0 auto;
}
.company-monthly-report {
  .sumblocks {
    margin-bottom: 15;
  }
  .platform-header {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 10;
  }
  &_container {
    padding: 1rem;
  }
  &_tabs-container {
    margin-left: 1.8rem;
  }
}
.branch-form,
.company-form {
  .address-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0px 20px;
    :first-child {
      grid-column: 1/-1;
    }
  }
}

