div#reset_password_screen {
  .login_panel {
    width: 650px;
    padding: 15px;
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
    .form-inputs {
      input {
        font-size: 18px;
      }
    }
    label {
      color: #888;
    }
    a {
      color: #ddd;
    }
  }
}
