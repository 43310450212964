.blacklist-company-numbers-icon{
    width: 30px;
    position: relative;
    height: 30px;
    top: 13px;
}

.blacklist-refresh-icon{
    position: relative;
    top: 11px;
    height: 30px;
    width: 30px;
}

.blocked-company{
    border-radius: 5px;
    //border-width: 1px;
    //border-color: #c5c5c5;
    //border-style: solid;
    width: 50%;
    padding-bottom: 20px;
    margin-bottom: 5px;
    box-shadow: 0 0 3px #5d5d5d78;
}