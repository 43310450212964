.twilio-export {
  .form-container {
    width: 40rem;
  }
}

.twilio-export-form {
  display: flex;
  flex-direction: column;

  input {
    margin: unset;
  }

  > * + * {
    margin-top: 1rem;
  }

  .twilio-export-type {
    display: flex;
    > * + * {
      margin-left: 2rem;
    }
    input {
      margin-right: 0.2rem;
    }
  }

  .twilio-export-submit-button {
    flex: 1;
    text-transform: uppercase;
    font-weight: bold;
    height: 4rem;
  }
}

.to-input-field-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
}
