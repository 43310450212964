.phone-dashboard-container {
  $container-color: #fff;
  .phone-dashboard-header {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    grid-gap: 20;
    .phone-dashboard-date-range-container {
      display: flex;
      flex: 1;
      select {
        margin: 0;
      }
    }
    .DayPickerInput {
      input:disabled {
        background-color: rgb(221, 221, 221);
      }
    }
  }

  .phone-dashboard-current-stats {
    padding: 30px 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    .data-card {
      margin: 10px 0px;
    }
  }

  .data-card {
    display: grid;
    grid-template-rows: 35px 1fr auto;
    background-color: $container-color;

    &.not-applicable {
      opacity: 0.15;
    }
    border-radius: 3px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding-bottom: 5px;

    .data-card-header {
      font-size: 13.1795px;
      font-weight: 700;
      line-height: 23.9999px;
      padding: 6px 14px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .data-card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 14px 12px 14px;
      font-size: 36px;
    }
    .data-card-sub-row {
      border-top: 1px solid #d7dde5;
      display: grid;
      grid-template-columns: 50% 50%;
    }
    .data-card-sub-section {
      &:nth-child(2n) {
        border-left: 1px solid rgb(215, 221, 229);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px 5px;
      .sub-label {
        font-size: 12px;
        font-weight: 700;
      }
      .sub-content {
        font-size: 30px;
        white-space: nowrap;
        sub {
          margin-left: 5px;
          font-size: 10px;
        }
      }
    }
  }

  .call-insights-chart {
    padding: 30px;
    .call-insights-chart-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      > span {
        padding-left: 5px;
      }
    }
  }
}
