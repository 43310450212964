.dialer-actions-container {
  .dialer-actions-submit {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    padding: 10px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 20px;
    .patient-container-info {
      border: 1px solid #ddd;
      border-radius: 5px;
      flex: 1;
      flex-direction: column;
      padding: 30px;
      background-color: #fafafa;
    }
  }
  .dialer-actions-card-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    grid-template-rows: auto auto;
    padding: 1rem;
    h3 {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    .card {
      background: #fdfdfd;
      cursor: pointer;
      margin-bottom: 5px;
      display: flex;
      flex: 1 1 auto;
      border-radius: 5px;
      user-select: none;
      height: 50px;
      box-shadow: 0 0 5px rgba(33, 33, 33, 0.3);
      &.selected {
        border: 2px solid #888;
        background: #f0f0f0;
      }
      .card-wrapper {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        transition: all 0.1s ease-in;
        overflow: hidden;
        .action-name {
          color: #085b55;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          user-select: none;
          font-size: 18px;
          white-space: nowrap;
          flex: 1 1 auto;
          justify-self: stretch;
          padding: 5px 10px;
        }
        &.active {
          animation-duration: 0.5s;
          animation-name: fadeIn;
          animation-timing-function: cubic-bezier(0.71, 0.55, 0.62, 1.57);
          box-shadow: 0 0 10px rgba(33, 33, 33, 0.75);
          display: grid;
          grid-template-rows: auto 1fr 1fr;
          background: rgba(33, 33, 33, 0.15);
        }
      }
      &:hover {
        box-shadow: 0 0 8px rgba(33, 33, 33, 0.5);
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

.call-disposition-form {
  padding: 1rem;
  h3 {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .card {
    background: #fdfdfd;
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    border-radius: 5px;
    user-select: none;
    height: 50px;
    box-shadow: 0 0 5px rgba(33, 33, 33, 0.3);
    &.selected {
      border: 2px solid #888;
      background: #f0f0f0;
    }
    .card-wrapper {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      transition: all 0.1s ease-in;
      overflow: hidden;
      .action-name {
        color: #085b55;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        user-select: none;
        font-size: 18px;
        white-space: nowrap;
        flex: 1 1 auto;
        justify-self: stretch;
        padding: 5px 10px;
      }
      &.active {
        animation-duration: 0.5s;
        animation-name: fadeIn;
        animation-timing-function: cubic-bezier(0.71, 0.55, 0.62, 1.57);
        box-shadow: 0 0 10px rgba(33, 33, 33, 0.75);
        display: grid;
        grid-template-rows: auto 1fr 1fr;
        background: rgba(33, 33, 33, 0.15);
      }
    }
    &:hover {
      box-shadow: 0 0 8px rgba(33, 33, 33, 0.5);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.disposition-options {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-between;
}

.disposition-options > .card {
  width: 425px;
}
