/* For mobile: */
.company-admin-dashboard-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(3, 8rem) repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "active-pts active-pts"
    "ready-outreach-pts ready-outreach-pts"
    "ins-elig-pts ins-elig-pts"
    "orders-table orders-table"
    "orders-claimed orders-claimed"
    "helpful-links helpful-links"
    "announcements announcements";
}

@media only screen and (min-width: 900px) {
  /* For tablet: */
  .company-admin-dashboard-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: repeat(3, 8rem) 1 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "active-pts active-pts"
      "ready-outreach-pts ready-outreach-pts"
      "ins-elig-pts ins-elig-pts"
      "orders-table orders-table"
      "orders-claimed orders-claimed"
      "helpful-links helpful-links"
      "announcements announcements";
  }
}

@media only screen and (min-width: 1200px) {
  /* For desktop: */
  .company-admin-dashboard-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 8rem 1fr;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "active-pts active-pts ins-elig-pts ins-elig-pts ready-outreach-pts ready-outreach-pts"
      "orders-table orders-table orders-claimed orders-claimed orders-claimed orders-claimed"
      "announcements announcements announcements announcements helpful-links helpful-links";
  }
}
