.questionTable{
    font-size: 1.5em;
    display: grid;
    grid-template-columns: 1fr 3fr 4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 2em;
    justify-items: stretch;
    align-items: center;
    text-align: center;
}
.questionTableHeaders{
    background-color: #025771;
    align-self: stretch;
    color: white;
    font-weight: 800;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
.ivr_question{
    margin: 10px;
}

.questionTableRemoveButtonOff{
    margin: 2px;
    color: white;
    padding: 7px;
    background: linear-gradient(#c83333, #690000);
    border-radius: 5px;
    cursor: pointer;
    &:hover{
       background: linear-gradient(#9c2828, #3b0000);
    }
}
.questionTableRemoveButtonOn{
    margin: 2px;
    color: white;
     padding: 7px;
    background: linear-gradient(#33c833, #006904);
    border-radius: 5px;
    cursor: pointer;
    &:hover{
       background: linear-gradient(#269526, #004703);
    }
}

.questionTableEditButton{
    margin: 2px;
    color: white;
    padding: 7px;
    background: linear-gradient(#33a5c8, #005069);
    border-radius: 5px;    
    cursor: pointer;
    &:hover{
          background: linear-gradient(#257b96, #003546);
    }
}

.questionLocation{
   border-radius: 5px;
    background: linear-gradient(#33a5c8, #005069);
    text-align: center;
    font-size: 2em;
    color: white;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    cursor: pointer;
    &:hover{
    background-color:rgb(2, 87, 113);
    }
}
.questionLocationInactive{
   border-radius: 5px;
   background: linear-gradient(#c83333, #690000);
    text-align: center;
    font-size: 2em;
    color: white;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    cursor: pointer;
    &:hover{    
     background: linear-gradient(#9c2828, #3b0000);
    }
}
.orderQuestionTableHeader{
    text-align: center;
     font-size: 5em;
      color: rgb(2, 87, 113);
     cursor: pointer;
    &:hover{
        color: #0090bc;
    }
}

.orderQuestionsForm{
    font-size: 4em;
    text-align: center;
    color: rgb(0, 144, 188);
    cursor: pointer;
    &:hover{
    color:rgb(2, 87, 113);
    }
}

.orderQuestionFormDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    select{
        font-size: 1em;
    }
    input{
        font-size: 1.4em;
    }
    label{
        font-size: 1.4em;
    }
    textarea{
        font-size: 1.4em;
    }
}

.orderFormQuestionsSubmit{
    margin: 1em !important;
    display: grid;
    grid-template-columns: 12fr 1fr;
    grid-column-gap: 1em;
    justify-items: stretch;
    align-items: end;
    input{
        margin: 0;
        font-size: 1.5em;
    }
    button{
        margin: 0;
        background: linear-gradient(#33a5c8, #005069);
        border-radius: 5px;
        position: relative;
        top: 5px;
        &:hover{
           background: linear-gradient(#257b96, #003546);
        }
    }
}

///////////////////////////////////////////////////////////

///////////////Tabs/////////////////////////////////////////

.s3Tab{
    margin-left: 11px;
    padding-bottom: 10px;
}

.s3TabContent{
margin: 10px;
}
.s3TabContentHidden{
    display: none;
}

.s3TabLink{
    font-size: 1.5em;
    color: rgb(0, 144, 188);
    padding: 10px;
    background-color: #fafafa;
    cursor: pointer;
    &:hover{
        color:white;
        background-color: rgb(0, 144, 188);;
    }
}

.s3TabLinkActive{
    cursor: pointer;
    font-size: 1.5em;
    color:rgb(0, 144, 188);
    border-bottom: 5px rgb(0, 144, 188) solid;
    padding: 10px;
    background-color:#fafafa;
}

//////////////////////states/////////////////////@at-root

.statesLocation{
    text-transform: capitalize;
     border-radius: 5px;
    background-color: #085b559c;
    text-align: center;
    font-size: 2em;
    color: white;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.statesTable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    justify-items: center;
    align-items: center;
}

.ivrCallsTable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    justify-items: center;
    align-items: center;
}
.statesTableHeaders{
    border-radius: 5px;
    background-color: #085b55;
    color: white;
    font-size: 2em;
    font-weight: 800;
    text-align: center;
}

.statesTableLink{
  cursor: pointer;
  color: blue;
  font-weight: "800";
  &:hover{
      color:darkblue;
  }
}
.identity{
    text-align: center;
    padding: 6px;
     border-radius: 5px;
    background-color: #E5F3F2;
    color:black;
}
.consent {  
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #CCE8E5;
    color:black;
  }
  
  .noConsent {  
    padding: 6px;
    text-align: center;
    border-radius: 5px;
    color: white;    
    animation-name: noConsentAnim;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    -webkit-animation-name: noConsentAnim;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 0.7s;
  }
  @keyframes noConsentAnim {
    0% {
      background-color: red;
    }
    50% {
      background-color: black;
    }
    100% {
      background-color: red;
    }
  }
  @-webkit-keyframes noConsentAnim {
    0% {
      background-color: red;
    }
    50% {
      background-color: black;
    }
    100% {
      background-color: red;
    }
  }

.supplies{
    text-align: center;
    padding: 6px;
     border-radius: 5px;
    background-color: #B2DCD8;
    color:black;
}


.confirmation{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #99D1CC;    
    color:black;
}

.insurance{   
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: 7FC5BF;
    color:black;
}

.dysfunction{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #66BAB2;
    color:black;
}
.qualified{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #4CAEA6;
    color:black;
}
.threeMonthSupply{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #33A399;
    color:white;
}
.compliance{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #19978C;    
    color:white;
}
.quantity{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #008C80;
    color:white;
}

.address{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #007E73;
    color:white;
}
.stop{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #007066;
    color:white;
}

.disclaimer{
    text-align: center;
     padding: 6px;
    border-radius: 5px;
    background-color: #006259;
    color:white;
}

  .ivrOutboundCallButton{
    top: 28%;
    left: 40%;
    background-color: #006400;
    color: #fff;
    cursor: pointer;
    height: 200px;
    width: 200px;
    font-size: 2.5em;
    box-shadow: 0 0 4px 3px hsla(0,0%,53.3%,.72157);
    border-radius: 200%;
     &:hover{
        box-shadow: 0px 0px 20px 7px #8888886b;
        transform: scale(1.1);
        background-color: green;
        color: #fff;
    }
  }

  .orderAddressSameCheckbox{
    position: relative !important;
    left: 5px !important;
    top: 3px !important;
  }
