@media print {
  body, main {
    margin-top: 0px;
    height: 100% !important;
    width: 100% !important;
    -webkit-print-color-adjust:exact;
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
    padding: 5px !important;
    margin: 0 !important;
    min-width: 581px !important;
    min-height: 250px !important;
    max-width: 1163px !important;
    max-height: 500px !important;
  }
  .print-company-monthly-report {
    &_footer {
      padding-top: 50px;
    }
  }
  .hide-for-print {
    display: none !important;
  }

  .page-layout{
    margin: -80px 0 0 0;
    width: 100%;
  }

  .packing-slip-container{
    margin: 5px 20px;
  }
  .packing-slip-header {
    margin: 10px 10px;
  }

  .page {
    border: none !important;
    width: 100% !important;
  }
  table{
    width: 100%;
    border: none;
  }
  .small-6{
    width: 50%;
  }
  a[href]{
    text-decoration: none;
    &:after {
      content: "";
    }
  }
}
