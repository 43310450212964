.bonafide-insurance-form {
  .insurance-field-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    input:read-only:not([disabled]) {
      background: white;
    }
  }
  .insurance-type-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
