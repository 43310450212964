.s3-ui-rows {

  border: 1px solid #eee;

  > div {
    padding: 0.5em;
  }

  > :nth-child(even) {
    background: #f9f9f9;
  }

  > :nth-child(odd) {
    background: #fff;
  }
}
