.patient-notes-container {
  .patient-notes-header {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 1.2em;
    padding-top: 5px;
    .note-filter-select {
      max-width: 150px;
      border: 1px solid #ccc;
      color: rgba(0, 0, 0, 0.75);
      font-family: inherit;
      font-size: 1rem;
      line-height: normal;
      padding: 0 1rem;
      margin: 0;
      border-radius: 0;
      height: initial;
    }
  }
  .note-container {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    background-color: #f3f3f3;
  }
  .note-header {
    display: block;
    color: #000;
    // margin-bottom: 5px;
  }
  .note-type {
    color: #000;
    font-weight: 600;
    margin-bottom: 3px;
  }
  .note-content {
    color: #333;
    word-break: break-word;
    white-space: pre-line;
    // -webkit-user-modify: read-write-plaintext-only;
  }
}

.verification-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;

  .verification-container {
    padding: 30px;
    padding-bottom: 15px;
    background-color: #fafafa;
    border: 10px outset #2dc6b8;
    border-radius: 5px;
    box-shadow: 2px 2px 6px black;

    .verification-instructions {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
      display: inline-block;

      .patient-name {
        color: salmon;
      }
    }

    .skip-button {
      margin-top: 10px;
      margin-left: 15px;
      color: gray;
      float: right;
    }

    .danger {
      color: salmon;
    }

    .skip-button:hover {
      cursor: pointer;
    }

    .verification-form {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 5px;

      .verification-input {
        width: 100%;
        min-width: 400px;
        margin: 0;
      }

      .verification-message {
        color: red;
        min-height: 20px;
      }

      button {
        display: inline-block;
        align-self: end;
        margin: 0;
      }
    }
  }
}
