.not-textable {
  // padding-left: 7px;
  font-weight: "800";
  color: red;
}
.textable-by-consent {
  // padding-left: 7px;
  font-weight: "800";
  color: green;
}
.inline-edit-patient-form {
  input,
  select {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 0px 5px;
    margin: 0px;
  }
}

.outreach-method-statuses-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  background-color: #fafafa;
  margin-bottom: 10px;
}
