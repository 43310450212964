.query-table-forms {
    grid-row: 2;
    height: 400px;
    overflow: auto;
    width: 100%;
    margin-bottom: 0;
    background: #f5f5f5;
    border: 1px solid #eee;
    position: relative;
}

.scrollTableAQuestion{
  max-height: 200px;
  overflow: auto;
}
 
.inputView {
  font-weight: bold
}

.title{
  font-size: medium;
  color:white
}

.text-label-container {
    display: flex;
    justify-content: space-between;
}

.forms-report-generator {
    height: 100%;
    position: relative;
}

.two-fields-container {
    display: flex;
    gap: 20px
}

.two-fields-container > * {
    flex: 1;
}

.report-branches-container {
    display: flex;
    flex-wrap: wrap;
}

.legend-section {
    display: flex;
    align-items: center;
}

.legend-section > div {
    display: flex;
    align-items: center;
}

.legend-section > div:last-child {
    margin-left: 30px;
}

.legend-section > div > div {
    height: 15px;
    width: 15px;
    border: 1px solid #000000;
}

.legend-section > div > p {
    margin: 0 0 0 10px;
}

.legend-selected-color {
    background-color: #ffffcc;
}

.legend-not-selected-color {
    background-color: #ffffff;
}

.questions-responses-section {
    width: 600px;
}

.question-container {
    border-top: 1px solid #ffffff;
    display: flex;
    align-items: center;
}

.question-container > input {
    margin: 0 10px 0 0 !important;
}

.question-container > p {
    margin: 0;
    padding: 0 0 0 10px;
    background-color: #4d93db;
    color: white;
    width: 100%;
    font-weight: 600;
}

.question-container:last-child {
    border-bottom: 1px solid #ffffff;
}

.option-container {
    margin-left: 23px;
}

.option-text {
    border-top: 1px solid #656555;
    border-left: 1px solid #656555;
    border-right: 1px solid #656555;
}

.option-text:last-child {
    border-bottom: 1px solid #656555;
}

.option-text > p {
    margin: 0;
    padding: 0 0 0 10px;
}

.generate-report-button {
    position: absolute;
    bottom: 0;
    right: 0;
}