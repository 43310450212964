
.assignable-patients-table{
    height: unset !important;
}

.assign-button{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
    margin-right: 10px;
    scroll-margin: 50px;
    scroll-behavior: smooth;
}