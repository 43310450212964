.company-patient-page {
  padding: 10px 5px;
  .company-patient-page-header {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 25px;
    padding: 0 5px;
    width: 100%;
  }
  .page-graphs {
    display: flex;
    flex-direction: column;
    .patient-status-breakdown {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
      align-items: center;
      padding: 10px 20px;
    }
    .graph-container {
      padding: 15px 5px;
    }
  }

  .interactive-graph-container {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12),
      inset 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #f3f3f3;
    border-radius: 5px;
    padding: 5px;
    @media print {
      margin: 0px;
      padding: 0px;
      overflow: hidden;
    }
  }
  .interactive-graph-header {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    background: #f5f5f5;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);

    h4 {
      margin: 0;
      color: rgb(49, 130, 189);
    }

    .back-button {
      color: black;
      margin-right: 10px;
    }
  }

  @media print {
    max-width: 8in;
    padding: 0px;
    .page-graphs {
      padding: 10px 0px !important;
    }
    .patient-status-breakdown {
      display: grid !important;
      grid-template-columns: auto !important;
      grid-template-rows: 1fr 1fr !important;
      padding: 10px 0px;
    }
  }
}
