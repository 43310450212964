.multi-select-dropDown-container {
  user-select: none;
  position: relative;
  width: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  color: rgba(0, 0, 0, 0.75);
  .select-dropDown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5em;
    cursor: default;
    position: relative;
    background-color: #fafafa;
  }
  .select-dropDown-header span {
    margin-right: 20px;
  }
  .select-dropDown-header-title {
    border: none;
    background-color: #fafafa;
    border-radius: 0;
    background-position: 100% center;
    background-repeat: no-repeat;
    color: rgba(0, 0, 0, 0.75);
    font-family: inherit;
    font-size: 0.875rem;
    line-height: normal;
    padding: 0.5rem;
    min-height: 2.3125rem;
    width: 100%;
    margin: 0
  }
  svg {
    background: transparent;
    background-color: none;
    font-size: 1.5em;
  }
  .select-dropDown-list {
    z-index: 100; // twillio export form date picker z-index is 100;
    position: absolute;
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #fafafa;
    max-height: 215px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .select-dropDown-list-item {
    width: 100%;
    padding: 5px;
    line-height: 1.5rem;
    cursor: default;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .select-dropDown-list-item.active {
    font-weight: 800;
    color: #4281A6;
    background: #fff;
    text-decoration: underline;
  }
  .select-dropDown-list-item.selected {
    color: #fff;
    background-color: #ffcc01;
  }
  .select-dropDown-list-item:hover {
    background-color: #ddd;
  }
  .select-dropDown-wrapper-single {
    position: relative;
    // width: 265px;
  }
  .select-dropDown-wrapper-single .select-dropDown-header {
    border: 1px solid #ccc;
  }
  .select-dropDown-wrapper-single .select-dropDown-header .select-dropDown-header-name {
    font-weight: 400;
  }
  .select-dropDown-wrapper-single .select-dropDown-list {
    border: 1px solid #ccc;
    border-top: none;
  }
}
