.table-settings-container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  .table-settings-option {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 1.15rem;
    padding-bottom: 3px;
    padding-left: 30px;
    input {
      margin: 0;
    }
    .option-name {
      padding-left: 10px;
      &:hover {
        color: #333;
        font-weight: 515;
      }
    }
  }
  button {
    margin: 0;
    border-radius: 3px;
    width: 100px;
    align-self: flex-end;
    justify-self: flex-end;
  }
}
