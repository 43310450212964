
  .error_text{
    color: red;
    padding: 0 0 10px 0;
  }
  .form_group_has_error{
    border: 2px solid red;
    padding: 10px 0 0;
  }
  .field_div{
    margin: 2px;;
  }
