.email-preview-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  .email-subject {
    display: flex;
    padding-bottom: 10px;
    font-size: 1.15em;
    .email-subject-label {
      font-weight: bold;
      padding-right: 10px;
    }
  }
  .email-preview-body {
    font-size: 1.1em;
    padding: 5px;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .email-preview-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin: 0 5px;
      padding: 5px 10px;
    }
  }
  input {
    display: inline-block;
    margin: 0 2px;
    max-width: 75px;
    text-align: right;
    width: auto !important;
    padding: 0 !important;
    height: auto !important;
  }
}
