.stat-block {
  display: grid;
  grid-template-rows: 30px 1fr;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  background-color: #f5f5f5;
  max-height: 150px;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .stat-block-title {
    font-size: 1.2em;
  }
  .stat-block-value {
    font-size: 2.5em;
    text-align: center;
  }
}
