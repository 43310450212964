.patient-selector {
  display: grid;
  grid-template-columns: 1fr 1fr /*0.1fr*/ 1fr;
  column-gap: 10px;

  .patient-search-button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 20px;
      margin-bottom: 0;
    }
  }

  .select-patient-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 0;
      padding: 10px 20px;
      background-color: #47a147;
    }
  }
}
