.bulk-patient-update {
  margin: 10px;
  .submit-container {
    margin-top: 2rem;
    button {
      text-transform: uppercase;
      font-weight: bold;
      width: 100%;
      margin-bottom: 0;
    }
  }
  .label-text {
    font-weight: bold;
  }
  .field-container-split {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }
  .bulk-update-info {
    align-self: center;
    font-size: 1rem;
    svg {
      color: rgb(66, 129, 166);
      padding-right: 5px;
    }
  }
}
