.historyContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.historyRow {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-left: 150px;
  padding-right: 200px;
  font-weight: bold;
}

.time-display-container {
  height: 80%;
  border: 1px rgb(238, 238, 238) solid;
  display: flex;
  flex-direction: row;
  position: relative;
}

.time-display-section {
  background-color: rgb(255, 254, 254);
}
.time-display-section-odd {
  background-color: rgb(245, 245, 245);
}

.hour-list {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
}

.hour {
  color: rgb(194, 194, 194);
  font-size: smaller;
}

.date-display {
  color: gray;
  font-size: smaller;
  margin-bottom: 8px;
}

.total-row {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.connection-session {
  top: 15%;
  position: absolute;
  z-index: 5;
  height: 35%;
  background-color: rgb(0, 71, 255);
  transition: all .2s ease-in-out;
}

.connection-session:hover {
  background-color: rgb(0, 31, 255);
  transform: scale(1.1);
  z-index: 6;
}

.autodialer-session {
  top: 50%;
  position: absolute;
  z-index: 5;
  height: 35%;
  background-color: rgb(0, 224, 255);
  transition: all .2s ease-in-out;
}

.autodialer-session:hover{
  background-color: rgb(0, 200, 255);
  transform: scale(1.1);
  z-index: 6;
}
.name-column {
  flex-wrap: wrap;
}

/* Tooltip text */
.timetooltip .timetooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgb(253, 253, 253);
  color: rgb(15, 14, 14);
  text-align: center;
  padding: 2px 0;
  border-radius: 2px;
  font-size: smaller;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 27;
  bottom: 27px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
}

// /* Show the tooltip text when you mouse over the tooltip container */
 .timetooltip:hover .timetooltiptext {
   visibility: visible;
 }

.connection-color-indicator{
 width: 9%;
 background-color: rgb(0, 71, 255);
 margin-left: 10px;
}
.autodialer-color-indicator{
  width: 9%;
  background-color: rgb(0, 224, 255);
  margin-left: 10px;
 }
.key-row{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}