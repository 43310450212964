.s3-ui-table {
  width: 100%;
  margin-bottom: 0;
  tbody.loading {
    opacity: 0.4;
    filter: alpha(opacity=40);
  }

  .header-text.active {
    color: #af0200;
  }

  th > .header {
    display: flex;
    justify-content: space-between;
  }

  .filter-icon-container {
    margin: -0.5rem -0.625rem -0.625rem;
    padding: 0.5rem 0.625rem 0.625rem;
  }

  .header-aux {
    display: flex;
    align-items: center;
  }

  th.interactive {
    text-decoration: underline;
    cursor: pointer;
  }
  th {
    position: relative;
  }
  .filter-pane {
    $filter-padding: 10px;
    margin-top: 7px;
    border-radius: 3px;
    box-shadow: 0 0 17px 3px rgba(132, 129, 129, 0.3607843137254902);
    position: absolute;
    background: #fff;
    z-index: 3;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 200px;
    .filter-pane-command {
      text-transform: lowercase;
      font-variant: small-caps;
    }
    .filter-pane-command.controls {
      justify-content: center;
      box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
    }

    > label,
    .filter-entry,
    .filter-pane-command {
      padding: $filter-padding;
      display: flex;
      align-items: center;

      &:hover {
        background: #eee;
      }
    }
    .filter-sub-entry {
      padding: 5px 10px 5px 30px;
    }

    input {
      margin-right: $filter-padding;
    }
  }

  .searchInputBox { 
    width: 94%;
    margin-bottom: 13px;
    margin-top: 12px;
    padding-left: 9px;
    margin-left: 5px;
    margin-right: 5px;
    height: 27px;
    border: none;
    box-shadow: inset 0px 0px 7px 0px rgba(136, 136, 136, 0.49019607843137253);
    border-radius: 5px;
  }

  .filterListSearchIcon {
    font-size: 17px;
    position: relative;
    top: 2px;
    left: -47px;
    color: #2dc6b8;
    cursor: pointer;
  }

  .hrForTableSearch {
    border: solid #dddddd87;
    border-width: 1px 0 0;
    clear: both;
    height: 0;
    margin: 0.25rem 0 0.1875rem;
  }

  .filter-pane-out-of-bounds {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: auto;
    background: none;
  }

  .table-waiting-container {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 29px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    td {
      display: inline-block;
    }
  }
  .filter-downselect {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    padding: 5px;
    margin: 5px;
    input {
      width: 100%;
      grid-area: "search-input";
    }
  }
  tr.even {
    background: #fff;
  }
  tr.odd {
    background: #f9f9f9;
  }
}

.table-container.scrollable {
  /* > tablet */
  @media (min-height: 750px) {
    height: 70vh;
    overflow-y: auto;

    thead tr:nth-child(1) th {
      position: sticky;
      z-index: 1;
      background: #f5f5f5;
      top: -1px;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
    }
  }
}

.comany-team-select {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  padding: 5px 10px;
  margin: 5px 0px;
  align-items: center;
  label {
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: bold;
    font-size: 1.05em;
  }
  select {
    height: 25px;
  }
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
}
