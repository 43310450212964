.work-list-container {
  position: sticky;
  width: auto;
  max-width: 100%;
  top: 0;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: #f2f2f2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);

  .work-list-header {
    display: flex;
    align-items: center;
    font-size: 1.15em;
    &:not(.minimized) {
      border-bottom: 1px solid #ddd;
    }
    border-top: 1px solid #ddd;
    .header-text {
      display: flex;
      align-items: center;
      user-select: none;
      flex: 1 1 auto;
    }
    .order-worklist-tab-headers {
      display: grid;
      grid-template-columns: auto auto;
      cursor: pointer;
      .header-tab {
        color: #396f8f;
        font-size: 1.4375rem;
        font-family: PT Sans, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;
        border-right: 1px solid #ddd;
        padding: 5px 10px;
        &.active {
          font-weight: 700;
          background-color: #ddd;
        }

        &:hover {
          color: #0090bc;
        }
      }
    }
    .minimize-order-worklist {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
    }
    .worklist-header-radio-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      align-content: center;
      label {
        padding: 0 15px;
        user-select: none;
        input {
          margin: 0 5px;
        }
      }
    }
  }
  .work-list-reasons {
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    min-height: 45px;
    flex: 1 1 auto;
    .s3-ui-rows {
      overflow-y: auto;
    }

    .hold-reason {
      color: #333;
      display: grid;
      grid-template-columns: 250px 1fr auto;
      .hold-reason-label {
        user-select: none;
        &:not(.disabled) {
          cursor: pointer;
        }
      }
      &.resolved {
        .hold-reason-label {
          color: #888;
          text-decoration: line-through;
        }
      }
      .reason-comments {
        display: flex;
        flex: 1;
        padding: 0 10px;
        margin: 0 10px;
      }
      .edit-hold-reason {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .bold {
      font-weight: bold;
    }
    .worklist-item {
      display: grid;
      grid-template-columns: 1fr 400px;
      align-items: center;
      grid-gap: 10px;
      padding: 1px 5px;
      font-size: 1.15em;
      .worklist-item-meta {
        flex: 1 1 auto;
        font-size: 0.8em;
        margin-left: 10px;
        margin-right: 10px;
        .worklist-item-info {
          font-weight: bold;
        }
        .warning {
          color: salmon;
        }
      }
      .order-note {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: 10px;
        padding: 0 7px;
        margin: 0 5px;
        color: #444;
        .note-content {
          padding: 0 7px;
          margin-right: 10px;
          color: black;
          word-break: break-word;
          white-space: pre-line;
        }
      }
    }
  }

  .add-reason-select {
    display: grid;
    grid-template-columns: 200px 1fr auto;
    align-items: center;
    grid-gap: 10px;
    padding: 10px 10px 0px 10px;
    input {
      margin: 0;
    }
  }
  .add-reason-select {
    button,
    select {
      margin: 0;
      padding: 4px;
      height: 2.125rem;
    }
  }
  .number-step {
    position: relative;
    bottom: 8px;
    left: 5px;

    color: #999;
    padding: 1px;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1.6em;
    text-align: center;
  }
}

.edit-reason-container {
  position: sticky;
  width: 100%;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.15);
  padding: 5px;
  .edit-reason-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  .edit-reason-title {
    font-size: 1.25em;
    color: #085b55;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 20px;
  }
  .last-updated-by {
    font-weight: 700;
  }
  .edit-reason-content {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 3fr 1fr;
    textarea {
      padding: 5px;
      margin: 0;
      height: 100%;
    }
    > .edit-reason-controls {
      display: grid;
      justify-content: flex-end;
      grid-template-columns: 1fr auto auto;
      grid-gap: 5px 15px;
      align-items: center;
      padding: 5px 0px;
      label {
        display: flex;
        align-items: center;
        font-size: 1.15em;
        input {
          margin: 0 10px;
        }
      }
      button {
        margin: 0px;
        padding: 0px;
        max-height: 50px;
        padding: 5px;
        min-width: 50px;
      }
    }
  }
}
