.switch-wrap {
  cursor: pointer;
  background: #15273b;
  height: 0;
  padding-top: 15%;
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.switch {
  position: absolute;
  display: grid;
  top: 1px;
  left: 1px;
  bottom: 0;
  right: 1px;
  height: 90%;
  width: 40%;
  //ICYMI, pseudo elements are treated as grid items
  &::after {
    content: '';
    border-radius: 50%;
    background: #ccc;
    transition: .2s ease-in-out;
  }
}

input:checked {
  + .switch {
    &::after {
      transform: translateX(135%);
      background-color: #78c3e7;
    }
  }
}
