.emailGroupsContainer {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  column-gap: 20px;
}

.emailGroups {
  max-height: 700px;
  overflow-y: scroll;
}

.emailGroup-Companies
{
  padding-bottom: 20px;
  max-height: 500px;
  overflow-y: scroll;

}

.center {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
}

.emailGroupInfo {
  //margin-left: 10px;
  min-height: 400px;
}
.emailGroupAlignAndJustify {
  display: grid;
  grid-template-columns: 1fr;
}
.emailGroupRow {
  margin-bottom: 6px;
  background-color: white;
  color: black;
  //height: 45px;
  cursor: pointer;
  &:hover {
    background-color: #dcdbdb;
    color: white;
  }
}
.emailGroupRowSelected {
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #02b4a6;
  color: white;
}
.emailGroupDropShadow {
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.26);
}

.emailGroupRowName {
  padding-left: 10px;
  font-size: 1.25em;
  font-weight: 100;
  justify-self: left;
  align-self: center;
}
.emailGroupRowDelete {
  padding-right: 10px;
  justify-self: right;
  align-self: center;
  color: red;
  &:hover {
    color: darkred;
  }
}

.addItemCircleBackground {
  display: grid;
  grid-template-columns: 1fr;
  height: 40px;
  width: 40px;
  border-radius: 100pc;
  &:hover {
    background-color: #dcdbdb;
    color: white !important;
    cursor: pointer;
  }
}

.addItemCircleSymbol {
  justify-self: center;
  align-self: center;
  font-size: 40px;
  line-height: 0;
  color: grey;
}

.inplaceInputEdit {
  text-align: center;
  border: none;
  box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
}

.emailGroupAddressRow {
  font-size: 1.3em;
  //height: 30px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  cursor: pointer;
  .emailGroupIcon {
    svg {
      height: auto;
      width: 10px;
    }
  }

  &:hover {
    background-color: #dcdbdb;
  }
}

.emailGroupAddressRowSelected {
  font-size: 1.3em;
  //height: 30px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  cursor: pointer;
  background-color: #02b4a6;
  color: white;
  .emailGroupIcon {
    svg {
      fill: white;
      height: auto;
      width: 10px;
    }
  }
}
.displayGridOne {
  display: grid;
  grid-template-columns: 1fr;
}
.justifyAndAlignSelfCenter {
  justify-self: center;
  align-self: center;
}

.editAddressButton {
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  background-color: #02b4a6;
  &:hover {
    background-color: #027d74;
  }
}
.deleteAddressButton {
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
  color: white;
  background-color: #ff0000;
  &:hover {
    background-color: darkred;
  }
}
.accessibleCompanyDeleteButton {
  position: relative;
  right: -46%;
  top: 10px;
  cursor: pointer;
  svg {
    color: red;
    &:hover {
      color: darkred;
    }
  }
}

.addressMemberIcon {
  svg {
    fill: #02b4a6;
    height: auto;
    width: 40px;
  }
}

.emailGroupAddressMemberEmail {
  font-size: 1em;
  margin-top: 5px;
  color: grey;
}

.emailGroupAddressMemberRole {
  font-size: 1em;
  margin-top: 5px;
  color: grey;
}

.multi-select-dropDown-container {
  border-radius: 10px;
}
.emailGroupAddress {
  min-height: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.emailGroupAddressAccessibleCompanies {
  margin-top: 30px;
}

.addItemButton {
  cursor: pointer;
  color: grey;
  &:hover {
    color: #02b4a6;
  }
}

.createEmailGroupInput {
  padding-left: 5px;
  box-shadow: inset 0 0 2px 0px #0000006e;
  border: none;
  height: 45px;
  font-size: 1.5em;
  width: 100%;
}

.submitButton {
  width: 100%;
  cursor: pointer;
  height: 30px;
  color: white;
  background-color: #02b4a6;
  &:hover {
    background-color: #037f75;
  }
}

.deleteEmailGroupButton {
  position: relative;
  right: -2%;
  top: -3px;
  cursor: pointer;
  color: red;
  &:hover {
    color: darkred;
  }
}

.editButton {
  svg {
    font-size: 20px;
    fill: grey;
    &:hover {
      fill: #037f75;
    }
  }
}
.deleteEmailGroupAddressButton {
  position: relative;
  right: -2%;
  top: -3px;
  cursor: pointer;
  color: red;
  &:hover {
    color: darkred;
  }
}

.addressFormPersonButton {
  svg {
    font-size: 70px;
    fill: grey;
    &:hover {
      fill: #02b4a6;
    }
  }
}

.addressFormGroupButton {
  svg {
    font-size: 82px;
    fill: grey;
    &:hover {
      fill: #02b4a6;
    }
  }
}

.addressFormButtonSelected {
  svg {
    fill: #02b4a6;
  }
}

.selectDropDownOveride {
  box-shadow: inset 0 0 2px 0px #0000006e;
  border: none;
  height: 45px;
  font-size: 2em;
  width: 100%;
}

.emailGroupWarning {
  font-size: 2em;
  color: red;
}

.createLoginButton {
  cursor: pointer;
  border-radius: 10px;
  font-size: 15px;
  height: 40px;
  color: #fff;
  background-color: grey;
  padding: 10px;
  margin: 8px;
  background-color: grey;
  &:hover {
    background-color: #02b4a6;
  }
}

.createLoginButtonSelected {
  background-color: #02b4a6;
}
