.messageList-container {
    margin: 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.AppContainer-text {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 100%;
  width: 50%;
  margin: .5em;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  background: #ddd;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.AppContainer-text .MessageList {
  box-sizing: border-box;
  flex: 1;
}

.AppContainer-text .MessageForm {
  background: #eee;
  border-top: 1px solid #ccc;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  flex: 0 0 30px;
}
