.form-profile-container {
    padding: 0px !important;
    overflow: auto;
    display: grid;
    grid-template-rows: auto 1fr;
  }

.form-profile {
  input,
  button,
  select {
    margin-bottom: 0;
  }
  padding-top: 20px;
  overflow: auto;

  button {
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: bold;
  }

  .form-profile-page,
  .form-profile-print-page {
    padding: 2rem 4rem;
    font-size: 0.9rem;
    background: #fff;

    > * + * {
      margin-top: 2rem;
    }
  }

  .form-profile-page {
    width: 75rem;
    border: 1px solid #ddd;
    margin: 0 auto;
  }

  .form-profile-page + .form-profile-page {
    margin-top: 2rem;
  }

  .form-profile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    > :first-child {
      flex: 1;
    }
  }

  .form-profile-subheader {
    margin-top: 2rem;
  }

  .profile-header {

    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .form-action-buttons {
      display: grid;
      grid-template-columns: repeat(5, minmax(60px, auto));
      justify-items: center;
      grid-gap: 15px;
      align-items: center;
    }

    .form-notes-button,
    .print-form-button {
      font-size: 2.75rem;
      cursor: pointer;
      color: grey;
      &.has-notes {
        color: salmon;
      }
    }

    .print-form-button {
      .print-menu {
        font-size: 0.85rem;
        .print-option {
          padding: 5px 0px;
          &:hover {
            color: black;
          }
        }
      }
    }

    .form-info-container {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      padding-top: 5px;
      input,
      select {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 1px solid black;
        font-size: 1rem;
        width: 250px;
      }
    }

    .inline-header {
      font-weight: bold;
      &::after {
        content: ": ";
      }
    }

    &:not(.print) {
      .equipment-label {
        margin-left: 20px;
        vertical-align: middle;
        padding: 0.25rem 0.5rem 0.25rem;
        font-size: 0.75rem;
        background-color: #4281a6;
        color: #ffffff;
        text-transform: uppercase;
        &.hasLineItem {
          text-decoration: underline;
        }
      }
    }

    &.print {
      /* TODO deprecated along with component: Nov 4 19*/
      // we don't want this in the page itself either, which is why it's
      // out of `@media print`
      .copy-to-clipboard-button,
      .print-form-button,
      .form-notes-button {
        display: none;
      }
  
      .equipment-label {
        margin-left: 0px;
        vertical-align: middle;
        padding: 0px;
        font-size: 0.75rem;
        text-transform: lowercase;
      }
      .equipment-label:not(:first-child):before {
        content: ", ";
      }
    }
  }

  
  .copy-to-clipboard-button {
    cursor: pointer;
    pre {
      color: black;
      font-size: 10px;
      line-height: 1;
    }
  }
  
  .copied-animation {
    animation: bounce 1s linear;
  }
  
  .copied-animation:before {
    content: "Copied";
    position: absolute;
    top: -20px;
    left: -3px;
    opacity: 0.8;
    animation: fade 2s ease-in-out;
  }
  
  .information-tooltip {
    margin-left: 5px;
    * > {
      content: normal;
    }
  }
}
@media print {
  .form-profile {
    margin: 0 !important;
    padding: 0 !important;
    overflow: unset;
    background: #fff;
  }
  .form-profile-container {
    margin: 0 !important;
    padding: 0 !important;
    overflow: unset;
  }

  .copy-to-clipboard-button,
  .print-form-button{
    display: none !important;
  }

  .form-profile .form-profile-page {
    width: 100%;
    border: none;
  }

  .info-div {
    width: 100%;
    height: auto;
    overflow: visible;
  }
}
