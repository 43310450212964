.query-table.manage-pdf-table {
  height: 60vh;
}

.override-dates-button {
    margin: 0;
    padding: 5px 10px;
}

.override-dates-popup {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expire-reminder-days {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.expire-reminder-days > div {
    display: flex;
    align-items: center;
}

.expire-reminder-days > div > input {
    width: 50px;
    margin-left: 10px;
}

.expire-reminder-errors {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expire-reminder-errors > p {
    margin: 0;
    color: #ff0000;
}

.save-cancel-butons-container {
    display: flex;
    gap: 10px;
}

.save-cancel-butons-container > button {
    margin: 0;
    padding: 5px 10px;
}