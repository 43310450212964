.tasks-page {
  .query-table.tasks-order-table.dynamic-columns {
    height: 60vh;
  }
}
.tasks-page {
  .query-table.tasks-table.dynamic-columns {
    height: 65vh;
  }
  .table-task-filter-container {
    position: sticky;
    display: grid;
    // grid-template-columns: auto 6fr 1fr;
    grid-gap: 5px;
    align-items: center;
    z-index: 3;
    background: linear-gradient(#249c91, #2dc6b8);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 3px;
    select {
      margin: 0px;
    }
    .table-task-filter-label {
      padding: 0px 5px;
      color: white;
      font-size: 1.075em;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
  }
  .task-actions-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
  }
  .task-action {
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.45rem 0.5rem;
    font-size: 1rem;
    margin-right: 10px;
    margin-bottom: 5px;
    background-color: #ededed;
    border-radius: 3px;
    color: #249c91;
    border: 1px solid #ccc;
    &.resolved {
      color: #249c9177;
      background-color: #f0f0f0;
      border: 1px solid #ddd;
      text-decoration: line-through;
    }
  }
  .task-actions-row {
    > td {
      column-span: all;
      text-align: right;
      line-height: 0.5rem;
    }
  }
  .task-filter-select-container {
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    flex-direction: column;
    > .multi-select-dropDown-container { 
      flex: 1;
      width: 100%;
      display: flex;
      > .select-dropDown-header { 
        width: 100%;
        flex: 1;
        display: flex;
      }
    }
  }
}
.searchInputBox {
  width: 94%;
  margin-bottom: 13px;
  margin-top: 12px;
  height: 27px;
  border: none;
  box-shadow: inset 0px 0px 7px 0px rgba(136, 136, 136, 0.49019607843137253);
  border-radius: 5px;
}
.filter-entry {
  padding: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
}
