.query-table.mask-fitting-table {
  height: calc(100vh - 200px);
}

.mask-fitting-details {
  padding: 10px;
  .mask-fitting-cards {
    margin: 10px;
  }
}

.autofit-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 10px;
}
.autofit-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.autofit-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  font-size: 14px;
  color: rgb(33, 37, 41);
}

.mask-transaction-submit-results {
  padding: 10px;
  display: flex;
  flex: 1 1 auto;
  height: 300px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 1.15em;
  div {
    padding: 10px;
  }
  .transaction-success {
    font-size: 1.25em;
    color: green;
  }
  .transaction-error {
    color: red;
  }
}
.mask-fitting-question {
  padding: 0;
  padding-bottom: 5px;
  font-size: 1rem;
}
