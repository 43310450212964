.tab-list {
  font-family: "Raleway", Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  li {
    display: inline-block;
    list-style: outside none none;
    margin: 0.25em 1em;
    padding: 0;
  }
  a {
    padding: 0.4em 0;
    color: rgb(0, 144, 188);
    position: relative;
    text-decoration: none;
    display: inline-block;
  }
  a:before {
    position: absolute;
    content: "";
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    opacity: 0;
    top: 25%;
    bottom: 25%;
    left: 0;
    right: 0;
    border-top: 2px solid rgb(37, 168, 224);
    border-bottom: 2px solid rgb(37, 168, 224);
  }
  a:hover {
    color: rgb(0, 144, 188);
  }
  a:hover:before {
    opacity: 1;
    top: 0;
    bottom: 0;
  }
  .is-active {
    &:before {
      opacity: 1;
      top: 0;
      bottom: 0;
    }
    // font-weight: 800;
    color: rgb(37, 168, 224);
  }
}
