.sleep-coach-dashboard-patients-table {
  .select-all {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    width: 100%;
  }

  .sleep-coach-dashboard-table-waiting {
    display: inline-block;
    margin-left: 20px;
  }

  // hacky
  .filterable label > span {
    width: 100%;
    display: inline-block;
    color: #222;
    text-decoration: underline;
  }
  .autodialer-btn {
    &.disabled {
      cursor: default !important;
      opacity: 0.6;
    }
  }
  .preset-select {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}


  .action-buttons-sleepcoach-dashboard {
    display: flex;
    align-items: left;
    padding-right: 20px;
    height: 100%;
    .icon-button-sleepcoach-dashboard {
      align-self: flex-end;
      font-size: 20px;
      padding: 0 5px;
      font-size: 20px;
      color: hsla(0, 0%, 100%, 0.493);
      cursor: pointer;
    }
  }
input.searchInputBox-coachfilter { 
    width: 94%;
    margin-bottom: 13px;
    margin-top: 12px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
