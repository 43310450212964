.dashboard-card-container {
  border: 2px solid lightgray;
  font-size: 1rem;
  border-radius: 7px;
  padding: 1.2rem;
  color: #666;
}

.dashboard-card-title {
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  color: rgb(7, 91, 85);
}

.active-patients-card {
  grid-area: active-pts;
}

.ins-elig-patients-card {
  grid-area: ins-elig-pts;
}

.ready-outreach-patients-card {
  grid-area: ready-outreach-pts;
}

.order-table-card-container {
  grid-area: orders-table;
}

.orders-claimed-card-container {
  grid-area: orders-claimed;
}

.helpful-links-card-container {
  grid-area: helpful-links;
}

.announcements-card-container {
  grid-area: announcements;
}

.announcement-container {
  min-height: 20rem;
  max-height: 35rem;
  overflow-y: auto;
}

.announcement-body {
  // wrap text in pre for muiltiple browsers
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
