.list-selector {
  position: relative;

  .list-selector-entry-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #bbb;
    overflow: auto;

    .list-selector-entry {
      position: relative;
      padding: 10px;
      font-size: 1.2em;
      background: rgb(255, 255, 255);
      font-family: PT Sans, sans-serif;
      cursor: pointer;
    }

    .list-selector-entry-active {
      @extend .list-selector-entry;
      background-color: #cde7f7 !important;
    }

    .list-selector-entry:hover {
      background-color: #e5f3fc;
    }

    .list-selector-entry-divider {
      cursor: pointer;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 1px;
      background-color: #bbb;
    }

    .deselect-button {
      position: absolute;
      top: 5px;
      right: 5px;
      margin: 0;
      padding: 5px 10px;
      background-color: salmon;
      border-radius: 5px;
    }
  }

  .list-selector-entry-wrapper::-webkit-scrollbar {
    width: 1em;
  }

  .list-selector-entry-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .list-selector-entry-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .list-selector-default {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    color: red;
    font-weight: 700;
    font-size: 14px;
    padding: 5px;
    box-shadow: inset 0px 0px 4px 0px #8888888c;

    .loading-spinner {
      position: absolute;
      margin: 0;
      padding: 0;
      top: 38%;
      left: 45%;
    }
  }
}
