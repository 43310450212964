.fulfillment-status-table {

  .update-status-container {
    display: flex;
    background: #2dc6b8;

    > * {
      margin: 10px;
    }

    select {
      flex: 2;
    }

    button {
      flex: 1;
      margin-bottom: 0;
    }
  }

}
