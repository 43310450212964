
.vmInfo{
    position: relative;
    top: -2px;
    left: 57px;
}

.vmAdddress{
    color: #a8a8a8;
    font-weight: 600;
    position: relative;
    top: -98px;
    left: 106px;
    font-size: 9px;
}

.vmToNumber{
    position: relative;
    top: -85px;
    left: 183px;
    color: #a8a8a8;
    font-weight: 600;
    font-size: 9px;
}

.audioPlayer{
    position: relative;
    top: -48px;
    left: 12px;
}
.workedInfo{
    position: relative;
    top: -131px;
    left: 614px;
    height: 40px;
}

.workedVMButton{
    position: relative;
    top: 0;
    left: 0;
    font-size: 8px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    padding: 0;
    box-shadow: 1px 1px 2px 1px rgba(64, 64, 64, 0.54);
    background-color: green;
}

.workedVMButton:hover{
    background-color: rgb(0, 105, 0);
}

.listenedBy{
    position: relative;
    top: -53px;
    left: 41px;
    color: green;
}

.workedBy{
    position: relative;
    top: -73px;
    left: 42px;
    color: green;
}

.vmailProfile{    
    background-color: #fafafa;
    height: 80px;
    position: fixed;
    z-index: 5;
    top: 22px;
    width: calc(100% - 220px);
    left: 226px; 
    min-width: 1115px;  
}
.prevIcon{
    position: relative;
    top: 0px;
    left: -12px;
    padding: 0px;
    background-color:#ffffff00;
    z-index: 2;
    color: #4281a6;
}
.nextIcon{
    position: relative;
    top: 0px;
    left: -12px;
    padding: 0px;
    background-color:#ffffff00;
    color: #4281a6;
    z-index: 2;
}

.closeIcon{
    position: relative;
    top: 0px;
    left: -12px;
    padding: 0px;
    background-color:#ffffff00;
    color: #4281a6;
    z-index: 2;
}


.vmailPatientTable{
    margin-right: 10px;
    //margin-left: 10px;
    //margin-top: 82px;
    position: relative;
    top: 56px;
    left: 0px;
}

.vmailPatientProfile{
    margin-right: 10px;
    margin-top: 5px;
    position: relative;
    top: 50px;
    left: 0px;
}

.vmailPatientTableSearch{
    //font-size: 0.9em;
    //margin-top: -20px;
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    //justify-items: center;
    align-items: center;
    margin-bottom: 5px;
    position: relative; 
    z-index: 1;
    input{
height: 20px;
        font-size: 0.8em;
    }
    div{
        div{
            margin: 0px;
            input{
                margin: 0px
            }
            
        }
        .DayPickerInput{
            width: 100%;
            //margin: 0px;
            input{
                margin: 0px;
            }
            
        }
    }
    button{
        height: 20px;
        margin: 0px;
        padding: .25rem 2rem .25rem 2rem;
    }
}
.vmailPatientSearchButton{
    //height: 20px;
}

.vmailPatientProfileDefaultHeader{
    font-size: 35px;
    position: relative;
    top: 256px;
    left: 0px;
    text-align: center;
}

.vmailPatientTableHeader{
    text-align: center;
    position: relative;
    top: -0px;
    left: 6px;
}

.vmailWorkPageWaiting{
    text-align: center;
    //position: relative;
    //top: 0px;
    //left: 610px;
}


.thereIsAPhoneMatchClass{
    //font-size: .90em;
    //width: 50px;
    font-weight: 600;
    //padding: 0;
    //text-align: center;
    //background-color:#ff6500;
    animation-name: phoneNumberMatchAnim;
    animation-iteration-count: infinite; 
    animation-duration: .8s;
    -webkit-animation-name: phoneNumberMatchAnim;
    -webkit-animation-iteration-count: infinite;  
    -webkit-animation-duration: .8s;
  }
  @keyframes phoneNumberMatchAnim {
    0%{ color:  red}
    50% {color:  black}
    100%{ color:  red}
  }
  @-webkit-keyframes phoneNumberMatchAnim {
     0%{ color:  red}
     50% {color:  black}
     100% {color:  red}
  }
