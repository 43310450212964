.patients-table-page {
  .query-table.patients-table.dynamic-columns {
    height: 70vh;
  }
}

.assign-button {
    margin: 0 10px 0 10px;
    padding: 0;
    background-color: unset !important;
    font-size: 15px;
}
