.contact-actions {
  //background: linear-gradient(#2dc6b8, #249c91);
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  padding: 3px 7px 3px 7px;
  margin: 0px 15px;
  &[disabled] {
    //background: linear-gradient(#2dc6b8, #249c91);
    color: rgba(255, 255, 255, 0.6);
    pointer-events: none;
  }
  svg {
    font-size: 1.6em;
    margin: 0px 5px;
  }
}

button.contact-actions{
  background-color: transparent;
}

.outreach-contact-type{
  color:rgba(255, 255, 255, 0.6); 
  cursor: pointer;
}
.outreach-contact-type-selected{
  color: rgba(255, 255, 255, 0.9); 
  cursor: pointer;
}
.bulk-outreach-modal-header{
  font-size: large;
    text-align: center;
    margin-top: 20px;
}
.bulk-outreach-modal-category-selected{

  // font-size: large;
  // margin-left: 30px;
  // margin-top: 10px;
  color: #249c91;
  font-weight: 700;
  font-size: 2em;
  background-color: transparent;
  padding: 0;
  //width: 100%;
  margin: 20px 0 0 30px ;
  transition: .3s;
  border: 0;
  filter: drop-shadow(2px 3px 2px #ccc);
}
.bulk-outreach-modal-category{

  // font-size: large;
  // margin-left: 30px;
  // margin-top: 10px;
  color: #aaa;;
  font-weight: 700;
  font-size: 2em;
  background-color: transparent;
  padding: 0;
  //width: 100%;
  margin: 20px 0 0 30px ;
  transition: .3s;
  border: 0;
  filter: drop-shadow(2px 3px 2px #ccc);
}
.bulk-outreach-modal-send{
  color: #249c91;
  font-weight: 700;
  font-size: 1.5em;
  background-color: transparent;
  padding: 0;
  //width: 100%;
  transition: .3s;
  border: 0;
  filter: drop-shadow(2px 3px 2px #ccc);
}
.bulk-outreach-modal-send-container{
  display: flex;
  justify-content: flex-end;
  margin: 10px  30px;
}


.bulk-outreach-modal-listItem{
  font-size: 1.25em;
    margin-left: 50px;
    margin-top: 10px;
}
.bulk-outreach-list-container{
  display: flex;
  flex-direction: row;
}