.contact-logic-search-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
  column-gap: 10px;

  div {
    z-index: 999 !important;
  }

  .close-button{
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
    }
  }
}

.contact-logic-container {
  width: 100%;
  display: grid;
  column-gap: 25px;
  grid-template-columns: repeat( auto-fill, 300px);
  grid-template-rows: 1fr 0;

  .open-new-table-button-container {
    width: 100px;
    height: 90vh;
    position: sticky;
    top: 95px;
    display: flex;
    align-items: center;
    justify-content: center;

    .open-new-table-button {
      display: inline-block;
      background-color: rgb(120, 195, 231);
      color: white;
      text-align: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      box-shadow: 2px 3px 2px #ccc;
      cursor: pointer;

      .plus-symbol {
        width: 70%;
        height: 8%;
        background-color: white;
      }

      .plus-symbol:nth-child(1) {
        transform: translate(20%, 575%);
      }
      .plus-symbol:nth-child(2) {
        transform: translate(20%, 480%) rotate(0.25turn);
      }
    }

    .open-new-table-button:hover {
      background-color: rgb(146, 208, 236);
    }
  }
}

.contact-logic-table-container {
  position: relative;
  width: 100%;
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  column-gap: 10px;

  .form-of-contact-button-container {
    z-index: 10;
    top: 0;
    position: sticky;

  }

  .form-of-contact-button-container-for-modal{
    z-index: 4;
  }

  .form-of-contact-button {
    color: #aaa;
    font-weight: bold;
    font-size: 2em;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 20px;
    transition: 0.3s;
    border: 0;
    filter: drop-shadow( 2px 3px 2px #ccc);
  }

  .contact-action-button-inline {
    color: #aaa;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    margin: 0;
    transition: 0.3s;
    border: 0;
    width: 20%;
    height: 100%;
    margin: 3px;
    font-size: 1em;
  }

  .contact-action-button-inline:focus {
    outline: none !important;
  }

  .exclamation {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 0;
    top: 0px;
    background-color: salmon;
    color: white;
    border-radius: 50%;
    z-index: 25;
    font-size: .8em;
    text-align: center;
    margin: 0;
  }

  .form-of-contact-button.active,
  .form-of-contact-button:hover,
  .contact-action-button-inline.active {
    color: rgb(120, 195, 231);
  }

  .hoverable-color:hover {
    color: rgb(120, 195, 231);
  }

  .contact-logic-table-header-container {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 4fr;
    z-index: 50;
    top: 0;
    position: sticky !important;

    .table-header {
      text-align: center;
      width: 100%;
      height: 100%;
      font-weight: bold;
      font-size: 13px;
      padding-bottom: 5px;
      background-color: #fafafa;
    }
  }

  .contact-logic-table-header-container-for-modal{
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 4fr;
    top: 0;
    position: sticky !important;

    .table-header {
      text-align: center;
      width: 100%;
      height: 100%;
      font-weight: bold;
      font-size: 13px;
      padding-bottom: 5px;
      background-color: #fafafa;
    }
  }

  .contact-logic-table {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 4fr;
    background-color: #ebebeb;
    box-shadow: 2px 5px 8px gray;
    margin-bottom: 20px;

    .table-info-container {
      grid-column: 1 / span 3;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 4fr;
      background-color: #ebebeb;
      z-index: 50;
      bottom: 0;
      position: sticky !important;

      .table-info {
        border-top: 4px solid #fafafa;
        width: 100%;
        display: flex;
        padding: 10px 4px;
        font-weight: bold;
        align-items: center;
        justify-content: center;
      }

      .table-info-toggle {
        @extend .table-info;
        padding: 5px;

        svg {
          margin-right: 10px;
          fill: rgb(120, 195, 231);
          border: 2px solid rgb(120, 195, 231);
          border-radius: 50%;
          height: 100%;
          width: auto;
          padding: 2px;
        }
      }

      .table-info:nth-child(2) {
        border-right: 2px solid #fafafa;
      }
      .table-info:nth-child(3) {
        border-left: 2px solid #fafafa;
      }
    }

    .table-info-container-for-modal{
      grid-column: 1 / span 3;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 4fr;
      background-color: #ebebeb;
      z-index: 4;
      bottom: 0;
      position: sticky !important;

      .table-info {
        border-top: 4px solid #fafafa;
        width: 100%;
        display: flex;
        padding: 10px 4px;
        font-weight: bold;
        align-items: center;
        justify-content: center;
      }

      .table-info-toggle {
        @extend .table-info;
        padding: 5px;

        svg {
          margin-right: 10px;
          fill: rgb(120, 195, 231);
          border: 2px solid rgb(120, 195, 231);
          border-radius: 50%;
          height: 100%;
          width: auto;
          padding: 2px;
        }
      }

      .table-info:nth-child(2) {
        border-right: 2px solid #fafafa;
      }
      .table-info:nth-child(3) {
        border-left: 2px solid #fafafa;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;
      height: 100%;
      width: 100%;

      .table-uneditable {
        text-align: center;
      }

      .table-cell-content {
        position: relative;

        > input {
          margin-bottom: 0px;
          padding: none;
          padding-top: 2px;
        }
      }
    }

    .table-cell:nth-child(3n + 1) {
      border-right: 2px solid #fafafa;
    }
    .table-cell:nth-child(3n + 2) {
      border-left: 2px solid #fafafa;
      border-right: 2px solid #fafafa;
    }
    .table-cell:nth-child(3n + 3) {
      border-left: 2px solid #fafafa;
    }
  }
}

.spinner-container {
  position: relative;
  width: 100%;
  height: 100px;

  .loading-spinner {
    position: absolute;
    left: 45%;
    top: 50%;
  }
}

.contact-logic-table-manager {
  position: relative;
}

.save-cycle-data-button {
  background-color: #da6052;
  margin-top: 10px;
  float: right;
}

.save-cycle-data-button:disabled {
  background-color: #ea8092;
}

.save-cycle-data-button:disabled:hover {
  background-color: #ea8092;
}

.save-cycle-data-button:hover {
  background-color: salmon;
}
