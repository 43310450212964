.patient-form {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  .form-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin-top: 30px;
      width: 33%;
    }
  }
  .patient-form-input {
    .patient-form-description-container {
      display: flex;
      justify-content: space-between;
    }

    .patient-form-validation-error {
      font-weight: 600;
    }

    &.patient-form-has-error {
      background: #ffcccc;
      color: #ff3333;
      padding: 5px;
    }

    input,
    select {
      margin-bottom: 0;
    }

    .address-fields {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0px 20px;
    }
    fieldset {
      > .patient-form-input + .patient-form-input {
        margin-top: 1rem;
      }
    }

    .patient-form-submission-container {
      display: flex;

      input.button {
        margin-bottom: 0;
      }

      .ui-inline-waiting {
        padding-left: 10px;
      }
    }
  }

  .patient-form-checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 20px;
    > * + * {
      margin-left: 20px;
    }
  }

  .patient-form-checkbox {
    display: flex;
    flex-direction: column;
    > div {
      padding-right: 5px;
    }
  }
}
.label-container {
  display: flex;
  justify-content: space-between;
}
.form-field-label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}
