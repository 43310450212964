.packing-slips-page {
  .action-container {
    display: flex;
    flex: 1 1 auto;
    max-height: 100px;
    justify-content: space-around;
    align-items: center;
    position: fixed;

    .print-button {
      font-size: 32px;
      margin: 5px 35px;
      color: grey;
      text-align: center;
      cursor: pointer;
    }
  }

  .print-packing-slips-container {
    @media not print {
      margin: 2rem 12rem;
    }

    @media print {
      input[type=checkbox] {
        display: none;
      }
    }
    .packing-slip-container {
      margin: 20px;
      background: #fff;
      padding: 2rem 4rem;
      @media not print {
        border: 1px solid #ddd;
      }
      @media print {
        page-break-after: always;
      }
    }
    .packing-slip-header {
      display: flex;
      justify-content: space-between;
    }

    .shipping-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .packing-slip-body {
      display: flex;
      flex-direction: column;

      .equipment-requested {
        margin: 50px 0px;
        table {
          width: 100%;
        }
      }
    }
    // note css for component is set in order-profile/styles.scss but not carried over
    .bulk-update-tracking-button-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .address-box {
    border: solid 1px #e7e7e7;
    height: 100px;
    padding: 0;
    div {
      padding: 10px;
    }

    .shaded-container {
      background-color: #e7e7e7;
      margin: 0 10px 0 0;
      font-weight: bold;
      height: 100%;
      // width: 20%;
      float: left;
    }
  }
}
