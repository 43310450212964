.autodialer-menu {
  display: flex;
  flex: 1 1 auto;
  .autodialer-status {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    color: #666;
    height: auto;
    font-size: 2em;
  }
  .current-company {
    margin-right: 1em;
    color: #666;
    height: auto;
    font-size: 2em;
  }
}

.validate-patient-button {
  border-radius: 8px;
  box-shadow: 2px 2px 6px black;
}
