.list-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.05rem;
  font-weight: 400;
  padding: 5px;
  .item-label {
    font-weight: 500;
  }
}
