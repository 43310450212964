.user-page-header {
  padding: 5px;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
  align-items: center;
  h1 {
    font-size: 2.3em;
    font-weight: 300;
    color: rgb(102, 102, 102);
    flex: 1;
  }
  .user-header-controls {
    display: flex;
    button {
      margin: 0 10px;
      justify-self: flex-end;
      padding: 5px 10px;
    }
  }
}
.user-profile-container {
  .user-profile-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    padding: 10px 5px;
    border-bottom: 1px solid #ddd;
  }
  .user-insights-container {
    display: flex;
    flex-direction: column;

    .user-insights-header {
      display: grid;
      grid-template-columns: 1fr 350px 350px auto;
      grid-gap: 20px;
      padding: 10px 0px;

      .tab-menu {
        display: flex;
        align-items: center;
        span {
          margin: 0 1.5em;
          padding: 0 10px;
          font-size: 1.15em;
          cursor: pointer;

          padding: 0.4em 0;
          color: rgb(0, 144, 188);
          position: relative;
          text-decoration: none;

          &:before {
            position: absolute;
            content: "";
            -webkit-transition: all 0.35s ease-in-out;
            transition: all 0.35s ease-in-out;
            opacity: 0;
            top: 25%;
            bottom: 25%;
            left: 0;
            right: 0;
            border-top: 2px solid rgb(37, 168, 224);
            border-bottom: 2px solid rgb(37, 168, 224);
          }
          &:hover {
            color: rgb(0, 144, 188);
          }
          &:hover:before {
            opacity: 1;
            top: 0;
            bottom: 0;
          }
        }
        span.is-active {
          &:before {
            opacity: 1;
            top: 0;
            bottom: 0;
          }
          font-weight: 800;
          color: rgb(37, 168, 224);
        }
      }
      .InputFromTo {
        align-items: center;
      }
      button {
        margin: 0;
      }
    }
    .user-orders {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding: 20px;
      grid-gap: 20px;
    }
    .user-insights-overview {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      padding: 10px 0px;
    }
  }
}
@media print {
  .user-profile-container {
    max-width: 8in !important;
    .user-profile-info {
      grid-template-columns: 1fr !important;
      > * {
        margin: 10px;
      }
    }
    .user-insights-overview {
      grid-template-columns: 1fr !important;
      > * {
        margin: 10px;
      }
    }
  }
}

.user-logs-container {
  background: #fff;
  border: 1px solid #ddd;
  margin: 5px auto;
  width: 70%;
  padding: 20px 50px;
  overflow: auto;
  .log-tab {
    padding: 5px 15px;
    width: 50%;
    float: left;
    border-right: 1px solid #ddd;
    background-color: rgb(237, 237, 237);
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px 0px 0px 1px;
    cursor: pointer;
    flex: 1 1 0%;
    padding: 10px 5px;
    text-align: center;
  }
  .log-tab.active {
    background-color: #c0c0c0;
  }
}
.logs-page {
  .log-container {
    background: #fff;
    border: 1px solid #ddd;
    height: 100%;
    table {
      width: 100%;
      table-layout: fixed;
    }
  }
  .logs-waiting-container {
    padding-top: 75px;
    padding-left: 50%;
  }
}
