.order-status-table {
  .query-table.orders-table.static-columns {
    height: unset;
  }
  .query-table-container .query-table {
    height: auto !important;
  }
}

.filter-pane-command {
  text-transform: lowercase;
  font-variant: small-caps;
}
.filter-pane-command.controls {
  justify-content: center;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  display: flex;
  padding: 10px;
  justify-content: center;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
  text-transform: lowercase;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
}
.filter-entry {
  padding-left: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
}

> label,
.filter-entry,
.filter-pane-command {
  padding: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background: #eee;
  }
}
.filter-sub-entry {
  padding: 5px 10px 5px 30px;
}

input {
  margin-right: 10px;
}

.searchInputBox {
  width: 94%;
  margin-bottom: 13px;
  margin-top: 12px;
  height: 27px;
  border: none;
  box-shadow: inset 0px 0px 7px 0px rgba(136, 136, 136, 0.49019607843137253);
  border-radius: 5px;
}

.filterListSearchIcon {
  font-size: 17px;
  position: relative;
  top: 2px;
  left: -47px;
  color: #2dc6b8;
  cursor: pointer;
}

.hrForTableSearch {
  border: solid #dddddd87;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 0.25rem 0 0.1875rem;
}

.filter-pane-out-of-bounds {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: auto;
  background: none;
}

.table-waiting-container {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 29px;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  td {
    display: inline-block;
  }
}
.filter-downselect {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  padding: 5px;
  margin: 5px;
  input {
    width: 100%;
    grid-area: "search-input";
  }
}

.claimed-by {
  display: block;
  background-color: #c0c0c0;
  width: 50px;
  text-align: center;
  padding: 3px;
  border-radius: 3px;
}
