.table-button {
  font-size: 22px;
  padding: 0px 7px;
  margin: 0px 0px;
  color: hsla(0, 0%, 100%, 0.5);
  align-content: center;
  cursor: pointer;

  &:not(.disabled) {
    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  &.disabled {
    cursor: default;
    color: hsla(0, 0%, 100%, 0.3);
  }
}
