.product-photo-viewer {
  .photo-not-found {
    display: inline-block;
    width: 500px;
    height: 500px;
    border: 10px solid #666;
    background-color: #888;
    color: #eee;
    font-size: 14em;
    text-align: center;
    line-height: 99%;

    .photo-not-found-text {
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
