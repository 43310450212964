.company-order-report-container {
  padding: 10px 5px;
  .waitingDiv {
    margin-top: 250px;
  }
  .orderSumRow {
    padding: 0 0.9375rem 15px 0.9375rem;
    height: 130px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
  }
  .company-order-report-date-range {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 25px;
    padding: 0 5px;
    width: 100%;
  }
  .row2 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;
    padding-right: 0.9375rem;
    @media print {
      grid-template-columns: 1fr;
    }
    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }
  @media print {
    max-width: 8in;
    margin: 0px;
    padding: 5px;
    .graph-row,
    .recharts-surface,
    .recharts-wrapper,
    .recharts-legend-wrapper {
      max-width: 7in !important;
      overflow: none;
    }
    .heatMap {
      max-width: 7in !important;
      padding: 0px;
    }
  }
}
