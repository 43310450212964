.company-profile-container {
  #allSelector {
    cursor: pointer;
    display: inline-block;
    margin: 1rem;
    text-decoration: underline;
    font-size: 1.15rem;
  }

  .insuranceListSearchInput {
    display: inline-block;
  }

  // .insuranceListSearchInput .select-dropDown-container .select-dropDown-header {
  //   padding: 0.24em;
  //   margin: auto;
  //   border-radius: 4px;
  // }
  // .multi-select-dropDown-container{
  //   width: 45%;
  // }
  //
  // .multiSelectSearchInput {
  //   display: inline-block;
  //   width: 45%;
  //   min-width: 200px;
  // }

  // div[class^="filterOptionDiv-"] {
  //   display: flex;
  //   flex-wrap: nowrap;
  // }

  .open-new-table-button-container {
    width: 100px;
    height: 90vh;
    position: sticky;
    top: 95px;
    display: flex;
    align-items: center;
    justify-content: center;

    .open-new-table-button {
      display: inline-block;
      background-color: rgb(120, 195, 231);
      color: white;
      text-align: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      box-shadow: 2px 3px 2px #ccc;
      cursor: pointer;

      .plus-symbol {
        width: 70%;
        height: 8%;
        background-color: white;
      }

      .plus-symbol:nth-child(1) {
        transform: translate(20%, 575%);
      }
      .plus-symbol:nth-child(2) {
        transform: translate(20%, 480%) rotate(0.25turn);
      }
    }

    .open-new-table-button:hover {
      background-color: rgb(146, 208, 236);
    }
  }

  .modalButton {
    display: inline-block;
    margin: 0.5rem;
  // background-color: #2dc6b8;
    border-radius: 1.5em;
    filter: drop-shadow(3px 3px 3px #7b7b7c);
  }

  .bulkUpdateHeader{
    display: inline-block;
    margin: 0.5rem;
    align-self: center;
  }
  // .modalButton:hover{
  //   background-color: #249c91
  // }
  .insuranceList--button--container {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;
    font-weight: 200;
    text-align: center;
  }
  .page--container {
    display: flex;
    flex-direction: row;
  }

  .searchInputContainer {
    display: block;
  }

  .close {
    float: right;
    margin-left: 5px;
    padding: 2.5px;
    position: relative;
  }

  .insuranceList--container {
    width: 30%;
    height: auto;
    background-color: lightgray;
    margin: 10px;
    min-width: 440px;
  }
  .hcpc-row-container {
    display: flex;
    margin: 10px 0px;
  }
  .hcpcTable--container {
    width: 80%;
    height: 80%;
    margin: 10px;
  }
  .insuranceList--item {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;
    font-weight: 200;
    text-decoration: underline;
  }
  .insuranceList--item--selected {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;
    background-color: rgb(173, 173, 173);
    font-weight: 200;
    width: 100%;
    text-decoration: underline;
  }
  .type-header {
    font-size: 2em;
    font-weight: 500;
    background-color: rgb(232, 232, 232);
    padding-left: 2%;
    padding-top: 5px;
    padding-bottom: 5px;

    display: flex;
  }
  .hcpc-row {
    display: flex;
    justify-content: space-evenly;
    padding: 1%;
    font-size: larger;
  }
  .hcpc-row-header {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
  .hcpc-row-gray {
    display: flex;
    justify-content: space-evenly;
    padding: 1%;
    font-size: larger;
    background-color: whitesmoke;
  }
  .hcpc-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .edit-eligibility-button {
    padding: 5px 10px;
    margin: 0px;
    margin-left: 10px;
  }
  .hcpc-label {
    width: 2%;
    display: flex;
    align-items: center;
  }
  .grouping-container {
    display: flex;
    flex-direction: column;
    height: 50%;
  }
  .type-header-label {
    width: 20%;
    color: rgb(87, 86, 86);

    text-decoration: underline;
  }

  input.allowable-edit-input {
    width: 20%;
    height: 30%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0 !important;
  }
  .eligiblity-item {
    display: flex;
    height: 80%;
  }
  .number-input-container {
    display: flex;
    flex-direction: row;
  }
  .number-input {
    margin: 20px 20px;
  }
  .modal-header-type {
    font-size: 2em;
    text-align: center;
    margin-top: 20px;
  }
  .eligibility-submit-button {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
  }
  .edit-group-containter {
    display: flex;
    flex-direction: row;
    align-self: center;
  }
  .form-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .edit-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .edit-mode-button {
    height: 30px;
    padding: 10px 10px;
    width: 70px;
  }
  .eligibility-container {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
  }
  .hcpc-header {
    align-self: center;
    font-size: 1em;
    font-weight: 300;
  }
  .hcpc-inputfield-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 15px;
  }

  #insurance-company-form-container {
    .input-field {
      margin-left: 5px;
    }
  }
  .insurance-company-tab {
    padding: 5px 15px;
    width: 50%;
    float: left;
    background-color: rgb(237, 237, 237);
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px 0px 2px 1px;
    cursor: pointer;
    flex: 1 1 0%;
    padding: 10px 5px;
    text-align: center;
  }

  .insuranceContainer {
    display: flex;
    flex-wrap: nowrap;
  }
  .insurance-company-tab.active {
    background-color: #c0c0c0;
  }
  .insurance-boolean-line{
  flex-grow: 1;
  }
  .insurance-boolean-title{
    margin-right: 7px;
  }

  .insuranceContainer-checkbox{
    margin: 1em;
  }
}

#bulk-update-insurance-table {
  height: 100%;
  .block {
    display: block;
    margin: 0 auto;
  }
  caption {
    font-size: 2em;
    left: 0;
    position: sticky;
    text-align: left;
    z-index: 25;
    width: 100%;
    background-color: white;
  }
  #container {
    display: block;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
  }
  table {
    border: 1px solid black;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 125%;
    margin: 0;
    table-layout: fixed;
    white-space: nowrap;
  }
  table td,
  table th {
    border: 1px solid black;
    padding: 0.5rem 1rem;
  }
  table td {
    /*   background: #fff; */
    padding: 4px 5px;
    text-align: center;
  }
  table tbody th {
    font-weight: 100;
    font-style: italic;
    text-align: left;
    position: relative;
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
  .no-arrows > input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  table thead th:first-child {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background: white;
  }
  table thead {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 3;
    width: 25vw;
    background: white;
  }
  tbody tr:nth-child(odd) {
    background: grey;
  }
}
