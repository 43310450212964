.mask-fitting {
  display: flex;
  flex-direction: column;
  padding: 0px 5px 5px 5px;
  border: 1px solid rgb(221, 221, 221);
  background: #f9f9f9;
  .fitting-header-label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
  }
  .patient-mask-fitting-status {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: #777;
    padding: 20px;
  }
}

.mask-fitting-result {
  .mask-fitting-result-status {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding: 5px 10px;
    font-size: 1.05rem;
    .mask-fitting-status {
      color: #888;
    }
  }
  .recommended-masks-table {
    margin-bottom: 5px;
    width: 100%;
    padding: 5px;
  }
  min-height: 135px;
}
.mask-fitting-history-entry {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}
.mask-fitting-question-entry {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  .mask-fitting-question-text {
    font-weight: bold;
  }
  .mask-fitting-question-answer {
    padding-left: 5px;
  }
}
.mask-fitting-history-link {
  font-size: 1.05rem;
  display: flex;
  flex: 1;
  padding-left: 5px;
}
.button-mask-fit-request {
  margin: 0;
  padding: 8px;
  font-size: .9rem;
}