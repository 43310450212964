.results-table {
  display: grid;
  max-width: 600px;

  .header {
    font-weight: bold;
    font-size: 1.3em;
    border-bottom: 1px solid gray;
  }

  .entry {
    background-color: #ffffff;
    font-size: 1.2em;
    padding: 2px;
  }
}
