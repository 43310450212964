.compliance-metrics-tab {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(3, 11rem) repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "active-pts active-pts"
      "ready-outreach-pts ready-outreach-pts"
      "compliant-pts compliant-pts";
  }

.bar-charts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
        @media only screen and (max-width: 1500px){
            justify-content: space-around;
        }
}

  @media only screen and (min-width: 900px) {
    /* For small desktop or tablet: */
    .compliance-metrics-tab {
      grid-gap: 0.5rem;
      grid-template-rows: 12rem 50% 1fr;
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        "active-pts active-pts compliant-pts compliant-pts ready-outreach-pts ready-outreach-pts";
    }
  }

  @media only screen and (min-width: 1200px) {
    /* For large desktop: */
    .compliance-metrics-tab {
      grid-template-rows: 12rem 50% 1fr;
      grid-template-areas:
        "active-pts active-pts compliant-pts compliant-pts ready-outreach-pts ready-outreach-pts";
    }
  }

  .bar-charts {
    width: 400px;
    margin: 0 10px 10px 0;
    align-items: center;
  }

.patients-by-doctor-referral {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interactive-graph-container {
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  @media print {
    margin: 0px;
    padding: 0px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-downselect-input {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  padding: 5px;
  margin: 5px;
  input {
    margin: 0;
    width: 100%;
    color: #333;
  }
}

.bar-charts-custom-tooltip {
    background-color: hsla(0,0%,100%,.8);
    border: 1px solid #f5f5f5;
    line-height: 20px;
    margin: 0;
    padding: 10px;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;

    .custom-tooltip-label {
        color: #666;
        font-weight: 500;
        margin: 0;
        background-color: unset;
        padding: 0;
        font-size: 1rem;
        white-space: normal;        
    }
}

.custom-ul{
  padding: 10px;
  margin: 0px;
  background-color:hsla(0,0%,100%,.8);

  .custom-li{
    display: block;
    padding-bottom: 4px;
  }

  .completed{
    color: rgb(68, 114, 196);
  }

  .pending{
    color: rgb(237,125,49);
  }
}