.menu-container {
  .menu-icon {
    float: right;
    font-size: 2em;
    cursor: pointer;
    color: #aaa;
    svg {
      vertical-align: top !important;
    }
  }

  .out-of-bounds {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .menu {
    height: 0;
    position: relative;
    top: 30px;
    z-index: 999;

    > div {
      background-color: #fff;
      border: 1px solid #ddd;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);

      .menu-item {
        border-bottom: 1px solid #ddd;
        padding: 15px;
        cursor: pointer;
        font-size: 1rem;
        color: #4281a6;
        position: relative;

        .menu-sub-list {
          visibility: hidden;
          position: absolute;
          left: 100%;
          min-width: 300px;
          top: 0px;
          .menu-item {
            padding: 10px;
          }

          @media only screen and (max-width: 46.0625em) {
            visibility: visible;
          }
        }
        &:hover {
          background-color: #eee;
          .menu-sub-list {
            visibility: visible;
          }
        }
      }

      .menu-item.disabled {
        color: #AAAAAA;
        background-color: #ECECEC;
        cursor: default;

        &:hover {
          .menu-sub-list {
            visibility: hidden;
          }
        }
      }

      > .menu-item:last-child,
      > :last-child .menu-item {
        border-bottom: none;
      }
    }
  }
}

.menu-container-disabled {
  @extend .menu-container;
  color: #ddd;
}