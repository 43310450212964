// @import "colors";
// Body
//
$main-background-color: #FAFAFA !default;
$main-width: 85% !default;

html, body {
  padding: 0;
  margin: 0;
  background: $main-background-color;
}
body {
  overflow: hidden;
}
.score-card {
  margin-left: 15px
}
#b {
	height: 100%;
	width: 100%;
}

.page-header {
	padding: 15px 0;
	display: block;
}

.hide {
	display: none;
}

// remove input chrome blue outline
input:focus, select:focus {
  outline: none;
}

// does not support edge and some mobile browsers
.scrollbar::-webkit-scrollbar,
.scrollbar-dark::-webkit-scrollbar {
  width: 0.6em;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
}

.scrollbar-dark::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

main {
	color: #333;
	display: flex !important;
	min-width: 1240px;
	overflow: hidden;
}

.top-bar {
	height: 45px;
	overflow: hidden;
	a {
		letter-spacing: .051em;
	}
	.name h1{
		background: darken($base-green, 3%);
		text-align: center;
		text-transform: uppercase;
		a {
			font-size: 19px;
			color: $base-green;
		}
	}
}

label > input[type="radio"] + span {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline; }

ul.breadcrumbs{
	margin-left: -35px;
	margin-right: -35px;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 0px;
	border-bottom: 1px solid darken($secondary-color, 10%);
	a:hover {
		text-decoration: none;
	}
}
div.section-title{
	.actions{
		padding-top: 15px;
	}
}
.emphasis {
	text-decoration: italic;
}

.pad-bottom {
	padding-bottom: 30px;
}

.full-height {
	height: 100%;
}

select {
  .select-placeholder {
    visibility: hidden;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    overflow: scroll;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  footer {
    page-break-after: always;
  }

  .print-page-break {
    page-break-after: always;
  }
}
