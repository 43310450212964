.compliance-action-view-tab {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(3, 8rem);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "active-pts active-pts"
  }
  
  @media only screen and (min-width: 900px) {
    /* For small desktop or tablet: */
    .compliance-action-view-tab {
      display: grid;
      grid-gap: 1rem;
      grid-template-rows: repeat(3, 8rem);
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "active-pts active-pts";
    }
  }
  
  @media only screen and (min-width: 1200px) {
    /* For large desktop: */
    .compliance-action-view-tab {
      display: grid;
      grid-gap: 0.75rem;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 8rem);
      grid-template-areas:
        "active-pts active-pts";
    }
  }



