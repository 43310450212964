.voicemail-profile {
  .workedButton {
    font-size: 8px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    padding: 0;
    box-shadow: 1px 1px 2px 1px rgba(64, 64, 64, 0.54);
    background-color: green;
  }

  .workedButton:hover {
    background-color: rgb(0, 105, 0);
  }
}

.voicemail-profile {
  .query-table.vmail-patients-table {
    height: unset;
    min-height: 20vh;
    max-height: 40vh;
  }
}
