.patient-finder {

  display: flex;
  flex-direction: column;

  .prompt {
    font-size: 1.25rem;
  }

  .guid-input {
    min-height: 16rem;
    flex: 1;
  }

  .submit-button {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
    height: 4rem;
  }
}
