.ivr-job-managment-container {
  .ivr-job-management-table {
    table-layout: fixed;
    width: 100%;
    button {
      padding: 0.5rem 1rem 0.5rem;
      margin: 0;
    }
  }
  .ivr-status {
    font-size: 1.1em;
    cursor: pointer;
  }
}
