.order-worklist-page {
  padding: 0px 10px 5px 10px !important;
  .order-worklist-header {
    margin: 10px 5px;
    border-bottom: 1px solid rgb(221, 221, 221);
    font-size: 2.3em;
    font-weight: 300;
    color: rgb(102, 102, 102);
  }
  .order-hold-reasons-table-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
  }
  .hold-label {
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.45rem 0.5rem;
    font-size: 1rem;
    margin-right: 10px;
    margin-bottom: 5px;
    background-color: #ededed;
    border-radius: 3px;
    color: #249c91;
    border: 1px solid #ccc;
    &.resolved {
      color: #249c9177;
      background-color: #f0f0f0;
      border: 1px solid #ddd;
      text-decoration: line-through;
    }
  }
  .hold-reason-row {
    > td {
      column-span: all;
      text-align: right;
      line-height: 0.5rem;
    }
  }

  .table-hold-reason-filter {
    position: sticky;
    display: grid;
    grid-template-columns: auto 6fr 1fr;
    grid-gap: 5px;
    align-items: center;
    z-index: 3;
    background: linear-gradient(#249c91, #2dc6b8);
    padding: 3px;
    .table-hold-reason-filter-label {
      padding: 0px 5px;
      color: white;
      font-size: 1.075em;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
  }
}
