@media only screen and (max-width: 800px) {
  .calendar-and-timeslots-container {
    flex-direction: column;
    padding: 5px;
    align-items: center;
  }
}

.disabled-calendar {
  opacity: 0.5;
  pointer-events: none;
}
