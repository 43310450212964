.inventory-table-container {
  padding-top: 10px;
  .inventory-table {
    height: 70vh;
  }
  .header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .inventory-search-container {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
  .inventory-search-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.75);
    display: block;
    font-family: inherit;
    font-size: 0.875rem;
    height: 2.3125rem;
    margin: 0 0 1rem 0;
    padding: 0.5rem;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s linear, background 0.15s linear;
    -moz-transition: border-color 0.15s linear, background 0.15s linear;
    -ms-transition: border-color 0.15s linear, background 0.15s linear;
    -o-transition: border-color 0.15s linear, background 0.15s linear;
    transition: border-color 0.15s linear, background 0.15s linear;
  }
}
