.s3-ui-header-footer {
  $pad: 5px;
  $background-image: linear-gradient(#2dc6b8, #249c91);
  $background: #2dc6b8;
  $light-color: rgba(255, 255, 255, 0.6);
  $medium-color: rgba(255, 255, 255, 0.5);
  $height: 20px;

  select {
    margin-bottom: 0;
    width: initial;
  }

  .pagination-per-container {
    background: $background-image;
    padding: $pad;
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: $pad;
      }
    }

    .s3-ui-export-button,
    .s3-ui-custom-button {
      font-size: $height;
      color: $light-color;
      cursor: pointer;
    }

    select,
    input {
      box-shadow: inset 0px 0px 5px 1px rgba(126, 126, 126, 0.56);
      font-size: 9px;
      height: $height;
      width: 150px;
      padding: 2px 16px;
      background-color: white;
      border: 0px solid rgb(40, 176, 164);
      border-radius: 3px;
    }
  }

  $footer-padding: 10px;

  .footer {
    padding: $footer-padding;
    background: rgb(238, 238, 238);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .pagination-page-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    div + div {
      margin-left: 20px;
    }
  }

  .pagination-page-range {
    display: flex;
    align-items: center;

    select,
    input {
      margin-right: 5px;
      margin-bottom: 0;
      width: 55px;
    }
  }

  .pagination-nav-step {
    color: #4281a6;
    cursor: pointer;
    margin: 0 20px;
  }
  .per-page-options-container {
    display: flex;
    align-items: center;
    input,
    select {
      margin: 0px 10px;
    }
    .per-page-options-mode {
      cursor: pointer;
      color: $light-color;
      font-size: 1.5em;
      &:hover {
        filter: brightness(180%);
        transform: scale(1.1);
      }
    }
  }
  .total-container {
    display: flex;
    color: #4281a6;
    .count-label {
      padding-right: 0.5rem;
    }
    .count-value {
      font-weight: 600;
    }
  }
}
