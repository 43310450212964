.input-editable[type="text"] {
  text-align: center;
  border: none;
  box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,.1);
}

.not-input-editable[type="text"],
.not-input-editable[type="text"]:focus {
  background: transparent;
  color: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
  cursor: default;
  outline: none;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.not-input-editable[type="text"]:focus {
  text-shadow: 0 0 0 black;
}
