.search-appointments {
  color: #309bd9;

  .search-controls {
    position: relative;
    padding: 1rem;
  }
  .search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-fields-container {
    display: flex;
    gap: 10px;
  }
  .search-fields-column {
    flex: 1;
  }
  .search-field {
    max-width: 350px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 1rem;
  }
  .modal-spinner-container {
    height: inherit;
    width: inherit;
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-blue-header {
    background-color: #4684aa !important;
    color: white;
    text-decoration: none;
  }
}
