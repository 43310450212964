.new-note {
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fafafa;

  button {
    padding: 5px 20px;
    margin: 0;
  }

  label > span {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 5;
  }
  .note-validation-error {
    color: red;
    font-size: 1rem;
    float: right;
  }
}
