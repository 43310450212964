  .room {
    position: relative;
  }
  .room button {
    position: absolute;
    top: 0;
    right: 20px;
  }
  .room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
  }
  
  .local-participant {
    text-align: center;
    margin-bottom: 2em;
  }
  .remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2em 2em;
  }
  .participant {
    background: #333e5a;
    padding: 10px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
  }
  .participant:last-child {
    margin-right: 0;
  }
  .participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
  }
  
  video {
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    max-width: 500px;
    min-width: 500px;
    min-height: 400px;
    max-height: 400px;
  }
  
  /* footer { */
  /*   background: #333e5a; */
  /*   color: #fff; */
  /*   text-align: center; */
  /*   flex-grow: 0; */
  /*   padding: 1em 0; */
  /* } */
  /*  */
  /* footer a { */
  /*   color: #fff; */
  /* } */
