.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ccc;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 1.15em;
  font-weight: bold;
}
