.queue-list-page {
  padding: 0px 25px 15px 25px;
  .queue-list {
    display: flex;
    flex-direction: column;
    font-size: 1.15em;
    background: #f9f9f9;
    border: 1px solid #eee;
    background: #fff;
    .queue:nth-of-type(2n + 1) {
      background: #f2f2f2;
    }
    .queue-header {
      display: grid;
      grid-template-columns: 5fr 1fr 1fr 50px;
      background: #f2f2f2;
      font-weight: bold;
      font-size: 1.15em;
      padding: 0.5em 1em;
    }
    .queue-header-row {
      display: grid;
      grid-template-columns: 5fr 1fr 1fr 50px;
      font-size: 1.15em;
      align-items: center;
      padding: 0.5em 1em;
    }
    .team-name-cell {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 20px;
    }
  }
}
