.modal-window.referral-answer-window {
  .modal-window .modal-window-content-container {
    z-index: 1000001 !important;
    height: 500px;
  }
}
.modal-window.referral-answer-window > .modal-window-content-container {
  $pad: 2rem;
  top: 4rem;
  left: $pad;
  right: $pad;
  z-index: 1000001 !important;
  height: 80%;
  max-width: none;
  max-height: none;
}
