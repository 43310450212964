.day-picker-range {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  grid-gap: 15px;
  width: 100%;
  .DayPickerInput {
    margin: 0;
    color: black;
  }
  input {
    margin: 0;
  }
  .ui-inline-waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
  }
  button {
    padding: 0.5rem 2rem;
    margin: 0;
    border-radius: 3px;
  }
}
