$sliderWidth: 100px;
$sliderHeight: 4px;
$border-radius: 25px;
$top: -8px;
$outline: none;

.progressSlider{
    background-color: rgba(0, 0, 0, 0.10980392156862745);
    //background: red;
    -webkit-appearance: none;
    height: $sliderHeight;    
    outline: $outline;
    width: $sliderWidth;
    position: relative;
    top: $top;
    //opacity: 0.7;
    border-radius: $border-radius;
    z-index: 3;
    //opacity: 0;
}
.progressSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 11px;
    height: 11px;
    border-radius: 50px;
    opacity: .7;
    background: rgb(27, 27, 27);
    cursor: pointer;
}

.progressSlider::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: rgb(27, 27, 27);
    cursor: pointer;
     opacity: .7;
}  
.progressSlider::-webkit-slider-thumb:hover {    
    opacity: 1;   
}
.progressSlider::-moz-range-thumb:hover {
   opacity: 1;
}  

$progressBarTop: -16px;
$progressBarLeft: 97px;
.progressBarBackground{
    position: relative;
    width: $sliderWidth;
    height: $sliderHeight;
    border-radius: $border-radius;
    top: $progressBarTop;
    left: $progressBarLeft;
    background: #a2a2a2;
    outline: $outline;
    z-index: 1;
}
.progressBar{
    position: relative;
    width: 50px;
    height: $sliderHeight;
    border-radius: $border-radius;
    //top: $progressBarTop;
    //left: $progressBarLeft;
    background: black;
    outline: $outline;
    z-index: 2;
}

.durationAndPosition{
    font-size: 12;
}

.quickAudioMoveContainer{
    // position: relative;
    // top: 0px;
    // left: 0px;
}


.goBackAmount{
    font-size: 7px;
    position: relative;
    top: -11px;
    left: 17.5px;
}
.goBackSpan{
    position: relative;
    top: -32px;
    left: 190px;
    cursor: pointer;    
}
.goBackIcon{
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  transform: scale(-1, 1);
}


.goForwardAmount{
    font-size: 7px;
    position: relative;
    top: -11px;
    left: 17.5px;
}
.goForwardSpan{
    position: relative;
    top: -32px;
    left: 173px;
    cursor: pointer;
}

