.company-inventory-checkbox-container div {
  margin-right: 15px;
}

.edit-company-inventory-form {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  font-size: 18px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  width: 75%;
  padding: 30px;
}
