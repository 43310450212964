$caution-color: #FF9900 !default;

.button {
  &.caution {
    background-color: $caution-color;
    &:visited {
      background-color: $caution-color;
    }
    &:hover {
      background-color: darken($caution-color, 10%);
    }
    &:active, &:focus {
      background-color: lighten($caution-color, 10%);
    }
  }
  &.record-action {
    padding: 6px 12px;
    font-size: 1.4em;
  }
}