.inventory-profile {
  .inventory-table {
    height: 47vh;
  }
  .inventory-item-details {
    margin: 0 15px;
    font-size: 14px;
    background: #f5f5f5;
    border: 1px solid #ddd;
  }

  .inventory-item-details {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    align-items: center;
    margin: 20px;
    padding: 20px;
    > div {
      padding: 20px;
    }

    .inventory-item-list > div:nth-child(even) {
      background-color: rgba(66, 129, 166, 0.2);
    }

    .inventory-photo {
      border: 1px solid gray;
      width: 100%;
    }

    .inventory-photo-container {
      cursor: pointer;
      position: relative;
      color: #eee;
      padding: 0;
    }

    .photo-not-found {
      position: relative;
      border: 10px solid #666;
      background-color: #888;
      color: #eee;
      padding-top: 100%;
      font-size: 4vw;
      text-align: center;
      line-height: 99%;

      .photo-not-found-text {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .photo-add-indicator {
      background-color: rgba(221, 221, 221, 0.8);
      border-left: 1px solid #888;
      border-bottom: 1px solid #888;
      border-radius: 0 0 0 5px;
      position: absolute;
      margin: 0;
      top: 0;
      right: 0;
      width: 10%;
      height: 10%;
      z-index: 2;
      color: #333;
      font-size: 1.8vw;
      line-height: 85%;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.table {
  background: #f5f5f5;
  border: 1px solid #ddd;
  margin: 0 20px 10px 20px;

  .header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: center;
    button {
      flex: 1 1 auto;
    }
  }
}

.photo-editor-form {
  text-align: center;
  align-items: center;

  form {
    margin-top: 10px;
  }

  img {
    margin-bottom: 10px;
  }

  .exit-button {
    margin-right: 15px;
    background-color: salmon;
  }

  // hide the input
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    color: #fff;
    background-color: #4281a6;
    border: 0 solid #356785;
    display: inline-block;
    margin-right: 15px;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-color: #4281a6;
    border: 0 solid #356785;
    background-color: #356785;
  }
}

.inventory-media {
  align-items: center;
  margin-bottom: 20px;

  .videos-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;

    .video-card {
      padding: 10px;
      position: relative;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #f5f5f5;

      .video-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .video-card-description {
        display: inline-block;
        max-width: 100%;
      }

      .video-wrapper {
        width: 100%;
        display: inline-block;

        .video-iframe-container {
          position: relative;
          padding-bottom: 56.25%;

          .video-iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        // .video-iframe-container::after {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   z-index: 999;
        //   width: 100%;
        //   height: 100%;
        //   background-color: rgba(255, 255, 255, .7);
        // }
      }
    }
  }

  .media-inactive-overlay {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .7);
  }

  .photos-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-gap: 10px;

    .inventory-photo {
      border: 1px solid #ddd;
      display: inline-block;
      position: relative;

      img {
        width: 100%;
      }
    }
  }
}

.media-button {
  display: inline-block;
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  background-color: rgba(221, 221, 221, 0.8);
  border: 1px solid #444;
  color: #333;
  margin: 0;
  z-index: 2;
  width: 25px;
  height: 25px;
  line-height: 150%;
  text-align: center;
  vertical-align: middle;
}

.media-primary-button {
  @extend .media-button;
  border-radius: 0 0 5px 0;
  top: 0;
  left: 0;
}

.media-deactivate-button {
  @extend .media-button;
  border-radius: 0 0 0 5px;
  top: 0;
  right: 0;
}

.media-activate-button {
  @extend .media-deactivate-button
}

.media-deactivate-button:hover {
  color: red;
}

.media-activate-button:hover {
  color: limegreen
}

.media-primary-button:hover {
  color: limegreen
}

.media-button:hover {
  background-color: rgba(221, 221, 221, 1);
  cursor: pointer;
}