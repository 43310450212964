.addInventoryModal{
    padding: 20px;
    margin: 20px;
}

.unassign--button{
    margin-left: 50px;
}

.button--container{

    margin: 15px;
}

.inventoryModal{
    padding-right: 30px;
}

.companyInventory--Main{
    display: flex;
    
}

.company-inventory-search-container{
    display: flex;
}

.company-inventory-search-input{
    width: 100%;
    font-size: 0.875rem;
    height: 2.3125rem;
}

.company-inventory-select{
    width: 20%;
}

.deleteButton--container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


