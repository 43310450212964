.patient-tabs {
  ul {
    display: flex;
    border-radius: 0.285rem 0.285rem 0px 0px;
    background: linear-gradient(#efefef, #eaeaea);
    li {
      background: linear-gradient(#efefef, #eaeaea);
      border-color: #ddd;
      border-style: solid;
      border-width: 1px 0 0 1px;
      cursor: pointer;
      display: table-cell;
      flex: 1;
      padding: 10px 5px;
      text-align: center;
      &:hover {
        background: #ddd;
      }
      &.active {
        background: #ddd;
      }
    }
  }
  .content {
    border: 1px solid #ddd;
  }
}
.special-fields {
  padding: 1em;
}
.special-fields > div {
  display: flex;
  vertical-align: center;
}

.submit-button-container {
    display: flex;
    align-items: center;
    .submit-button {
        margin-right: 5px;
        margin-bottom: 0px;
    }
}
