$base-green: #042C29;
$link-green: #2DC6B8;
$primary-color: #4281A6;
$secondary-color: #e7e7e7;
$alert-color: #B11623;
$success-color: #43AC6A;
$warning-color: #f08a24;
$info-color: #a0d3e8;

tr.red > td {
	color: $alert-color
}