.zoomButtons{
    font-size: 1.5rem;
    &:hover{
        color: #00b4fe;
    }
}

.heatMap{
   box-shadow: 0px 1px 9px 0px rgba(136, 136, 136, 0.7294117647058823);
    border-radius: 5px;
    padding: 20px 10px 10px 20px;
}
.heatMapHeader{
    color: rgb(8, 36, 48);
    &:hover{
        color:  #00b4fe;
    }
}

.disabledArrowButtons{
    color: rgba(34, 34, 34, 0.23921568627450981);
    font-size: 1.5rem;
    cursor: not-allowed;
}

.startOfRangeBorder{
    border-top-color: rgba(0, 180, 255, 0.47);
    border-top-style: dashed;
    border-top-width: initial;
    border-bottom-color: rgba(0, 180, 255, 0.47);
    border-bottom-style: dashed;
    border-bottom-width: initial;
    border-left-color: rgba(0, 180, 255, 0.47);
    border-left-style: dashed;
    border-left-width: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.middleOfRangeBorder{
    border-top-color: rgba(0, 180, 255, 0.47);
    border-top-style: dashed;
    border-top-width: initial;
    border-bottom-color: rgba(0, 180, 255, 0.47);
    border-bottom-style: dashed;
    border-bottom-width: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.endOfRangeBorder{
    border-top-color: rgba(0, 180, 255, 0.47);
    border-top-style: dashed;
    border-top-width: initial;
    border-right-color: rgba(0, 180, 255, 0.47);
    border-right-style: dashed;
    border-right-width: initial;
    border-bottom-color: rgba(0, 180, 255, 0.47);
    border-bottom-style: dashed;
    border-bottom-width: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.oneColumnRangeBorder{
    border-top-color: rgba(0, 180, 255, 0.47);
    border-top-style: dashed;
    border-top-width: initial;
    border-right-color: rgba(0, 180, 255, 0.47);
    border-right-style: dashed;
    border-right-width: initial;
    border-bottom-color: rgba(0, 180, 255, 0.47);
    border-bottom-style: dashed;
    border-bottom-width: initial;
    border-left-color: rgba(0, 180, 255, 0.47);
    border-left-style: dashed;
    border-left-width: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}
.heat-map-header {
  display: grid;
  grid-column: 1;
  grid-template-columns: repeat(3, auto) 1fr 50px;
  grid-gap: 20px;
  align-items: center;
  .heat-map-title {
    flex: 1;
    justify-content:center;
    align-items: center;
  }
  .heatMapRestore {
    font-size: 28px;
    color: rgb(0, 180, 255);
    cursor: pointer;
    &:hover {
      color: rgb(0, 118, 167);
    }
  }

  .heatMapShifterLeft {
    font-size: 28px;
    color: #00b4ff;
    cursor: pointer;
    &:hover {
      color: rgb(0, 118, 167);
    }
  }

  .heatMapShifterRight {
    font-size: 28px;
    color: #00b4ff;
    cursor: pointer;
    &:hover {
      color: rgb(0, 118, 167);
    }
  }
}
