@supports (not (display: grid)) {
  body {
    background: white;
  }

  section[role="main"] {
    position: absolute;
    top: 32px;
    left: 220px;
    min-width: calc(100% - 220px);
  }
  #app {
    width: 100%;
  }

  .root-component {
    width: 100%;
  }
}
