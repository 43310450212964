.patient-update-by-guid {
  @mixin message($primary, $faded) {
    padding: 1rem;
    color: $primary;
    border: 1px solid $primary;
    background: $faded;
    font-weight: 600;
  }

  display: flex;
  $element-margin: 1rem;

  .patients-found {
    @include message(#155724, #20c997);
  }

  .patients-not-found {
    @include message(#ff3333, #ffcccc);
  }

  > :first-child {
    flex: 2;
  }

  > :nth-child(2) {
    flex: 5;
    > * + * {
      margin-top: $element-margin;
    }
  }

  > * + * {
    margin-left: $element-margin;
  }
}
