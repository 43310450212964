.insights-page-container {
  padding: 10px;
  display: grid;
  grid-template-rows: auto auto 3fr 3fr 3fr;
  grid-gap: 10px;
}

.date-select-row {
  display: grid;
  grid-template-columns: 1fr auto;
}

.orders-submitted-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-width: 100%;
  grid-gap: 50px;
  .statistic-card {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media print {
  .orders-submitted-cards {
    page-break-after: always;
    padding-bottom: 10px;
    .statistic-card {
      canvas {
        display: none;
      }
    }
  }
}
