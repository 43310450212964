.twilio-chat-window {
  grid-template-rows: auto 1fr;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(#eee, #dfdfdf);
  border: 1px solid #dfdfdf;
  overflow: hidden;

  .chat-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chat-header-text {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
  .twilio-chat-container {
    font-size: 16px;
    display: grid;
    grid-template-rows: 1fr auto;
    overflow: hidden;
    .messages-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      text-align: center;
      overflow: auto;
      height: 100%;
      min-height: min-content; /* needs vendor prefixes */
      border: 1px solid #ddd;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
    }
    .my-msg {
      .msg-details {
        grid-template-areas: "timestamp divider author";
        color: #888;
      }
    }
    .participant-msg {
      .msg-details {
        grid-template-areas: "author divider timestamp";
        color: #0085d1;
      }
    }
    .my-msg,
    .participant-msg {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .msg-details {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 5px;
        .divider {
          grid-area: divider;
        }
        .msg-author {
          grid-area: author;
        }
        .msg-timestamp {
          grid-area: timestamp;
        }
      }
      .msg-body {
        border-radius: 20px;
        padding: 5px 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: inline-block;
        overflow-wrap: anywhere;
      }
    }
    .message.log {
      color: #bbb;
      align-items: center;
      .msg-body {
        border-radius: 0;
        margin-top: 5px;
      }
    }
    .message.my-msg {
      align-items: flex-end;
      margin-right: 10px;
      .msg-body {
        background-color: #ddd;
        position: relative;
      }
    }
    .message.participant-msg {
      align-items: flex-start;
      margin-left: 10px;
      .msg-body {
        background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
        background-attachment: fixed;
        color: white;
        position: relative;
      }
    }

    // .send-msg-container {
    //   background: #eee;
    //   flex: 0 0 30px;
    //   display: flex;
    //   background: #ccc;
    //   margin: 5px;
    // }
    // .send-msg-container .input-container {
    //   flex: 1;
    //   margin: 1px;
    // }
    //
    // .send-msg-container .button-container {
    //   flex: 0 0 6em;
    //   margin: 1px 1px 1px 0;
    // }
    //
    // .send-msg-container input,
    // .send-msg-container button {
    //   margin: 0;
    // }
    .send-msg-container {
      vertical-align: middle;
      white-space: nowrap;
      position: relative;
      display: inline-flex;
      margin: 5px 10px;

      .send-msg-input {
        width: 100%;
        border: 0;
        padding: 0 16px;
        outline: 0;
        color: #666b6b;
        transition: all, 0.2s ease-in-out;
        margin: 0;
        border-radius: 4px;
      }

      .send-msg-icon {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        z-index: 1;
        padding-right: 5px;
        top: 0;
        right: 0;
        font-size: 1.15em;
        cursor: pointer;
        color: gray;
        &:hover {
          color: black;
        }
      }
    }

    .auto-msg-container {
      border-top: 1px solid #ccc;
      max-width: 100%;
      height: 100%;
      bottom: 50px;
      display:flex;
      overflow: auto;

      .auto-msg-category-container {
        margin-left: 20px;
        margin-right: 20px;
        padding: 10px;
        flex: 1;

        .auto-msg-category {
          text-align: center;
          font-weight: bold;
        }
  
        .auto-msg-button {
          text-align: center;
          font-size: .8em;
          margin-top: 10px;
          color: white;
          border-radius: 4px;
          background-color: #2F80ED;
          box-shadow: 2px 2px 8px #888;
          transition: 0.3s;
          cursor: pointer;
        }
  
        .auto-msg-button:hover {
          background-color: #4FA0FF;
        }
      }
    }
  }
}
