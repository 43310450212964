.order-tasks-panel {
  display: grid;
  flex: 1;
  grid-template-columns: 120px 1fr;
  height: inherit;
  position: relative;
  background-color: transparent;

  .task-sidebar {
    color: #000 !important;
    background-color: #f1f1f1 !important;
    border-right: 1px solid #ddd;
    height: 100%;
    background-color: #fff;
    margin: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: sticky;
    direction: rtl;
    overflow-y: auto;
    flex: 1;
  }

  .task-sidebar-item {
    width: 100%;
    display: block;
    padding: 4px 8px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    border: none;
    outline: 0;
    &:hover {
      color: #000 !important;
      background-color: #ccc !important;
    }
    &.active {
      font-weight: bold;
      background-color: #ccc !important;
    }
  }
  .task-list {
    .task-item {
      color: #333;
      display: grid;
      display: grid;
      grid-template-columns: 1fr 400px;
      align-items: center;
      grid-gap: 10px;
      padding: 1px 5px;
      font-size: 1.15em;
      .task-item-meta {
        flex: 1 1 auto;
        font-size: 0.8em;
        margin-left: 10px;
        margin-right: 10px;
        .worklist-item-info {
          font-weight: bold;
        }
        .warning {
          color: salmon;
        }
      }
      .order-note {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: 10px;
        padding: 0 7px;
        margin: 0 5px;
        color: #444;
        .note-content {
          padding: 0 7px;
          margin-right: 10px;
          color: black;
          word-break: break-word;
          white-space: pre-line;
        }
      }

      .task-status {
        user-select: none;
        &:not(.disabled) {
          cursor: pointer;
        }
      }
      &.resolved {
        .task-status {
          color: #888;
          text-decoration: line-through;
        }
      }
      .task-description {
        display: flex;
        flex: 1;
        padding: 0 10px;
        margin: 0 10px;
      }

      .edit-task-icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .number-step {
    position: relative;
    bottom: 8px;
    left: 5px;

    color: #999;
    padding: 1px;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1.6em;
    text-align: center;
  }
}
