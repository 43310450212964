.company-contacts-container {
  padding: 10px 5px;
  .date-range-input {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 25px;
    padding: 0 5px;
    width: 100%;
  }
  .total-contacts {
    border-bottom: none;
    color: #000;
    padding-top: 10px;
    font-weight: 600;
    .item-label {
      font-weight: 600;
    }
  }

  @media print {
    max-width: 8in;
    margin: 0px;
    padding: 5px;
    .graph-row,
    .recharts-surface,
    .recharts-wrapper,
    .recharts-legend-wrapper {
      max-width: 8in;
      overflow: none;
    }
  }
}

.contact-report-container {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 20px;

  @media print {
    grid-template-rows: 1fr;
  }
  .contact-summary-blocks {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;

    @media print {
      grid-gap: 5px;
      max-width: 8in;
    }
  }

  .contact-summary-blocks-1 {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;

    @media print {
      grid-gap: 5px;
      max-width: 8in;
    }
  }

  .contact-summary-blocks-2 {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 20px;
    margin-top: 20px;

    @media print {
      grid-gap: 5px;
      max-width: 8in;
    }
  }

  .graph-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    &.breakdown-row {
      grid-template-columns: 3fr 1fr;
      page-break-after: always !important;
      @media print {
        grid-template-columns: 1fr;
        padding-bottom: 150px;
      }
    }
    @media print {
      grid-template-columns: 1fr;
      margin: 0px;
      padding: 5px;
    }
  }
  .graph-container {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12),
      inset 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #f3f3f3;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    .graph-header {
      margin-bottom: 0;
      text-align: center;
    }
  }
}
