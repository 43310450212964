.orders-table-page {
  .query-table.orders-table.dynamic-columns {
    height: 70vh;
  }
  // .query-table.orders-table {
  //   max-height: 70vh !important;
  //   // @media only screen and (max-height: 780px) {
  //   //   height: 100%;
  //   //   max-height: 100%;
  //   // }
  // }
  //  .query-table {
  //    overflow: unset;
  //    overflow-y: scroll;
  //   // overflow-y: auto;
  //   // overflow-x: auto;
  //   // overflow: unset;
  // }
}
