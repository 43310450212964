$alert-box-color: rgba(255,255,255,0.8) !default;

.alert-box {
  width: 350px;
  min-height: 100px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  color: #000;
  background-color: $alert-box-color;
  border: 1px solid rgba($alert-box-color, 0.9);
  box-shadow: 0 0 6px #000;
  &.warning {
    color: #000;
  }
}
