$base-color: #666b6b;
$hover-input-color: rgba(88, 159, 207, 0.835);
$solid-color: rgba(15, 15, 15, 0.2);
$result-text-color: #424444;
$extension-search-width: 120px;

.transfer-controls {
  display: flex;
  padding-left: 20px;
  .transfer-input-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      width: $extension-search-width;
      .search-input-wrapper {
        vertical-align: middle;
        white-space: nowrap;
        position: relative;
        display: inline-flex;

        .transfer-input {
          width: 100%;
          border: 0;
          padding: 0 16px;
          outline: 0;
          color: $base-color;
          transition: all, 200ms ease-in-out;
          &::placeholder {
            color: $base-color;
          }
        }
        .clear-icon {
          position: absolute;
          display: flex;
          align-items: center;
          height: 100%;
          z-index: 1;
          padding-right: 5px;
          top: 0;
          right: 0;
          font-size: 1.15em;
          cursor: pointer;
          color: gray;
          &:hover {
            color: black;
          }
        }
      }
    }
  }

  .transfer-btn {
    width: 50px;
    padding: 0px;
    font-size: 0.9em;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
  .transfer-button {
    background-color: #47a147;
    &:hover {
      background-color: darkgreen;
      &:disabled {
        background-color: #47a147;
      }
    }
  }

  .cancel-transfer-button {
    background-color: #ff0000;
    &:disabled {
      background-color: rgba(255, 65, 54, 0.8);
      cursor: default;
    }
    &:hover {
      background-color: darkred;
      &:disabled {
        background-color: rgba(255, 65, 54, 0.8);
      }
    }
  }

  .transfer-search-matches {
    width: $extension-search-width;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    // box-shadow: 0px 0px 15px 2px $solid-color;
    border-radius: 0px 0px 5px 5px;
    position: fixed;

    .container {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr auto;
      .content {
        padding: 5px 0;
        max-height: 300px;
        overflow: auto;
        .search-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border-top: 0.5px solid rgba(15, 15, 15, 0.2);
          padding: 5px 16px;
          color: $result-text-color;
          &:last-child {
            border-bottom: 0.5px solid rgba(15, 15, 15, 0.2);
            transition: none !important;
          }
          cursor: pointer;
          transition: all, 230ms ease-in;
          &:hover {
            background-color: #aacbe06e;
          }
        }
      }
    }
  }
}
