.query-table.manage-questionnaire-table {
  height: 60vh;
}

.questionnaire-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  .question-fields {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .questionnaire-form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
