.patient-search-container {
  display: grid;
  grid-template-columns: 1fr 3fr auto;
  grid-gap: 20px;
  padding: 10px;
  flex: 1;
}

.patient-search-results {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.query-table.patient-search-results-table {
  height: 45vh;
  max-height: 45vh;
  min-height: 45vh;
  table {
    tbody {
      tr:hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }
}

.patient-search-form {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr auto;
  margin-bottom: 15px;
  .form-field {
    margin-bottom: 10px;
  }
  input {
    margin: 0;
  }
  button {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
  }
}
