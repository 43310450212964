@font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"),
        url("/src/assets/fonts/Poppins-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "Poppins-Bold";
    src: local("Poppins-Bold"),
        url("/src/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: local("Poppins-Medium"),
        url("/src/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: local("Poppins-SemiBold"),
        url("/src/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}