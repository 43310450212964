.order-status-table,
.orders-table {
  .claimed-by {
    display: block;
    background-color: #c0c0c0;
    text-align: center;
    padding: 3px;
    border-radius: 3px;
  }

  .claim {
    color: $primary-color;
    cursor: pointer;
  }
}
