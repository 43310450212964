.repap-confirmation {
  padding-left: 25px;
  label.toggle-switch-container {
    font-size: 1.25rem;
  }
  padding-bottom: 20px;
}
.philips-recall-form {
  .philips-recall-prompt {
    background-color: #d3d3d3;
    border: 1px solid #888;
    padding: 10px;
    margin: 10px;
    color: #23a3a3;
    .form-field-label {
      font-size: 20px;
    }
    .form-field.philips-radio-group {
      .radio-group-container {
        display: flex;
        flex-direction: column;
        .radio-group-option {
          font-size: 18px;
        }
      }
    }
  }

  .form-field.philips-checkbox {
    label.form-field-label {
      font-size: 18px;
    }
  }
  p {
    font-size: 18px;
    color: #23a3a3;
  }
}
.philips-alert-bar-prompt {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  .philips-alert-bar-status {
    display: flex;
    flex: 1;
  }
}
