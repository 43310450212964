.dd_root {
    box-sizing: border-box;
}

.dd_row::after {
    content: "";
    clear: both;
    display: table;
  }

[class*="dd_col-"] {
    float: left;
    padding: 7px;
    /* border: 1px solid red; */
  }
  
  .dd_col-1 {width: 8.33%;}
  .dd_col-2 {width: 16.66%;}
  .dd_col-3 {width: 25%;}
  .dd_col-4 {width: 33.33%;}
  .dd_col-5 {width: 41.66%;}
  .dd_col-6 {width: 50%;}
  .dd_col-7 {width: 58.33%;}
  .dd_col-8 {width: 66.66%;}
  .dd_col-9 {width: 75%;}
  .dd_col-10 {width: 83.33%;}
  .dd_col-11 {width: 91.66%;}
  .dd_col-12 {width: 100%;}

  .dd_LocalScreen200 {
    height: 200px;
    max-width: 270px;
    max-height: 200px;
    border: 1px solid red;
  }

  .dd_remoteScreen{
      /* height: 450px; */
      padding: 10px;
      border: 1px solid red;
  }

  .dd_childRemoteScreen {
    /* margin: 10px; */
    padding: 0.5px;
    /* height: 150px; */
    /* border: 1px solid blue; */
  }


  /* Questionnaire css */
  .dd_createQuestionnaireTheme{
    /* background: white; */
    border: 1px solid silver;
    margin:auto;
    padding-bottom:64px;
    /* width:770px; */
  }


  /* twilio history */
  .dd_tabCustom {
    height: 200px;
    max-width: 270px;
    max-height: 200px;
    border: 1px solid red;
  }

  .dd_noData{
    background: url(../../../../assets/images/no-events.svg) no-repeat 50%;
    background-size: contain;
    width: 100%;
    margin-top: 20%;
    height: 200px;
  }
  .dd_noDataTitle{
    color: darkgray;
    text-align: center
  }

  /*twilio one-to-one chat*/
  .dd_flex-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-top: 10px;
  }
  
  .dd_flex-item {
    margin: 5px;
    flex:2;
  }
  
  #dd_remote-media video {
    width: inherit;
    height: 200px;
  }
