.show-email-button{
    margin-top: 15px;
    border-radius: 10px;
    position: relative;
    left: 40%;
    box-shadow: 0px 0px 3px 3px #8f8f8f5e;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    width: 130px;
    height: 40px;
      
}

.show-email-button-disabled{
  margin-top: 15px;
  border-radius: 10px;
  position: relative;
  left: 40%;
  box-shadow: 0px 0px 3px 3px #8f8f8f5e;
  justify-items: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background-color: #ddd;
  color: #888;
  cursor: not-allowed;
}

.show-email-button:hover {
  background-color: #356785;
  color: white;
}
.close-compose-email-button{
  position: relative;
    left: 98%;
    color: red;
    font-size: 2em;
    cursor: pointer;
}
.close-compose-email-button:hover {  
  color: #8a0000;
}
.email-button{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;   
  
}
.email-button-icon{
  margin: 5px;
  justify-self: center;
  position: relative;
  top: 1px;
  left: 13px;
}
.email-button-text{
  margin: 5px;
  font-size: 1.5em;
  justify-self: center;
  font-weight: 600;
  position: relative;
  left: -12px;
}
.lowPriorityButton{
border-radius: 5px;
    text-align: center;
    border-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    font-size: .75em;
    font-weight: 900;
    color:red;
    cursor: pointer;
    &:hover {
        color:darkred;
    }    
}
.highPriorityButton{
border-radius: 5px;
    text-align: center;
    border-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    font-size: .75em;
    font-weight: 900;
    color:#777;
    cursor: pointer;
    &:hover {
        color:darkgray;
    }
}
.email-container {
  min-width: 100%;
  max-width: 100%;
  border: #dfdfdf;
  border-width: thin;
  border-style: solid;
  padding: 10px;
  margin-top: 10px;
  .email-label-container {  
     // display: grid;
     // grid-template-columns: 1fr 1fr;
    .email-label {
      
      font-size: 16px;
      font-weight: bold;
      color: #777;

      .select-dropDown-container,
      .select-dropDown-header,
      .select-dropDown-header-title,
      .select-dropDown-list,
      .multi-select-dropDown-container {
        border-radius: 10px;
      }

      .select-dropDown-header-title {
        margin-bottom: 0;
        min-height: 0;
        height: 100%;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none;
      }

      .select-dropDown-list {
        overflow-y: auto;
      }

      .select-dropDown-container,
      .multi-select-dropDown-container {
        margin-top: 5px;
      }
    }
  }

  .text-box-container {
    width: 100%;
    position: relative;
    margin-top: 15px;

    .text-box {
      min-width: 100%;
      max-width: 100%;
      height: 200px;
      border-radius: 5px;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .send-email-button {
      border-radius: 10px;
      padding: 5px 30px;
      font-weight: bold;
      font-size: 13px;
      margin-top: 15px;
      background-color: #7d7b7b;
      outline: none;
    }

    .email-button:hover {
      background-color: #949292;
    }

    .email-button:active {
      background-color: #adabab;
    }
  }
}
