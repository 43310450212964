button.secondary.icon-button {
  display: inline-block;
  margin: 2px 0 2px 5px;
  padding: 1px 3px;
  border-radius: 4px;
  color: rgb(66, 129, 166);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  background-color: rgba(231, 231, 231, 0.3);
  &:disabled {
    color: #aaa !important;
    pointer-events: none;
  }
  &:hover, &:focus {
    color: rgba(84, 161, 206, 1);
    background: rgba(231, 231, 231, 0.5);
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .button-text {
    margin-left: 2px;
  }
  &:focus {
    outline: none;
  }
}
