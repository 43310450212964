

/* Patient search */
.firstTimeRegistration-patientSearch-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}
  
.firstTimeRegistration-patientSearch-input {
  border: 1px solid black !important; 
  width: 100% !important;
  height: 28px !important;
  padding: 6px !important;
}
.firstTimeRegistration-patientSearch-inputWrapper {
  width: 28%;
  margin-bottom: 8px;
}


.patientSearch-buttonBase {
    width: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .patientSearch-buttonPrimary {
    background-color: #4BA3FF; 
    color: white;
  }
  .patientSearch-buttonSecondary {
    background-color: #E0E0E0;
    color: black;
    font-weight: bold;
  }
  .patientSearch-buttonPrimary:hover {
    background-color: #3685db; 
  }
  .patientSearch-buttonSecondary:hover {
    background-color: #c9c8c8; 
    color: black;
  }
.firstTimeRegistration-patientSearch-errorLabel {
 color: red;
 margin: 0;
}

.header-borders {
    border: 1px solid #000000;
    padding: 4px 16px;
    position: relative;
    cursor: default;
}

.header-title {
    position: absolute;
    font-size: 1.8rem;
}

.register-container {
    display: flex;
}

.register-data {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    flex: 1;
}

.register-action {
    display: flex;
    align-items: end;
    flex: 1;
    justify-Content: space-evenly
}

.register-admin-selector {
    width: 30%
}

.goback-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px
}

.goback-button {
    margin: 0;
}

.editable-header-fields {
    display: flex;
}

.match-work-button-container {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.match-work-button {
    margin-bottom: 0;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 10px;
}

.section-title {
    font-size: 1.8rem;
    cursor: default;
}

.patient-search-filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0.5em;
}

.patient-search-buttons {
    display: flex;
    gap: 4%;
    margin-top: 1em;
}


