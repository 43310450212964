.form-of-contact-chart-legend {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  label {
    font-size: 1.25em;
    font-weight: bold;
    padding: 5px 15px;
    user-select: none;
  }
  input {
    margin: 0 5px;
  }
}
