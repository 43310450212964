.s3-ui-query-table {

  select {
    margin-bottom: 0;
    width: initial;
  }

  .pagination-per-container {
    $pad: 10px;
    $background: #2dc6b8;
    $light-color: rgba(255, 255, 255, 0.6);
    $medium-color: rgba(255, 255, 255, 0.5);
    $height: 25px;

    background: $background;
    padding: $pad;
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: $pad;
      }
    }

    .s3-ui-export-button, .s3-ui-custom-button {
      font-size: $height;
      color: $light-color;
      cursor: pointer;
    }

    select {
      height: $height;
      width: 250px;
      padding: 2px 10px;
      background-color: $medium-color;
      border: 1px solid $light-color;
    }

  }

  $footer-padding: 10px;

  .query-table-footer {
    padding: $footer-padding;
    background: rgb(238, 238, 238);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 50px;
  }

  .pagination-page-container {
    display: flex;
    justify-content: center;
    align-items: center;

    div + div {
      margin-left: 20px;
    }

  }

  .pagination-page-range {

    display: flex;
    align-items: center;

    select {
      margin-right: 5px;
      width: 55px;
    }

  }

  .pagination-nav-step {
    color: #4281a6;
    cursor: pointer;
  }

  .query-table-footer-aux-children {
    position: absolute;
    right: $footer-padding;
    display: flex;
  }
  margin-bottom: 10px;
}
