
.month-view-wrapper{
  margin: 0 auto;
  overflow: hidden;
  display: flex;

  .month-view {
    overflow: auto;
    margin-top: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    width: fit-content;

    .calendar-month {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      &.loading {
        opacity: 0.5;
      }
    }
    
    .calendar-month-header {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
      display: grid;
      grid-template-columns: repeat(7, minmax(auto,170px));
      text-align: center;
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      gap: 5px;
      padding: 20px;
    }
    
    .calendar-month-body {
      display: grid;
      grid-template-columns: repeat(7, minmax(auto, 170px)); 
      grid-auto-rows: 170px;
      gap: 5px;
      padding: 20px;
    }
    
    .calendar-month-day {
      border: 1px solid #ddd;
      font-size: 18px;
      padding: 5px 0;
      box-sizing: border-box; 
      overflow: hidden;
      position: relative;

      &.day-on-focus{
        z-index: 1;
        overflow: visible;
      }
    }
    
    .calendar-month-day-number {
      font-weight: bold;
      margin-bottom: 2px;
      margin-left: 10px;
      display: block;
      width: 27px;
      min-height: 27px;
      border-radius: 100%;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &.selected{
        background-color: #2a41a6;;
        color: white;
      }
    }
    
    .calendar-month-appointments {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &.appointments-on-focus{
        background-color: white;
        position: absolute;
        top: 35px;
        left: 0;
        padding: 0 10px;
        padding-top: 10px;
        padding-bottom: 30px;
        min-width: 100%;
        max-width: 130%;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2)
      }
    }
    
    .appointment {
      padding: 5px;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    
    .inactive{
      background-color: #666666;
    }
    .active{
      background-color: white;
    }
  }
}

.month-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-selector input{
  margin: 0 0 0 10px !important;
  max-width:  200px;
}

.show-more-arrow{
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  cursor: pointer;
  margin-left: 2px;
  font-size: 1rem;
}

.show-less-arrow{
  box-sizing: border-box;
  position: absolute;
  bottom: 2px;
  background-color: white;
  width: calc(100% - 20px);
  cursor: pointer;
}