.company-profile-container {
  .company-menu {
    background: #fdfdfd;
    display: flex;
    margin-bottom: 5px;
  }

  display: flex;
  flex-flow: column;
  height: 100%;
}

.company-log-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

header.company-logs-header {
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  .log-header-title {
    padding: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 2.3em;
      font-weight: 300;
      color: #666;
      .page-title-link {
        color: #4281a6;
        cursor: pointer;
      }
    }
    .page-count-value {
      color: #b3b4ba;
    }
  }
}

.company-branches-table {
  max-height: 520px;
  margin-bottom: 1.25rem
}
