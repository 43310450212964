div#login_background {
  background: #eee;
  background-image: url(https://s3.amazonaws.com/SleepCoach/Images/Fall2018LoginBackgroundS3.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 0;
}

section#log_in_screen {
  .login_panel {
    width: 650px;
    padding: 15px;
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
    .form-inputs {
      input {
        font-size: 18px;
      }
    }
    label {
      color: #fff;
    }
    a {
      color: #ddd;
    }
    .loginError {
      padding-left: 10px;
      color: red;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .submitting {
      padding-left: 10px;
      color: #7d858f;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .shortcuts {
      position: absolute;
      color: white;
      bottom: 0;
      padding: 2em;
      width: 200px;
      background-color: rgba(255, 255, 255, 0.2);
      display: flex;
      flex-direction: column;
    }
    .clickable {
      cursor: pointer;
      color: #6b737c;
      font-size: 1.1em;
    }
  }
  .loginInput {
    border-radius: 20px;
    height: 3rem;
    padding-left: 2rem;
    font-size: 20px;
  }
  .loginInput:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
    box-shadow: 0 0 0 100px white inset;
  }
  .loginWelcome {
    color: #687a89;
    font-size: 50px;
    text-align: center;
    margin-bottom: 30px;
  }

  .loginLogo {
    background: url(https://s3.amazonaws.com/SleepCoach/Images/LogoResize.png)
      no-repeat center;
    background-size: contain;
    width: 100%;
    height: 250px;
    margin-bottom: -70px;
  }

  .buttonLogin {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 20px;
    border-style: solid;
    border-width: 0;
    cursor: pointer;
    font-family: "PT Sans", sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: #7d858f;
    border-color: #7d858f;
    color: #ffffff;
    transition: background-color 300ms ease-out;
  }
}
