.active-patients-card {
    grid-area: active-pts;
}

.compliant-patients-card {
    grid-area: compliant-pts;
}

.ready-outreach-patients-card {
    grid-area: ready-outreach-pts;
}