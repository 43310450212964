$tooltip-background-color: 8px solid rgb(59, 57, 59) !important;

.helpful-link-tooltip {
  color: lightgray !important;
  background: rgb(59, 57, 59) !important;
}

.helpful-link-tooltip::before {
  height: 8px !important;
  bottom: -1px !important;
}

// .helpful-link-tooltip::after {
//     background-color: #3b393b !important;
// }

.helpful-link-tooltip .show {
  background-color: #3b393b;
}

// over-riding react-tooltip's bottom border so the
// arrow tip is styled same as background
.__react_component_tooltip.place-left::after {
  border-left: $tooltip-background-color;
}

.__react_component_tooltip.place-right::after {
  border-right: $tooltip-background-color;
}

.__react_component_tooltip.place-top::after {
  border-top: $tooltip-background-color;
}

.__react_component_tooltip.place-bottom::before {
  border-bottom: $tooltip-background-color;
}
