.loading-spinner {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
  animation: spinner 0.75s 0s linear infinite;
  border-color: #2DC6B8;
  border-bottom-color: transparent;
  background: 0 0 !important;
  display: inline-block;
}
