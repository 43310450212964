$twilio-chat-width: 600px;

.root-component {
  display: -ms-grid;
  display: grid;
  height: 100vh;

  grid-template-areas:
    "header header header"
    "nav content chat";

  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  -ms-grid-columns: auto 1fr auto;
  -ms-grid-rows: auto 1fr;

  overflow: hidden;

  @media print {
    height: 100%;
  }
}

.phone {
  grid-row: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: header;
}

section[role="navigation"] {
  overflow: hidden;
  grid-row: 2;
  grid-column: 1;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: nav;
}

section[role="main"] {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: content;
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  background-color: $main-background-color;
  > div {
    flex: 1;
    padding: 20px 20px 90px 20px;
  }
}

section[role="twilio-chat"] {
  grid-area: chat;
  grid-row: 2;
  grid-column: 3;
  -ms-grid-row: 2;
  -ms-grid-column: 3;
  display: flex;
  flex: 1 1 auto;
  z-index: 10;
  width: $twilio-chat-width;
}
