.patient-profile {
  position: relative;

  .patient-status-bar {
    font-size: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    box-shadow: rgb(204, 204, 204) 1px 2px 4px 2px;
    &.patient-active {
      color: white;
      background-color: green;
    }
    &.patient-inactive {
      color: white;
      background-color: #ff4136;
    }
    &.patient-on-hold {
      background-color: #e8910c;
      border: 1px solid #c5a231;
      color: white;
    }
    &.deactivation-reason {
        color: white;
    }
  }
  .patient-status-banner {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    box-shadow: rgb(204, 204, 204) 1px 2px 4px 2px;
    flex: 1 1 0px;
    &.patient-active {
      color: white;
      background-color: green;
    }
    &.patient-inactive {
      color: white;
      background-color: #ff4136;
    }
    &.patient-on-hold {
      background-color: #e8910c;
      border: 1px solid #c5a231;
      color: white;
    }
    &.single-banner {
      font-size: 25px;
    }
  }
  .deactivation-reason {
    color: white;
    font-size: 20px;
  }
  .status-container {
    color: white;
    margin-left: 10px;
    font-size: 20px;
  }
  .general-status-container {
    color: white;
    font-size: 20px;
  }
  .automatedVoicemailButton {
    box-shadow: rgb(204, 204, 204) 1px 2px 4px 2px;
    margin-bottom: 10px;
    font-size: 1.5em;
    text-align: center;
    padding: 5px 0px 5px 0px;
    color: white;
    cursor: pointer;
    background-color: red;
    &:hover {
      background-color: darkred;
    }
  }

  .patient-tab-table {
    max-height: 30vh;
    @media only screen and (max-height: 780px) {
      height: 100%;
      max-height: 100%;
    }
    border: none;
    border-top: 1px solid #ccc;
    .dropdown-content {
      max-height: 200px !important;
    }
  }

  .special-instruction-row {
    display: grid;
    grid-template-columns: auto 1fr auto;
    border-bottom: 1px solid rgb(142, 203, 225);
    padding: 5px;
    .index-number {
      padding-right: 10px;
    }
    .created-by {
      font-size: 0.75em;
      color: #555;
    }
  }
  .special-instruction-row:last-child {
    border-bottom: none;
  }
  .create-contact-record-form {
    padding: 10px;
  }
  .patient-schedule-dates-container {
    .overide-date-checkbox-container {
      float: right;
      padding-right: 3px;
      input {
        margin: 0;
      }
    }
    .patient-outreach-date-controls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 10px;
      padding-top: 15px;

      button.secondary.icon-button {
        padding: 10px 10px;
        margin: 0;
        flex-grow: 1;
        background: #fafafa;
        border: 1px solid #ccc;
        border-radius: 3px;
        &:hover {
          background: #f3f3f3;
          border-color: #999;
        }
      }
      .outreach-date-seperator {
        padding: 5px;
        align-self: center;
        font-size: 0.85em;
      }
      select {
        margin-bottom: 0;
        text-align-last: center;
      }
    }
  }
}

.automationFailedAlertBox {
  //box-shadow: rgb(204 204 204) 1px 2px 4px 2px;
  background-color: red;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.stage-select {
    width: 90px;
    border-style: solid;
    border-width: 1px;
    height: 1.1rem;
    padding: 0px 5px;
    margin: 0px;
}

.stage-spinner-container {
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-spinner-container {
    max-height: 21px;
    float: right;
}