.time-slot-search-form {
}

.assign-chats-container {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  .query-table.patient-search-results-table {
    height: 30vh;
    min-height: 20vh;
  }
}
