

.breakdownContainer--listItem{
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px; 
  padding-left: 150px;
  padding-right: 200px;
  font-weight: bold;
}

.modalRow{
  background-color: rgba(231, 232, 233, .3);
}

.modalHeader{
  font-weight: bolder;
  font-size: 15px;
  text-decoration: underline;
}
.modalTitle{
  font-weight: bolder;
  font-size: 20px;
  text-align: center;
}
.modalContainer{
  padding: 30px;
}

.borderTop{
  border-top: solid darkgray 2px; 
}