.xForListenedIcon{
    position: relative;
    top: 2px;
    left: -8px;
    z-index: 1;
}

.xlistenedIcon{
    position: relative;
    top: -6px;
    left: -30px;
}

.circleForListenedIcon{
    position: relative;
    top: -6px;
    left: -30px;   
}

.assignmentModeToggleButton{
    height: 22px;
    width: 57px;
    font-size: 8px;
    padding: 0;
    margin-right: 9px;


}