.MessageForm {
  display: flex;
  background: #ccc;
}

.MessageForm .input-container {
  flex: 1;
  margin: 1px;
}

.MessageForm .button-container {
  margin: 1px 1px 1px 0;
}

.MessageForm input {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: inherit;
  outline: none;
  width: 100%;
}

.MessageForm input {
  padding: 0 0 0 4px;
}

.button-container > button {
 margin: 0px;
}

.input-container > input {
  height: 100% !important;
}